import { useTheme } from '@mui/material';

export default ({ fromX, fromY, fromPosition, toX, toY, toPosition, connectionLineType, connectionLineStyle }) => {
  const theme = useTheme();

  return (
    <g>
      <path
        fill="none"
        stroke={theme.palette.text.primary}
        strokeWidth={1.5}
        className="animated"
        d={`M${fromX},${fromY} C ${fromX} ${toY} ${fromX} ${toY} ${toX},${toY}`}
      />
      <circle
        cx={toX}
        cy={toY}
        fill={theme.palette.text.primary}
        r={3}
        stroke={theme.palette.text.primary}
        strokeWidth={1.5}
      />
    </g>
  );
};
