import { Box, CircularProgress, Container, useTheme } from '@mui/material';
import ChatHeader from './ChatHeader';
import ConversationStarter from './ConversationStarter';
import FormMessageInput from './FormMessageInput/FormMessageInput';
import MessageInput from './MessageInput/MessageInput';
import MessageList from './MessageList';
import './chat.scss';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    height: '100%',
    position: 'relative',
    backdropFilter: 'blur(2000px)',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '0 10px 10px 0px',
  },
  innerContainer: {
    px: (theme) => theme.spacing(2),
    pb: (theme) => theme.spacing(4.5),
    // paddingTop: { xs: '64px', md: 0 },
    // background: "url('/assets/pattern.jpg') repeat",

    borderRadius: (theme) => theme.spacing(0, 0, 0, 0),
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    overflow: 'auto',
    maxHeight: '100%',
    overflowY: 'auto',
    pb: 6,
    // pt: 6,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    // paddingBottom: 30,
  },
  messageList: {
    flexGrow: 1,
  },
  bottomContainer: {
    display: 'flex',
    justifyContent: 'center',
    pb: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    // '::after': {
    //   content: '""',
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   width: '100%',
    //   height: '100%',
    // backdropFilter: 'blur(10px)',
    // maskImage: 'linear-gradient(to top, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 100%)',
    // pointerEvents: 'none',
    // backdropFilter: 'blur(10000px)',
    // },
  },
};

function Chat({
  projectName,
  mainColor,
  generatingAnswer,
  handlePauseGenerating,
  handleCompleteGenerating,
  chat,
  logoURL,
  chatName,
  secondaryColor,
  messages,
  isNewChat,
  handleSendMessage,
  loading,
  suggestions,
  showPoweredBy,
  customInputFields,
  forceExtend,
  isJSONAnswer,
}) {
  const theme = useTheme();

  const renderContent = () => {
    if (loading)
      return (
        <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      );
    if (isNewChat)
      return (
        <ConversationStarter
          mainColor={mainColor}
          onIdeaClick={handleSendMessage}
          suggestions={suggestions}
          name={projectName}
        />
      );
    return (
      <MessageList
        generatingAnswer={generatingAnswer}
        handlePauseGenerating={handlePauseGenerating}
        handleCompleteGenerating={handleCompleteGenerating}
        mainColor={mainColor}
        messages={messages}
        sx={styles.messageList}
        chatId={chat?.id}
        isJSONAnswer={isJSONAnswer}
      />
    );
  };

  return (
    <Container disableGutters sx={styles.container} maxWidth={false}>
      <Box px={2} py={2} className="chat-header">
        <ChatHeader name={chat?.name || chatName} logoURL={logoURL} animate={isNewChat} />
      </Box>
      <Box display="flex" flexGrow={1} overflow="auto" flexDirection="column" sx={{ ...styles.innerContainer }}>
        <Container flexGrow={1} maxWidth="md" sx={styles.messageContainer}>
          {renderContent()}
        </Container>
        <Container maxWidth="md" sx={styles.bottomContainer}>
          {customInputFields?.length ? (
            <FormMessageInput
              mainColor={mainColor}
              generatingAnswer={generatingAnswer}
              handlePauseGenerating={handlePauseGenerating}
              secondaryColor={secondaryColor}
              onSendMessage={handleSendMessage}
              showPoweredBy={showPoweredBy}
              fields={customInputFields}
              forceExtend={forceExtend}
              sx={{ zIndex: 99 }}
            />
          ) : (
            <MessageInput
              mainColor={mainColor}
              generatingAnswer={generatingAnswer}
              handlePauseGenerating={handlePauseGenerating}
              secondaryColor={secondaryColor}
              onSendMessage={handleSendMessage}
              showPoweredBy={showPoweredBy}
              sx={{ zIndex: 99 }}
            />
          )}
        </Container>
      </Box>
    </Container>
  );
}

export default Chat;
