import { Tag, Tile } from '@carbon/react';
import { Alert, Box, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { integrationsInfo } from '../../../utils/integrationsInfo';
import './settings-node.scss';

const SettingsNode = ({ color, integration }) => {
  const [highlight, setHighlight] = useState(false);

  const prevIntegrationRef = useRef();

  const Icon = integrationsInfo[integration.id].icon;

  useEffect(() => {
    if (
      prevIntegrationRef.current &&
      !prevIntegrationRef.current.projectIntegration &&
      integration?.projectIntegration
    ) {
      setHighlight(true);
    } else {
      setHighlight(false);
    }

    // Actualizamos la referencia con el valor actual
    prevIntegrationRef.current = integration;
  }, [integration]);

  return (
    <Tile className="settings-node glass glass_border" style={{ height: '100%', width: '100%' }}>
      <Box
        sx={{
          width: 40,
          height: 40,
          backgroundColor: integrationsInfo[integration.id].color,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 10,
          // p: 1,
          mb: 1,
        }}
      >
        {Icon && <Icon size={22} color="white" />}
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle1" component="div" gutterBottom>
          {integrationsInfo[integration.id].title}
        </Typography>
        {/* <Typography variant="body2" color="text.secondary" mb={2}>
          {integrationsInfo[integration.id].description}
        </Typography> */}
      </Box>
      {integrationsInfo[integration.id].disabled && <Tag>Coming soon</Tag>}
      {integrationsInfo[integration.id].warning && (
        <Alert severity="warning">{integrationsInfo[integration.id].warning}</Alert>
      )}
    </Tile>
  );
};

export default SettingsNode;
