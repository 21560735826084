import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';

import env from '../env';

const AuthContext = React.createContext();

const axiosInstance = axios.create({
  baseURL: env.REACT_APP_BACKEND_URL,
  timeout: 100000,
});

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isCheckingToken, setIsCheckingToken] = useState(true);
  const [isAxiosReady, setIsAxiosReady] = useState(false); // Add this line
  const [user, setUser] = useState(null); // Add user state

  useEffect(() => {
    const requestInterceptor = axiosInstance.interceptors.request.use(
      (config) => {
        const token = sessionStorage.getItem('authToken');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          logout();
          // Show a 'Login must be performed' message here
        } else if (error.request) {
          console.error('Network error');
        } else {
          console.error('*Error', error.message);
        }
        return Promise.reject(error);
      }
    );

    setIsAxiosReady(true); // Add this line

    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (token) {
      setIsAuthenticated(true);
      fetchUser();
    } else {
      setIsAuthenticated(false);
    }
    setIsCheckingToken(false);
  }, []);

  const fetchUser = () => {
    const authToken = sessionStorage.getItem('authToken');
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${authToken}`;

    return axiosInstance
      .get(`/v1/users/me`)
      .then((res) => {
        setUser(res.data);
      })
      .catch((error) => {
        console.error('Error getting user:', error);
        throw error;
      });
  };

  function login(accessToken) {
    sessionStorage.setItem('authToken', accessToken);
    setIsAuthenticated(true);
    fetchUser();
  }

  function logout() {
    sessionStorage.removeItem('authToken');
    setIsAuthenticated(false);
    setUser(null);
  }

  const value = {
    isAuthenticated,
    login,
    logout,
    axiosInstance,
    isCheckingToken,
    isAxiosReady,
    user,
    fetchUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
