import { Tooltip, darken } from '@mui/material';
import { getColorFromInitials } from '../../utils/colors';
import './avatar-group.scss'; // assuming you have a separate CSS file

const getInitials = (name) => {
  const names = name.split(' ');
  const initials = names.map((name) => name.charAt(0).toUpperCase()).slice(0, 2); // Take only the first two initials

  return initials.join('');
};

const AvatarGroup = ({ avatars, maxVisibleAvatars }) => {
  const visibleAvatars = avatars?.slice(0, maxVisibleAvatars);
  const overflowAvatars = avatars?.slice(maxVisibleAvatars);

  return (
    <div className="avatar-group">
      {visibleAvatars?.map((v, index) => {
        const color = getColorFromInitials(v.name);
        return (
          <Tooltip title={v.name} key={`avt-${index}`}>
            <div
              key={index}
              className="avatar"
              style={{
                zIndex: visibleAvatars.length - index,
                backgroundColor: color,
                // border: `2px solid ${darken(color, 0.2)}`,
                boxShadow: `1px 1px 4px ${darken(color, 0.4)}`,
              }}
            >
              {typeof v.avatar === 'string' ? (
                <img src={v.avatar} alt={`user${index + 1}`} />
              ) : (
                <div className="initial-avatar">{getInitials(v.name)}</div>
              )}
            </div>
          </Tooltip>
        );
      })}
      {overflowAvatars?.length > 0 && <div className="more">{`+${overflowAvatars?.length}`}</div>}
    </div>
  );
};

export default AvatarGroup;
