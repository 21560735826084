import PropTypes from 'prop-types';
import './avatar.scss';

const getFormattedInitials = (label) => {
  const parts = label.split(' ');
  const initials = parts.length >= 2 ? `${parts[0][0]}${parts[1][0]}` : parts[0][0];
  return initials.toUpperCase();
};

const Avatar = ({ src, alt, label }) => {
  if (src) {
    return <img src={src} alt={alt} className="avatar-image" />;
  }

  return <div className="avatar-placeholder">{label}</div>;
};

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default Avatar;
