import { Checkmark, Copy, ThumbsDown, ThumbsDownFilled, ThumbsUp, ThumbsUpFilled } from '@carbon/icons-react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, Tag } from '@carbon/react';
import { AutoAwesome } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, Stack as MUIStack, Stack, Tooltip, Typography, styled } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { motion } from 'framer-motion';
import { parseInt } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { CodeBlock, atomOneDark, atomOneLight } from 'react-code-blocks';
import { useChatApi } from '../../../hooks/api/useChatApi';

import { GlobalThemeContext } from '../../../contexts/GlobalThemeContext';
import LoadingDots from '../../LoadingDots';
import PathInfoBubble from '../../PathInfoBubble';
import AnimatedCodeBlock from './AnimatedCodeBlock';
import AnimatedListElement from './AnimatedListElement';
import AnimatedTable from './AnimatedTable';
import AnimatedTypography from './AnimatedTypography';
import './message.scss';

const extractTable = (str) => {
  const lines = str.split('\n').map((line) => line.trim());
  let headers = [];
  const rows = [];

  lines.forEach((line, i) => {
    if (i === 0) {
      // The first line is always a column name row
      headers = line.split('|').filter((cell) => cell.trim() !== '');
    } else if (!/^-/.test(line)) {
      // Exclude header lines (dashes)
      rows.push(line.split('|').filter((cell) => cell.trim() !== ''));
    }
  });

  return {
    type: 'table',
    headers,
    rows,
  };
};

function parseResponse(response) {
  const patterns = [
    {
      type: 'code',
      regex: /\\begin{code}([\s\S]*?)\\end{code}|```([\s\S]*?)(```|$)/g,
      extract: (match) => match[1] || match[2],
    },
    {
      type: 'table',
      regex: /(\|\s*[^|\n]+\s*\|[\s\S]*?)(?=\n[^|]|\n?$)/g,
      extract: (match) => extractTable(match[0]),
    },
    {
      type: 'list',
      regex: /^(\d+[.\-\\)]|\s*[\\*\\+\\-])\s*(.*$)/gm,
      extract: (match) => ({
        listType: /^\d/.test(match[1]) ? 'numeric' : 'bullet',
        items: match[2],
        num: /^\d/.test(match[1]) ? parseInt(match[1]) : null,
      }),
    },
  ];

  const messageParts = [];
  let remainingText = response;
  let closestMatch = null;
  let closestPattern = null;

  function findClosestMatch(pattern) {
    pattern.regex.lastIndex = 0; // Always start from the beginning
    const match = pattern.regex.exec(remainingText);
    if (match && (!closestMatch || match.index < closestMatch.index)) {
      closestMatch = match;
      closestPattern = pattern;
    }
  }

  while (remainingText.length > 0) {
    closestMatch = null; // reset the variables for each iteration
    closestPattern = null;

    patterns.forEach(findClosestMatch);

    if (!closestMatch) {
      messageParts.push({ type: 'string', content: remainingText.trim() });
      break; // exit loop if no more patterns match
    }

    // Add text before the match
    const textBeforePattern = remainingText.substring(0, closestMatch.index).trim();
    if (textBeforePattern) {
      messageParts.push({ type: 'string', content: textBeforePattern });
    }

    // Extract the matched content
    const extractedContent = closestPattern.extract(closestMatch);
    if (closestPattern.type === 'table') {
      messageParts.push(extractedContent);
    } else if (closestPattern.type === 'list') {
      messageParts.push({
        type: closestPattern.type,
        content: extractedContent.items,
        listType: extractedContent.listType,
        num: extractedContent.num,
      });
    } else {
      messageParts.push({
        type: closestPattern.type,
        content: extractedContent,
      });
    }

    // Trim processed text
    remainingText = remainingText.substring(closestMatch.index + closestMatch[0].length);
  }

  return messageParts;
}

const Bubble = styled(motion.div)(({ theme }) => ({
  borderRadius: '1.5rem 1.5rem 1.5rem 0',
  padding: '0.5rem 1.5rem',
  color: '#fff',
  textAlign: 'left',
  maxWidth: '100%',
  overflow: 'scroll',
  backgroundColor: '#9a9a9a1a',
}));

const MessageContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  marginBottom: '1rem',
}));

const renderMessagePart = (messagePart, theme) => {
  switch (messagePart.type) {
    case 'code':
      return (
        <CodeBlock
          text={messagePart.content.trim()}
          language="javascript"
          showLineNumbers
          theme={theme === 'dark' ? atomOneDark : atomOneLight}
          wrapLines
        />
      );

    case 'table': {
      const { headers, rows } = messagePart;
      return (
        <Table>
          <TableHead>
            <TableRow>
              {headers?.map((header, headerIdx) => (
                <TableHeader key={headerIdx}>{header.trim()}</TableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, cellIdx) => (
                  <TableCell key={cellIdx}>{cell.trim()}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }

    case 'list': {
      const lines = messagePart.content.split('\n').filter((line) => line.trim());
      return (
        <Stack spacing={1}>
          {lines.map((line, lineIndex) => (
            <Typography variant="body1" color="text.primary" key={lineIndex}>
              {line}
            </Typography>
          ))}
        </Stack>
      );
    }

    default: {
      const defaultLines = messagePart.content.split('\n').filter((line) => line.trim());
      return (
        <Stack spacing={1}>
          {defaultLines.map((line, lineIndex) => (
            <Typography variant="body1" color="text.primary" key={lineIndex}>
              {line}
            </Typography>
          ))}
        </Stack>
      );
    }
  }
};

const renderMessagePartAnimated = (messagePart, onAnimationEnd) => {
  switch (messagePart.type) {
    case 'code':
      return <AnimatedCodeBlock content={messagePart.content.trim()} onAnimationEnd={onAnimationEnd} />;

    case 'table': {
      const { headers, rows } = messagePart;
      return <AnimatedTable headers={headers} rows={rows} onAnimationEnd={onAnimationEnd} />;
    }

    case 'list': {
      return (
        <AnimatedListElement textElement={messagePart.content} num={messagePart.num} onAnimationEnd={onAnimationEnd} />
      );
    }

    default: {
      const defaultLines = messagePart.content.split('\n').filter((line) => line.trim());
      return <AnimatedTypography textElements={defaultLines} onAnimationEnd={onAnimationEnd} />;
    }
  }
};

const Message = ({
  messageId,
  chatId,
  content,
  isNew,
  setOpenFeedback,
  setSelectedMessage,
  handleCompleteGenerating,
  hideActions,
  isJSONAnswer,
}) => {
  const { theme } = useContext(GlobalThemeContext);

  const { markMessageAsInvalid, markMessageAsValid } = useChatApi();

  const [upVote, setUpVote] = useState(content?.isValid);
  const [downVote, setDownVote] = useState(content?.isValid === false);

  const [isCopied, setIsCopied] = useState(false);

  const [messageParts, setMessageParts] = useState([]);

  const [actions, setActions] = useState(null);
  const [notIncludedYet, setNotIncludedYet] = useState(null);

  useEffect(() => {
    if (content?.response) {
      if (isJSONAnswer) {
        try {
          const jsonData = JSON.parse(content.response);

          setMessageParts(parseResponse(jsonData.answer));
          setActions(jsonData.actions);
          setNotIncludedYet(jsonData.notIncludedYet);
        } catch (error) {
          console.warn('Failed to parse JSON:', error);
          setMessageParts(parseResponse(content.response));
        }
      } else setMessageParts(parseResponse(content.response));
    }

    return () => {};
  }, [isJSONAnswer, content]);

  const handleVote = () => {
    setSelectedMessage(content);
    setOpenFeedback(true);
  };

  const handleUpVoteClick = () => {
    markMessageAsValid(chatId, messageId);

    setUpVote(!upVote);
    if (downVote) {
      setDownVote(false);
    }
  };

  const handleDownVoteClick = () => {
    markMessageAsInvalid(chatId, messageId);

    setDownVote(!downVote);
    if (upVote) {
      setUpVote(false);
    }
  };

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(content.response);

      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  const iconSize = 14;

  const queueRef = useRef(null);
  const renderedElementsRef = useRef([]);
  const [renderTrigger, setRenderTrigger] = useState(false);
  const isRenderingRef = useRef(false);

  const [loadingAction, setLoadingAction] = useState(false);

  const runAction = () => {
    setLoadingAction(true);

    setTimeout(() => {
      setLoadingAction(false);
    }, 2000);
  };

  const handleDequeue = () => {
    if (isRenderingRef.current || queueRef.current.length === 0) return;

    isRenderingRef.current = true;

    const [nextElement] = queueRef.current;
    renderedElementsRef.current = [...renderedElementsRef.current, nextElement];
    queueRef.current = queueRef.current.slice(1);

    setRenderTrigger((prev) => !prev);
  };

  useEffect(() => {
    // Begin the animation chain by rendering the first element
    if (messageParts?.length && queueRef.current === null) {
      queueRef.current = [...messageParts];
      handleDequeue();
    }
  }, [messageParts]);

  useEffect(() => {
    isRenderingRef.current = false;
  }, [renderTrigger]);

  function shouldShowTraceabilityElement(chain) {
    if (chain?.length <= 1) {
      return false;
    }
    let shouldShow = false;
    // Use forEach to check all elements except the last one for a non-null "message"
    chain.forEach((element, index) => {
      if (index < chain.length - 1 && element.message !== null) {
        shouldShow = true;
      }
    });
    return shouldShow;
  }

  const shouldShow = content && content.chain && shouldShowTraceabilityElement(content.chain);

  return (
    <Box display="flex" flexDirection="column">
      <MessageContainer>
        <Stack sx={{ maxWidth: '90%', alignItems: 'flex-start', position: 'relative' }}>
          <Bubble
            className="advanced_glass_chat"
            style={{ position: 'relative' }}
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            {content?.response && (
              <Stack spacing={3}>
                {!isNew
                  ? messageParts.map((part, idx) => renderMessagePart(part, theme))
                  : renderedElementsRef.current.map((part, idx) => renderMessagePartAnimated(part, handleDequeue))}
              </Stack>
            )}

            {actions && (
              <Stack mt={3} component={motion.div} animate={{ opacity: [0, 1], x: [-10, 0] }}>
                <Typography color="text.primary" variant="caption" mt={0.5} mb={2}>
                  Smart actions
                </Typography>
                <MUIStack spacing={2.5}>
                  {actions
                    ?.sort((a, b) => b.confidence - a.confidence)
                    .map((action, index) => (
                      <MUIStack key={`${action.action_id}-${index}`} direction="row" alignItems={'center'} gap={6}>
                        <LoadingButton
                          component={motion.div}
                          whileHover={{ scale: [1, 1.05] }}
                          onClick={runAction}
                          loading={loadingAction}
                          variant="contained"
                          startIcon={<AutoAwesome />}
                          disabled={notIncludedYet}
                          sx={{
                            // backgroundImage: `url(/assets/images/bubbles_background.png)`,
                            backgroundSize: 'cover',
                            // color: 'black',
                            borderRadius: 20,
                            backgroundColor: deepPurple.A200,
                            '&:hover': {
                              backgroundColor: deepPurple.A400,
                              boxShadow: `  0 0 2px 4px #fff, 
                              0 0 2px 5px #f0f, 
                              0 0 2px 5px #0ff; `,
                            },
                          }}
                          disableElevation
                        >
                          {action.action_id}
                        </LoadingButton>

                        {action.notIncludedYet && <Tag type="red">Action was detected but it's not available</Tag>}
                        {action.confidence && <Tag type="blue">{action.confidence}</Tag>}
                      </MUIStack>
                    ))}
                </MUIStack>
              </Stack>
            )}

            {messageId && !content.response && 'Response failed'}
            {!messageId && <LoadingDots />}
          </Bubble>

          {!hideActions && shouldShow && (
            <Box
              component={motion.div}
              animate={{ opacity: [0, 1], x: [-20, 0] }}
              sx={{ position: 'absolute', right: -41, top: 0 }}
            >
              <PathInfoBubble chain={content?.chain} />
            </Box>
          )}
          {messageId && (
            <div style={{ display: 'flex', gap: 4, justifyContent: 'flex-end', marginTop: '0.5rem' }}>
              {!hideActions && (
                <>
                  <IconButton size="small" onClick={handleUpVoteClick} sx={{ color: 'white' }}>
                    {upVote ? <ThumbsUpFilled size={iconSize} /> : <ThumbsUp size={iconSize} />}
                  </IconButton>
                  <IconButton size="small" onClick={handleDownVoteClick} sx={{ color: 'white' }}>
                    {downVote ? <ThumbsDownFilled size={iconSize} /> : <ThumbsDown size={iconSize} />}
                  </IconButton>
                  <Tooltip title="Copy to clipboard" arrow>
                    <IconButton size="small" onClick={handleCopyClick} sx={{ color: 'white' }}>
                      {isCopied ? <Checkmark size={iconSize} /> : <Copy size={iconSize} />}
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
          )}
        </Stack>
      </MessageContainer>
    </Box>
  );
};

export default Message;
