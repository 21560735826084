import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@carbon/react';
import { motion } from 'framer-motion';

export default function AnimatedTable({ headers, rows, onAnimationEnd }) {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.1,
      },
    },
    exit: { opacity: 0 },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
      },
    },
    exit: { opacity: 0, y: -20 },
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="exit"
      onAnimationComplete={onAnimationEnd}
      variants={containerVariants}
    >
      <Table>
        {headers && (
          <TableHead>
            <TableRow>
              {headers.map((header, idx) => (
                <TableHeader key={idx}>
                  <motion.span variants={itemVariants}>{header}</motion.span>
                </TableHeader>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {rows?.map((row, idx) => (
            <TableRow key={idx}>
              {row.map((cell, cellIdx) => (
                <TableCell key={cellIdx} style={{ paddingTop: 20, paddingBottom: 20 }}>
                  <motion.span variants={itemVariants}>{cell}</motion.span>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </motion.div>
  );
}
