export const pickerColors = ['#000000', '#0f62fe', '#6fdc8c', '#ffafd2', '#fa4d56', '#fddc69'];

const ibmColors = ['#0f62fe', '#8a3ffc', '#007d79', '#198038', '#da1e28', '#d02670'];

export function getColorFromInitials(name) {
  const initials = name.split(' ');

  let sum = 0;
  initials.forEach((initial) => {
    sum += initial.charCodeAt(0);
  });
  const colorIndex = sum % ibmColors.length;
  return ibmColors[colorIndex];
}
