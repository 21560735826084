import axios from 'axios';
import env from '../../env';
import resolve from '../../utils/APIUtils';
import { useAuth } from '../useAuth';

const end = '/v1';

export function useUserApi() {
  const { axiosInstance } = useAuth();

  let redirectUrl = '';

  if (env.REACT_APP_DEV) {
    redirectUrl = 'http://localhost:3000/login';
  }

  async function getLoginEndpoint() {
    return resolve(
      axios.get(`${env.REACT_APP_BACKEND_URL}${end}/login`, { params: { redirectUrl } }).then((res) => res.data)
    );
  }

  async function generateLoginToken(code) {
    return resolve(
      axios.post(`${env.REACT_APP_BACKEND_URL}${end}/login/generate`, { code, redirectUrl }).then((res) => res.data)
    );
  }

  async function getUser() {
    return resolve(axiosInstance.get(`${end}/users/me`).then((res) => res.data));
  }

  async function getUsers() {
    return resolve(axiosInstance.get(`${end}/users`).then((res) => res.data));
  }

  return { getLoginEndpoint, getUser, getUsers, generateLoginToken };
}
