import { ArrowLeft } from '@carbon/icons-react';
import { Button } from '@carbon/react';
import { Box, Chip, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const Instructions = ({ step, showBack, handleChipClick, previousStep, controls }) => {
  const navigate = useNavigate();
  const NAME_IDEAS = ['Customer Support', 'Investment', 'Loan', 'Security and Fraud Prevention', 'Finance'];

  return (
    <Box
      component={motion.div}
      animate={controls}
      sx={{
        backgroundColor: '#8a3ffc',
        width: '100%',
        height: '100%',
        p: 5,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Typography variant="overline" color="white" sx={{ fontSize: 20, letterSpacing: 2 }} mb={1.5}>
          {step.title}
        </Typography>
        <Typography variant="body2" sx={{ color: (theme) => theme.palette.grey[200] }}>
          {step.subtitle}
        </Typography>
        {step.id === 'name' && (
          <Box mt={6} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.grey[200] }}>
              Need some ideas?
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {NAME_IDEAS.map((idea, i) => (
                <Chip key={i} label={idea} color="secondary" clickable onClick={() => handleChipClick(idea)} />
              ))}
            </Box>
          </Box>
        )}
      </Box>
      {showBack && (
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }} mt={3}>
          <Button kind="ghost" onClick={previousStep} style={{ color: 'white' }} renderIcon={ArrowLeft}>
            Back
          </Button>
        </Box>
      )}
      {!showBack && (
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }} mt={3}>
          <Button kind="ghost" onClick={() => navigate('/projects')} renderIcon={ArrowLeft} style={{ color: 'white' }}>
            Go to projects
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Instructions;
