// ConversationIdea.js
import { ClickableTile } from '@carbon/react';
import { Typography, lighten } from '@mui/material';
import { motion } from 'framer-motion';
import './conversation-idea.scss';

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: (index) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: index * 0.1,
    },
  }),
};

const ConversationIdea = ({ color, index, text, icon, onIdeaClick }) => (
  <motion.div
    key={index}
    custom={index}
    variants={itemVariants}
    initial="hidden"
    animate="visible"
    whileHover={{
      scale: 1.05,
      opacity: 0.9,
    }}
    whileTap={{
      scale: 1.1,
    }}
  >
    {/* <Button
      variant="contained"
      onClick={() => onIdeaClick(text)}
      sx={{
        width: '100%',
        textTransform: 'none',
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '150px',
        color: (theme) => (color ? theme.palette.getContrastText(color) : 'auto'),
        backgroundColor: color || 'auto',
        borderRadius: (theme) => theme.spacing(2, 2, 0, 2),
        borderColor: color || 'auto',
        ':hover': {
          borderColor: color || 'auto',
          backgroundColor: color ? alpha(color, 0.9) : 'auto',
        },
      }}
    >
      {icon}
      <Typography mt={1.5}>"{text}"</Typography>
    </Button> */}

    <ClickableTile
      onClick={() => onIdeaClick(text)}
      className="idea-tile"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '20px 20px 0 20px',
        height: '100%',
        borderRight: color && `5px solid ${lighten(color, 0.2)}`,

        borderBottomLeft: 'none',
        backgroundColor: color || 'defaultColor',
        // transition: 'background-color 0.3s', // Adding a smooth transition effect
        // ':hover': {
        //   backgroundColor: darken(color, 0.1),
        // },
      }}
    >
      <Typography sx={{ color: (theme) => color && theme.palette.getContrastText(color) }}>"{text}"</Typography>
    </ClickableTile>
  </motion.div>
);

export default ConversationIdea;
