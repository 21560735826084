import { LoadingButton } from '@mui/lab';
import { Box, Container, Stack, Typography, styled, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingPage from '../../components/LoadingPage';
import { useUserApi } from '../../hooks/api/useUserApi';
import { useAuth } from '../../hooks/useAuth';
import './secure-layout.scss';

const StyledCard = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: theme.spacing(1, 2, 3, 2),
  // borderRadius: theme.spacing(1),
  backgroundColor: 'rgba(255, 255, 255, 0.1) !important',

  boxShadow: `inset 1.52px 3.04px 3.04px -0.76px rgba(255, 255, 255, 0.25), inset 0px -0.76px 0.76px rgba(255, 255, 255, 0)`,
  filter: 'drop-shadow(0px 18px 23px rgba(0, 0, 0, 0.05))',
  backdropFilter: 'blur(150px)',
  /* Note: backdrop-filter has minimal browser support */
  borderRadius: '26px',
}));

const LoginCard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { getLoginEndpoint } = useUserApi();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState(null);

  function isOnline() {
    return navigator.onLine;
  }

  const redirect = async () => {
    try {
      const { data } = await getLoginEndpoint();
      if (data?.url) window.location.href = data.url;
    } catch (error) {
      console.error('Error fetching login URL:', error);
      setError(true);
      if (!isOnline()) {
        setErrorType('no_internet');
      } else {
        setErrorType('api_error');
      }
    }
  };

  const theme = useTheme();

  return (
    <Container maxWidth="sm">
      <StyledCard className="login-card">
        <Box className="login-header-bar">
          <Stack spacing={1} direction="row" alignItems="center" className="mock-actions-container">
            <Box
              component={motion.div}
              sx={{ width: 12, height: 12, borderRadius: '50%', background: (theme) => theme.palette.error.main }}
            />
            <Box
              component={motion.div}
              sx={{ width: 12, height: 12, borderRadius: '50%', background: (theme) => theme.palette.warning.main }}
            />
            <Box
              component={motion.div}
              sx={{ width: 12, height: 12, borderRadius: '50%', background: (theme) => theme.palette.success.main }}
            />
          </Stack>
          <Typography variant="subtitle1" ml={1}>
            Login using IBMid
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', width: '100%', height: '100%', py: 1 }}>
          <Box sx={{ height: '100%', p: 4 }}>
            <Box
              component="img"
              src="/assets/logo.svg"
              sx={{
                width: 70,
                height: 100,
              }}
            />
          </Box>
          <Box p={4} sx={{ textAlign: 'start' }}>
            <Typography gutterBottom variant="body1" mb={2}>
              Enterprise AI assistants
            </Typography>
            <Typography variant="body2" mb={1}>
              - Safeguarded data
            </Typography>
            <Typography variant="body2" mb={1}>
              - Adherence to global regulations
            </Typography>
            <Typography variant="body2" mb={1}>
              - Seamless open-source compatibility with any model
            </Typography>
            <Box mb={4} sx={{ zIndex: 99, mt: 1, userSelect: 'none' }}>
              <Typography variant="caption" color="text.secondary">
                Powered by{' '}
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                watson<span style={{ color: theme.palette.primary.light }}>x</span>.ai
              </Typography>
            </Box>
            <LoadingButton
              variant="contained"
              color="primary"
              disableElevation
              loading={loading}
              onClick={async () => {
                try {
                  setLoading(true);
                  sessionStorage.setItem('initialRoute', location.pathname);
                  await redirect();
                } catch (e) {
                  setLoading(false);
                  setError(true);
                }
              }}
            >
              Log in
            </LoadingButton>
          </Box>
        </Box>

        {error && <ErrorBox />}
      </StyledCard>
    </Container>
  );
};

const ErrorBox = ({ errorType }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      gap: 1,
    }}
  >
    <Typography variant="caption" color="error" align="center" mb={1}>
      {errorType === 'no_internet'
        ? 'No hay conexión a Internet. Por favor, verifica tu conexión y vuelve a intentarlo.'
        : 'No hemos podido iniciar la sesión, vuelve a intentarlo más tarde'}
    </Typography>
  </Box>
);

export default function SecureLayout({ children }) {
  const { isAuthenticated, isCheckingToken, isAxiosReady } = useAuth();

  // useEffect(() => {
  //   if (isAuthenticated) getUser();
  // }, [isAuthenticated]);

  if (isCheckingToken || !isAxiosReady) {
    return <LoadingPage />;
  }

  return isAuthenticated ? (
    <Box class="app_container">{children}</Box>
  ) : (
    <Box sx={{ position: 'relative' }}>
      <LoginCard />
    </Box>
  );
}
