import { Search } from '@carbon/react';
import { Box } from '@mui/material';

const ChatSearchBar = ({ searchValue, setSearchValue }) => {
  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2, marginBottom: 3 }}>
      <Search
        id="search-input"
        labelText=""
        placeholder="Search chats"
        value={searchValue}
        onChange={handleInputChange}
        style={{
          backgroundColor: 'transparent',
          background: 'rgba(0, 0, 0, 0.2)',
          boxShadow: `inset 1.52px 3.04px 3.04px -0.76px rgba(0, 0, 0, 0.25), inset 0px -0.76px 0.76px rgba(255, 255, 255, 0.35)`,
          borderRadius: 10,
        }}
      />
    </Box>
  );
};

export default ChatSearchBar;
