import resolve from '../../utils/APIUtils';
import { useAuth } from '../useAuth';

const end = '/v1';

export function useProjectApi() {
  const { axiosInstance } = useAuth();

  async function createProject(name, color, userIds) {
    return resolve(axiosInstance.post(`${end}/projects`, { name, color, userIds }).then((res) => res.data));
  }

  async function getProjects() {
    return resolve(axiosInstance.get(`${end}/projects`).then((res) => res.data));
  }

  async function getProject(id) {
    return resolve(axiosInstance.get(`${end}/projects/${id}`).then((res) => res.data));
  }

  async function updateProject(id, chatConfig) {
    return resolve(axiosInstance.put(`${end}/projects/${id}`, { chatConfig }).then((res) => res.data));
  }

  async function updateProjectMembers(id, userIds) {
    return resolve(axiosInstance.put(`${end}/projects/${id}`, { userIds }).then((res) => res.data));
  }

  async function updateProjectName(id, name) {
    return resolve(axiosInstance.put(`${end}/projects/${id}`, { name }).then((res) => res.data));
  }

  async function updateProjectFlow(id, flowConfig) {
    return resolve(axiosInstance.put(`${end}/projects/${id}`, { flowConfig }).then((res) => res.data));
  }

  async function getProjectIntegrations(id) {
    return resolve(axiosInstance.get(`${end}/projectConfigs/${id}/integrations`).then((res) => res.data));
  }

  async function getIntegrationDetails(projectId, integrationId) {
    return resolve(
      axiosInstance.get(`${end}/projectConfigs/${projectId}/integrations/${integrationId}`).then((res) => res.data)
    );
  }

  async function getIntegrations() {
    return resolve(axiosInstance.get(`${end}/integrations`).then((res) => res.data));
  }

  async function getIntegrationConnectionDetails(projectId, integrationId, fieldId) {
    return resolve(
      axiosInstance
        .get(`${end}/projectConfigs/${projectId}/integrations/${integrationId}/values`, {
          params: {
            fieldId,
          },
        })
        .then((res) => res.data)
    );
  }

  async function removeIntegrationDetails(projectId, integrationId) {
    return resolve(
      axiosInstance.delete(`${end}/projectConfigs/${projectId}/integrations/${integrationId}`).then((res) => res.data)
    );
  }

  async function updateIntegrationDetails(projectId, integrationId, values) {
    return resolve(
      axiosInstance
        .put(`${end}/projectConfigs/${projectId}/integrations/${integrationId}`, values)
        .then((res) => res.data)
    );
  }

  async function removeProject(id) {
    return resolve(axiosInstance.delete(`${end}/projects/${id}`).then((res) => res.data));
  }

  async function getPipelines(projectId) {
    return resolve(axiosInstance.get(`${end}/projectConfigs/${projectId}/pipelines`).then((res) => res.data));
  }

  async function updatePipelines(projectId, orderedIds) {
    return resolve(
      axiosInstance.put(`${end}/projectConfigs/${projectId}/pipelines`, { ids: orderedIds }).then((res) => res.data)
    );
  }

  async function createIntegration(projectId, integrationId) {
    return resolve(
      axiosInstance.post(`${end}/projectConfigs/${projectId}/integrations/${integrationId}`).then((res) => res.data)
    );
  }

  async function removeIntegration(projectId, projectIntegrationId) {
    return resolve(
      axiosInstance
        .delete(`${end}/projectConfigs/${projectId}/integrations/${projectIntegrationId}`)
        .then((res) => res.data)
    );
  }

  return {
    createProject,
    getProjects,
    getProject,
    updateProject,
    updateProjectName,
    updateProjectFlow,
    removeProject,
    getProjectIntegrations,
    getIntegrations,
    getIntegrationDetails,
    removeIntegrationDetails,
    updateIntegrationDetails,
    getIntegrationConnectionDetails,
    getPipelines,
    updatePipelines,
    updateProjectMembers,
    createIntegration,
    removeIntegration,
  };
}
