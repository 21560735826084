import { Add } from '@carbon/icons-react';
import { Button, InlineNotification, Search } from '@carbon/react';
import { Box, CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import ProjectCard from '../../components/ProjectCard/ProjectCard';
import { ProjectContext } from '../../contexts/ProjectContext';
import { useProjectApi } from '../../hooks/api/useProjectsApi';
import './projects-page.scss';

const mockProjects = [
  { name: 'Review classifier', createdAt: new Date().getTime(), color: '#333333' },
  { name: 'Email generator', createdAt: new Date().getTime(), color: '#48E5C2' },
  { name: 'Talk to PDF', createdAt: new Date().getTime(), color: '#EE4266' },
  { name: 'Talk to API', createdAt: new Date().getTime(), color: '#FFD23F' },
  { name: 'Job description', createdAt: new Date().getTime(), color: '#E980FC' },
  { name: 'Questions and answers', createdAt: new Date().getTime(), color: '#999AC6' },
];

const ProjectsPage = () => {
  const { error } = useContext(ProjectContext);

  const { removeProject, getProjects } = useProjectApi();

  const [projects, setProjects] = useState(null);

  const [searchValue, setSearchValue] = useState('');

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleAddProject = () => {
    navigate('/new-project');
  };

  const retrieveProjects = () => {
    getProjects().then((res) => {
      if (res.data) {
        const sortedProjects = res.data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        setProjects(sortedProjects);
      }
    });
  };

  const filteredProjects =
    projects && projects.filter((project) => project.name?.toLowerCase().includes(searchValue.toLowerCase()));

  const numProjects = filteredProjects ? filteredProjects.length : 0;

  const handleRemoveProject = (id) => {
    removeProject(id).then((res) => {
      if (!res.error) retrieveProjects();
    });
  };

  useEffect(() => {
    retrieveProjects();
  }, []);

  const response = `Let's say I have this map:

  \\begin{code}
  myMap = new Map([
    ['first', { id: 1 }],
    ['second', { id: 2 }],
    ['third', { id: 3 }]
  ])
  \\end{code}
  
  I'm trying to create a function in javascript that I can pass the map to and get an array of the \`id\` properties. I'm trying to create a function in javascript that I can pass the map to and get an array of the \`id\` properties.
  
  Expected output: \`[1, 2, 3]\`
  
  So far, I've tried this:
  
  \\begin{code}
  function getIds (map) {
    let ids = [];
    map.forEach((value, key) => {
      ids.push(value.id);
    });
    return ids;
  }
  \\end{code}
  
  But it returns \`undefined\`.  Any idea where I'm going wrong?`;

  return (
    <>
      <Helmet>
        <title> Prompto Settings</title>
      </Helmet>
      <Box id="projects_settings_container" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {error && <InlineNotification kind="error" title="An error occurred" subtitle={error} lowContrast />}

        <Box className="projects-header">
          <Stack pt={10} pb={5} mx={4}>
            <Stack direction="row" alignItems="center" mb={2} spacing={2}>
              <Typography variant="h3" sx={{ fontWeight: 450 }}>
                Projects Settings
              </Typography>
              <IconButton
                size="small"
                onClick={handleAddProject}
                sx={{
                  boxShadow:
                    'inset 0px 1px 6px rgba(255, 255, 255, 0.35), inset -1px 1px 1px -2px rgba(255, 255, 255, 0.35)',
                  filter: 'drop-shadow(0px 18px 23px rgba(0, 0, 0, 0.05))',
                  '&:hover': {
                    backgroundColor: (theme) => theme.palette.primary.main,
                  },
                }}
              >
                <Add size={20} style={{ color: 'white' }} />
              </IconButton>
            </Stack>

            <Box sx={{ width: 350 }}>
              <Search
                labelText="Hello"
                id="search-1"
                placeholder="Search projects..."
                onChange={handleInputChange}
                size="lg"
                style={{
                  zIndex: 1,
                  backgroundColor: 'transparent',
                  background: 'rgba(0, 0, 0, 0.2)',
                  boxShadow: `inset 1.52px 3.04px 3.04px -0.76px rgba(0, 0, 0, 0.25), inset 0px -0.76px 0.76px rgba(255, 255, 255, 0.35)`,
                  borderRadius: 10,
                }}
              />
            </Box>
          </Stack>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              background: 'url(/assets/patterns/projects-banner.svg)',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: '100%',
              width: '60%',
              opacity: 0.8,
            }}
          />
        </Box>
        <Box px={4} sx={{ height: '100%', overflow: 'auto' }} pb={2}>
          {!projects ? (
            <Box
              mt={8}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '40vh',
              }}
            >
              <CircularProgress />
              <Typography variant="caption" color="text.secondary" mt={2}>
                Retrieving projects...
              </Typography>
            </Box>
          ) : projects && projects.length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '40vh',
              }}
            >
              <Typography variant="h4">It's lonely here...</Typography>
              <Typography variant="body2" gutterBottom mb={2}>
                Let's create a new project to start chatting!
              </Typography>
              <Button color="primary" onClick={() => navigate('/new-project')} renderIcon={Add}>
                Create New Project
              </Button>
            </Box>
          ) : (
            <>
              <Typography variant="subtitle1" sx={{ fontWeight: 400, marginTop: 2, marginBottom: 2 }}>
                {numProjects || 0} projects
              </Typography>
              <Grid container spacing={2}>
                <AnimatePresence>
                  {filteredProjects.map((project, index) => (
                    <Grid key={project.id} item xs={12} sm={6} md={4} lg={3}>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3, delay: index * 0.1 }}
                        style={{ height: '100%' }}
                      >
                        <ProjectCard
                          project={project}
                          animate={false}
                          onClick={() => navigate(`/projects/${project.id}`)}
                          onDelete={() => handleRemoveProject(project.id)}
                        />
                      </motion.div>
                    </Grid>
                  ))}
                </AnimatePresence>
              </Grid>
            </>
          )}
        </Box>

        {/* <Box px={4} sx={{ height: '100%' }}>
          {!projects ? (
            <Box
              mt={8}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '40vh',
              }}
            >
              <CircularProgress />
              <Typography variant="caption" color="text.secondary" mt={2}>
                Retrieving projects...
              </Typography>
            </Box>
          ) : projects && projects.length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '40vh',
              }}
            >
              <Typography variant="h4">It's lonely here...</Typography>
              <Typography variant="body2" gutterBottom mb={2}>
                Let's create a new project to start chatting!
              </Typography>
              <Button color="primary" onClick={() => navigate('/new-project')} renderIcon={Add}>
                Create New Project
              </Button>
            </Box>
          ) : (
            <>
              <Typography variant="subtitle1" sx={{ fontWeight: 400, marginTop: 2 }}>
                {numProjects || 0} projects
              </Typography>
              <Grid container spacing={2} mt={1} sx={{ overflow: 'auto' }}>
                <AnimatePresence>
                  {filteredProjects.map((project, index) => (
                    <Grid key={project.id} item xs={12} sm={6} md={4} lg={3}>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3, delay: index * 0.1 }}
                        style={{ height: '100%' }}
                      >
                        <ProjectCard
                          project={project}
                          animate={false}
                          onClick={() => navigate(`/projects/${project.id}`)}
                          onDelete={() => handleRemoveProject(project.id)}
                        />
                      </motion.div>
                    </Grid>
                  ))}
                </AnimatePresence>
              </Grid>
            </>
          )}
        </Box> */}
      </Box>
    </>
  );
};

export default ProjectsPage;
