import { memo } from 'react';
import { Position } from 'reactflow';
import UserMessageInput from '../../../components/UserMessageInput';
import CustomHandle from '../CustomHandle';

export default memo(({ data }) => {
  const { isConnectable, maxConnections } = data;

  return (
    <>
      <UserMessageInput />
      <CustomHandle
        type="source"
        position={Position.Right}
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
        maxConnections={maxConnections}
      />
    </>
  );
});
