import { ChevronDown, Send } from '@carbon/icons-react';
import { Button, TextInput } from '@carbon/react';
import { Box, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useRef, useState } from 'react';
import SendButton from '../SendButton';
import './form-message-input.scss';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      staggerDirection: -1,
    },
  },
};

const item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

const FormMessageInput = ({
  forceExtend,
  onSendMessage,
  generatingAnswer,
  handlePauseGenerating,
  mainColor,
  secondaryColor,
  showPoweredBy = true,
  fields,
  sx,
}) => {
  const [extend, setExtend] = useState(forceExtend);

  const [fieldValues, setFieldValues] = useState(Array(fields.length).fill(''));

  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const inputRef = useRef(null);

  const clear = () => {
    setFieldValues(Array(fields.length).fill(''));
  };

  const handleSendMessage = async () => {
    if (!checkAllFieldsHaveValues()) return;
    setIsLoading(true);
    clear();

    const keyValue = fields.map((field, i) => `${field.title}: ${fieldValues[i]}`);
    const joined = keyValue.join('\n');

    await onSendMessage(joined);
    setIsLoading(false);
    setExtend(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleFocus = () => {
    setExtend(true);
  };

  const handleClose = () => {
    clear();
    setExtend(false);
  };

  const handleInputChange = (e, index) => {
    const newFieldValues = [...fieldValues];
    newFieldValues[index] = e.target.value;
    setFieldValues(newFieldValues);
  };

  const checkAllFieldsHaveValues = () => fieldValues?.every((fieldValue) => fieldValue.trim() !== '');

  return (
    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
      {/* {generatingAnswer === 'generating' && (
        <Box sx={{ textAlign: 'center', zIndex: 99, mt: 0.5 }}>
          <Button kind="secondary" renderIcon={Stop} onClick={handlePauseGenerating}>
            Stop generating
          </Button>
        </Box>
      )} */}

      <Box
        component="form"
        onSubmit={handleSendMessage}
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'flex-end',
          position: 'relative',
          gap: '1rem',
          ...sx,
        }}
      >
        {/* <AnimatePresence> */}
        <Box sx={{ position: 'relative', width: '100%' }}>
          {!extend && (
            <Stack spacing={2} sx={{ width: '100%' }} variants={container}>
              <TextInput
                type="text"
                className="chat-text-input"
                id="text-input-1"
                hideLabel
                size="lg"
                placeholder="Write a message..."
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                style={{
                  backgroundColor: 'transparent',
                  background: 'rgba(0, 0, 0, 0.2)',
                  boxShadow: `inset 1.52px 3.04px 3.04px -0.76px rgba(0, 0, 0, 0.25), inset 0px -0.76px 0.76px rgba(255, 255, 255, 0.35)`,
                  borderRadius: 10,
                }}
              />
            </Stack>
          )}
          {/* </AnimatePresence> */}

          <AnimatePresence>
            {extend && (
              <Stack
                component={motion.div}
                spacing={2}
                variants={container}
                initial="hidden"
                animate="show"
                exit={{ opacity: 0 }}
                sx={{ position: 'absolute', width: '100%', bottom: 0 }}
              >
                {fields?.map((f, index) => (
                  <motion.div variants={item} key={f.id}>
                    <TextInput
                      className="chat-text-input"
                      size="lg"
                      id={f.id}
                      placeholder={f.title}
                      value={fieldValues[index] || ''}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </motion.div>
                ))}
              </Stack>
            )}
          </AnimatePresence>
        </Box>
        <Stack spacing={2}>
          <AnimatePresence>
            {extend && (
              <motion.div
                initial={{ y: 52, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 52, opacity: 0 }}
                transition={{ type: 'spring', bounce: 0.25 }}
              >
                <Button
                  component={motion.div}
                  kind="secondary"
                  hasIconOnly
                  renderIcon={ChevronDown}
                  onClick={handleClose}
                  iconDescription="Close form"
                />
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            <motion.div
              initial={{ y: 16, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 12, opacity: 0, transition: { type: 'inertia' } }}
            >
              <SendButton
                color={secondaryColor}
                onClick={handleSendMessage}
                disabled={!checkAllFieldsHaveValues() || isLoading || generatingAnswer}
                style={{ height: inputRef.current ? inputRef.current.clientHeight : undefined, zIndex: 10 }}
              >
                {isLoading ? <CircularProgress color="inherit" size={24} /> : <Send size={24} />}
              </SendButton>
            </motion.div>
          </AnimatePresence>
        </Stack>
      </Box>
      {showPoweredBy && (
        <Box sx={{ textAlign: 'center', zIndex: 99, mt: 0.5, userSelect: 'none' }}>
          <Typography variant="caption" color="text.secondary">
            Powered by{' '}
          </Typography>
          <Typography variant="caption" sx={{ fontWeight: 600 }}>
            watson<span style={{ color: theme.palette.primary.light }}>x</span>.ai
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FormMessageInput;
