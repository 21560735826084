import { createContext, useState } from 'react';

export const GlobalThemeContext = createContext();

export const GlobalThemeProvider = ({ children }) => {
  const getInitialTheme = () => {
    const savedTheme = window.localStorage.getItem('theme');
    if (savedTheme) {
      return savedTheme;
    }
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  };

  const [theme, setTheme] = useState('dark');

  const toggleTheme = () => {
    setTheme((currentTheme) => (currentTheme === 'light' ? 'dark' : 'light'));
  };

  // useEffect(() => {
  //   window.localStorage.setItem('theme', theme);
  // }, [theme]);

  return <GlobalThemeContext.Provider value={{ theme, toggleTheme }}>{children}</GlobalThemeContext.Provider>;
};
