import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingPage from '../components/LoadingPage';
import { useUserApi } from '../hooks/api/useUserApi';
import { useAuth } from '../hooks/useAuth';

function isOnline() {
  return navigator.onLine;
}

export default function LoginPage() {
  const { login } = useAuth();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const [error, setError] = useState(true);
  const [errorType, setErrorType] = useState(null);
  const navigate = useNavigate();

  const { generateLoginToken, getLoginEndpoint } = useUserApi();

  const handleAuthentication = async () => {
    try {
      const { data } = await generateLoginToken(code);

      if (data?.accessToken) {
        login(data.accessToken);

        const initialRoute = sessionStorage.getItem('initialRoute');

        if (initialRoute) sessionStorage.removeItem('initialRoute');

        navigate(initialRoute || '/chat');
      }
    } catch (error) {
      console.error('Error authenticating:', error);
      sessionStorage.removeItem('authToken');
      setError(true);
      if (!isOnline()) {
        setErrorType('no_internet');
      } else {
        setErrorType('api_error');
      }
    }
  };

  const handleInitialRedirect = async () => {
    if (code) {
      await handleAuthentication();
    } else {
      redirect();
    }
  };

  const redirect = async () => {
    try {
      const { data } = await getLoginEndpoint();
      if (data?.url) window.location.href = data.url;
    } catch (error) {
      console.error('Error fetching login URL:', error);
      setError(true);
      if (!isOnline()) {
        setErrorType('no_internet');
      } else {
        setErrorType('api_error');
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await handleInitialRedirect();
    };

    fetchData();
  }, []);
  return <LoadingPage includeGradient />;
}
