const {
  ModelBuilderReference,
  IbmWatsonDiscovery,
  WatsonHealthDna,
  IbmWatsonTextToSpeech,
  WatsonxData,
  WatsonxAi,
  ServiceId,
} = require('@carbon/icons-react');

export const integrationsInfo = {
  'integration.bam': {
    id: 'integration.bam',
    icon: WatsonxAi,
    title: (
      <span>
        watson<span style={{ color: '#4589ff' }}>x</span>.ai
      </span>
    ),
    description: 'Boost your assistant using generative AI capabilities, powered by foundation models',
    color: '#0F62FE',
  },
  'integration.customservice': {
    id: 'integration.customservice',
    icon: ServiceId,
    title: <span>Custom Service</span>,
    description: `Treat the answer in your way using your own exposed service`,
    color: '#f94751',
    disabled: true,
  },
  'integration.wd': {
    id: 'integration.wd',
    icon: IbmWatsonDiscovery,
    title: 'Watson Discovery',
    description: 'Enhances assistant responses with intelligent search in your documents and data',
    color: '#7c4dff',
    // disabled: true,
  },
  'integration.langchain': {
    id: 'integration.langchain',
    icon: WatsonxData,
    title: <span>Milvus</span>,
    description: `Enhance assistant responses with efficient and scalable vector similarity search`,
    color: '#ff9800',
  },
};
