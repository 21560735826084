import { Renew } from '@carbon/icons-react';
import { Box, Button, Typography, alpha } from '@mui/material';

const ErrorBox = ({ errorType }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '70vh',
      gap: 1,
    }}
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
      }}
    >
      <Typography variant="h5" color="text.secondary">
        Oops!
      </Typography>
      <Typography variant="h5" color="text.secondary">
        Chat is not available
      </Typography>
    </Box>
    <Typography variant="body2" color="text.secondary" align="center" mb={1}>
      {errorType === 'no_internet'
        ? 'No hay conexión a Internet. Por favor, verifica tu conexión y vuelve a intentarlo.'
        : "We're sorry, but we weren't able to connect to the chat. Please try again later."}
    </Typography>
    <Button
      onClick={() => window.location.reload()}
      variant="text"
      color="primary"
      endIcon={<Renew />}
      sx={{ backgroundColor: alpha('#fff', 0.1), color: 'white', px: 2 }}
    >
      Try again
    </Button>
  </Box>
);

export default ErrorBox;
