import { useState } from 'react';
// @mui
import { Box, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import { ProjectProvider } from '../../contexts/ProjectContext';
//
import './dashboard.scss';
import Nav from './nav';
import './nav/nav.scss';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 48;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  // display: 'flex',
  // minHeight: '100%',
  // overflow: 'hidden',
  background: 'url(/assets/images/app_background.jpg)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: '100%',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: 62,
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    paddingTop: '0px',
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <ProjectProvider>
      <StyledRoot>
        {/* <Hidden mdUp>
        <Header onOpenNav={() => setOpen(true)} />
      </Hidden> */}

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            py: { xs: 2, sm: 4, md: 8 },
            px: { xs: 2, sm: 6, md: 12 },
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '100%',
              heigth: '100%',
              backdropFilter: 'blur(5px)',
            },
          }}
        >
          <Nav openNav={open} onCloseNav={() => setOpen(false)} />

          {/* {matches ? (
            <Nav openNav={open} onCloseNav={() => setOpen(false)} />
          ) : (
            <Header onOpenNav={() => setOpen(true)} />
          )} */}

          <Outlet />

          {/* <Box
            sx={{
              paddingTop: !matches && `${APP_BAR_MOBILE}px`,
              marginLeft: matches && '60px',
            }}
          >
            <Outlet />
          </Box> */}
        </Box>
      </StyledRoot>
    </ProjectProvider>
  );
}
