import { Information } from '@carbon/icons-react';
import { FormLabel, Toggletip, ToggletipButton, ToggletipContent } from '@carbon/react';

const InfoTooltip = ({ name, description }) => (
  <>
    <FormLabel>{name}</FormLabel>
    <Toggletip>
      <ToggletipButton label="Show information">
        <Information />
      </ToggletipButton>
      <ToggletipContent>{description}</ToggletipContent>
    </Toggletip>
  </>
);

export default InfoTooltip;
