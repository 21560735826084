import { ChevronRight } from '@carbon/icons-react';
import { Box, ButtonBase, Container, Grid, emphasize, useTheme } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Instructions from '../../components/ProjectStepper/Instructions';
import StepColor from '../../components/ProjectStepper/steps/StepColor';
import StepMembers from '../../components/ProjectStepper/steps/StepMembers';
import StepName from '../../components/ProjectStepper/steps/StepName';
import ProjectPreviewCard from '../../components/projects/ProjectPreviewCard/ProjectPreviewCard';
import { ProjectContext } from '../../contexts/ProjectContext';
import { useProjectApi } from '../../hooks/api/useProjectsApi';
import { useUserApi } from '../../hooks/api/useUserApi';
import './new-project-page.scss';
import { useAuth } from '../../hooks/useAuth';

export default function NewProjectPage() {
  const theme = useTheme();
  const navigate = useNavigate();

  const { fetchProjects } = useContext(ProjectContext);
  const { createProject } = useProjectApi();
  const { getUsers } = useUserApi();
  const { user } = useAuth();

  const [step, setStep] = useState(0);

  const [projectName, setProjectName] = useState('');
  const [selectedColor, setSelectedColor] = useState('#0f62feff');
  const [members, setMembers] = useState([user?.id]);

  const [loadingCreation, setLoadingCreation] = useState(false);

  const [users, setUsers] = useState(null);

  const [showReview, setShowReview] = useState(false);

  const handleColorClick = (color) => {
    setSelectedColor(color.hex);
  };

  const handleToggleMember = (id, isSelected) => {
    if (isSelected) {
      setMembers((prevMembers) => [...prevMembers, id]);
    } else {
      setMembers((prevMembers) => prevMembers.filter((memberId) => memberId !== id));
    }
  };

  const controls = useAnimation();

  const handleTextFieldChange = (event) => {
    setProjectName(event.target.value);
  };

  const handleChipClick = (name) => {
    setProjectName(name);
  };

  const colors = ['#8a3ffc', theme.palette.primary.main, theme.palette.secondary.dark];

  const nextStep = () => {
    if (!selectedStep.disabled) {
      if (step < steps.length - 1) {
        controls.start({
          backgroundColor: colors[step + 1],
          transition: { duration: 0.5 },
        });

        setStep(step + 1);
      } else {
        setShowReview(true);
        controls.start({
          backgroundColor: selectedColor,
          transition: { duration: 0.5 },
        });
      }
    }
  };

  const steps = [
    {
      id: 'name',
      title: 'Project name',
      subtitle: 'The best project names clearly describe the chat purpose',
      component: (
        <StepName projectName={projectName} handleTextFieldChange={handleTextFieldChange} nextStep={nextStep} />
      ),
      disabled: !projectName,
    },
    {
      id: 'members',
      title: 'Include members',
      subtitle: 'Add everyone you want to be part of this project',
      component: (
        <StepMembers
          users={users && Object.values(users)}
          members={members}
          handleToggleMember={handleToggleMember}
          setMembers={setMembers}
        />
      ),
      disabled: !members.length,
    },
    {
      id: 'color',
      title: 'Select a color',
      subtitle: 'Choose a color for your project',
      component: <StepColor selectedColor={selectedColor} handleColorClick={handleColorClick} />,
      disabled: !selectedColor,
    },
  ];

  const nextBtnColors = [theme.palette.primary.main, theme.palette.secondary.main, theme.palette.primary.main];

  const previousStep = () => {
    if (step > 0) {
      controls.start({
        x: [0, -40, 0],
        backgroundColor: colors[step - 1],
        transition: { duration: 0.5 },
      });
      setStep(step - 1);
    }
  };

  const handleCreateProject = () => {
    setLoadingCreation(true);

    createProject(projectName, selectedColor, members).then((res) => {
      if (res.data) {
        fetchProjects();
        // navigate(`/projects/${res.data.id}`);
        navigate('/projects');
      } else {
        console.error('Error al crear el proyecto', res);
      }
      setLoadingCreation(false);
    });
  };

  const handleBack = () => {
    setShowReview(false);
  };

  useEffect(() => {
    getUsers().then((res) => {
      if (res.data) {
        const formattedUsers = res.data.map((u) => ({ ...u, name: u.name }));
        const usersObj = formattedUsers.reduce((acc, user) => {
          acc[user.id] = user;
          return acc;
        }, {});
        setUsers(usersObj);
      }
    });
  }, []);

  const selectedStep = steps[step];

  return (
    <Box sx={{ height: '100%' }}>
      <Container
        sx={{
          height: '100%',
          py: 4,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        maxWidth="md"
      >
        <Box
          sx={{
            height: { xs: '100%', md: 650, display: 'flex', justifyContent: 'center', alignItems: 'center' },
            width: '100%',
          }}
        >
          {showReview ? (
            <ProjectPreviewCard
              selectedColor={selectedColor}
              projectName={projectName}
              members={members}
              users={users}
              handleBack={handleBack}
              loadingCreation={loadingCreation}
              handleCreateProject={handleCreateProject}
            />
          ) : (
            <Box
              component={motion.div}
              animate={{ scale: [0, 1], opacity: [0, 1], transition: { delay: 0.3, duration: 0.5, ease: 'easeOut' } }}
              layoutId="underline"
              className="new-project-container"
              sx={{
                minWidth: '100%',
                height: '100%',
                borderRadius: (theme) => theme.spacing(6),
                display: 'flex',
              }}
            >
              <Grid container sx={{ height: '100%', flexGrow: 1 }} direction={{ xs: 'column', md: 'row' }}>
                <Grid item xs sx={{ zIndex: 999 }}>
                  <Instructions
                    step={selectedStep}
                    showBack={step > 0}
                    previousStep={previousStep}
                    handleChipClick={handleChipClick}
                    setStep={setStep}
                    controls={controls}
                  />
                </Grid>
                <Grid item xs>
                  {selectedStep.component}
                </Grid>
                <Grid item xs="auto">
                  <ButtonBase
                    disabled={selectedStep.disabled}
                    onClick={nextStep}
                    sx={{
                      height: '100%',
                      backgroundColor: (theme) =>
                        selectedStep.disabled ? theme.palette.grey[800] : nextBtnColors[step],
                      width: { xs: '100%', md: 'auto' },
                      borderRadius: (theme) => theme.spacing(0, 0, 0, 0),
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: (theme) => theme.spacing(1, 2),
                      boxShadow: 1,
                      '&:hover': {
                        backgroundColor: (theme) => emphasize(nextBtnColors[step]),
                        boxShadow: 3,
                      },
                    }}
                  >
                    <ChevronRight color={selectedStep.disabled ? theme.palette.grey[600] : 'white'} size={30} />
                  </ButtonBase>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
}
