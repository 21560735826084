// OptionsList component
import { DocumentMultiple_02 as DocumentMultiple } from '@carbon/icons-react';

import { Box, Grid, Link } from '@mui/material';
import './options-tiles.scss';

const OptionButton = ({ name, value, onClick, checked }) => (
  <Box
    className="glass glass_border"
    sx={{
      p: 3,
      cursor: 'pointer',
      backgroundColor: (theme) => (checked ? theme.palette.primary.main : 'rgb(0,0,0,0.3)'),
    }}
    onClick={() => onClick(value)}
    value={value}
  >
    <div className="option_tiles--container">
      <div className="option_tiles--tile-title">
        <DocumentMultiple size={20} />
        <span>{name}</span>
      </div>
    </div>
  </Box>
);

const OptionsTiles = ({ title, description, options, selectedOption, handleOptionChange }) => (
  <div>
    {/* <TileGroup
      defaultSelected={selectedOption}
      legend={title}
      name="radio tile group"
      valueSelected={selectedOption}
      onChange={handleOptionChange}
      className="horizontal-tiles-group"
      style={{ display: 'flex' }}
    >


      {options.map((option) => (
        <RadioTile light key={option.id} id={option.id} value={option.id} style={{ marginBottom: '.5rem' }}>
          <div className="option_tiles--container">
            <div className="option_tiles--title">
              <DocumentMultiple />
              <span>{option.name}</span>
            </div>
            <div className="option_tiles--elements">
              <div>
                <span>Model</span>
              </div>
              <div>
                <span>Params</span>
              </div>
              <div>
                <span>Instruction</span>
              </div>
              <div>
                <span>Multi-shot Prompt</span>
              </div>
            </div>
          </div>
        </RadioTile>
      ))}
    </TileGroup> */}

    <div className="options_tiles--title-container">
      <span className="options_tiles--title">{title}</span>
      <div className="options_tiles--description">
        {description} Configure your model, parameters (temperature, top k...) and few-shot prompting in{' '}
        <Link className="options_tiles--description" href="https://bam.res.ibm.com/" target="_blank">
          watsonx prompt laboratory
        </Link>
      </div>
    </div>

    <Grid container spacing={2} sx={{ height: '100%' }}>
      {options.map((option) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={option.id} className="grid-item">
          <OptionButton
            name={option.name}
            value={option.id}
            onClick={handleOptionChange}
            checked={option.id === selectedOption}
          />
        </Grid>
      ))}
    </Grid>
  </div>
);

export default OptionsTiles;
