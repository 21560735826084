import { Box, Container } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import ChatDrawer from '../components/ChatDrawer';
import ErrorBox from '../components/ErrorBox';
import Chat from '../components/chat/Chat';
import { useChatApi } from '../hooks/api/useChatApi';
import { useProjectApi } from '../hooks/api/useProjectsApi';
import { useAuth } from '../hooks/useAuth';

export default function ChatPage() {
  const { id } = useParams();

  const { createChat, getSocket, getChat, getChats, getChatMessages, removeChat } = useChatApi();
  const { getProject } = useProjectApi();

  const { isAuthenticated } = useAuth();

  const [openChatList, setOpenChatList] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [chatName, setChatName] = useState(null);
  const [newChat, setNewChat] = useState(true);

  const [generatingAnswer, setGeneratingAnswer] = useState(null);

  const [messages, setMessages] = useState([]);

  const [pendingMessage, setPendingMessage] = useState(null);

  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [error, setError] = useState(false);

  const [connectionStablished, setConnectionStablished] = useState(false);

  const socketRef = useRef(null);

  const handlePauseGenerating = () => setGeneratingAnswer('pause');

  const handleCompleteGenerating = () => setGeneratingAnswer('completed');

  const createNewChat = async (projectId) => {
    const response = await createChat(projectId);
    return response.data;
  };

  useEffect(() => {
    if (isAuthenticated && selectedChat) {
      setConnectionStablished(false);

      const newSocket = getSocket();
      socketRef.current = newSocket;

      newSocket.on('login', (data) => {
        if (data.statusCode === 401) {
          console.error('Error', data);

          // logout();
        }

        newSocket.emit('join', { chatId: selectedChat.id });
      });

      newSocket.on('join', (data) => {
        if (data.statusCode === 401) {
          console.error('Error', data);
          // logout();
        } else setConnectionStablished(true);
      });

      newSocket.on('message', (data) => {
        if (data.statusCode === 200) {
          const newMessage = data.message;

          newMessage.isNew = true;

          setMessages((prevMessages) => {
            const shouldReplaceLastMessage =
              prevMessages.length > 0 && !('response' in prevMessages[prevMessages.length - 1]);

            return shouldReplaceLastMessage
              ? [...prevMessages.slice(0, -1), newMessage]
              : [...prevMessages, newMessage];
          });

          setGeneratingAnswer('generating');
        } else {
          setError(error.message);
        }
      });

      newSocket.on('name', (data) => {
        if (data.statusCode !== 200) {
          console.error('WS error');
        } else {
          const { name } = data.message;
          setChatName(name);
          setChats((prevChats) =>
            prevChats.map((chat) => (chat.id === selectedChat.id ? { ...selectedChat, name } : chat))
          );
        }
      });

      newSocket.on('connect_error', (error) => {
        console.error('Connection error:', error);
      });

      if (!newSocket.connected) newSocket.connect();
    }
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [selectedChat, isAuthenticated]);

  useEffect(() => {
    (async () => {
      clear();

      getProject(id).then((res) => {
        if (res.data) setSelectedProject(res.data);
      });

      if (id) {
        const res = await getChats(id);
        if (res.data) setChats(res.data);
      }
    })();
  }, [id]);

  useEffect(() => {
    if (connectionStablished && pendingMessage) {
      handleSendMessage(pendingMessage);
      setPendingMessage(null);
    }
  }, [connectionStablished]);

  const handleItemClick = async (id) => {
    setSelectedChat(null);
    setNewChat(false);
    setMessages(null);
    setChatName(null);
    setSelectedItemId(id);

    getChatMessages(id).then((res) => {
      if (res.data) setMessages(res.data);
    });

    await getChat(id).then((res) => {
      if (res.data) setSelectedChat(res.data);
    });

    setOpenChatList(false);
  };

  const deleteChat = (id) => {
    setSelectedChat(null);
    setSelectedItemId(null);

    setNewChat(true);

    removeChat(id);
    const indexToDelete = chats.findIndex((chat) => chat.id === id);

    setChats((prevChats) => prevChats.filter((chat) => chat.id !== id));

    const newSelectedItem = indexToDelete > 0 ? chats[indexToDelete - 1].id : chats[indexToDelete + 1]?.id || -1;
    setSelectedItemId(newSelectedItem);
  };

  const addNewChat = () => {
    setNewChat(true);

    setMessages([]);
    setChatName(null);
    setSelectedChat(null);
    setSelectedItemId(null);
    setConnectionStablished(false);
    if (socketRef.current) socketRef.current.disconnect();
  };

  const clear = () => {
    setIsLoading(true);
    setNewChat(true);
    setChats(null);
    setSelectedProject(null);
    setMessages([]);
    setChatName(null);
    setSelectedChat(null);
    setSelectedItemId(null);
    setConnectionStablished(false);
    if (socketRef.current) socketRef.current.disconnect();
    setOpenChatList(false);
  };

  const handleError = (message, error) => {
    console.error(error);
    setError(message);
  };

  const handleSendMessage = async (inputMessage) => {
    try {
      setIsLoading(true);

      if (!selectedChat) {
        const newChat = await createNewChat(selectedProject.id);
        setPendingMessage(inputMessage?.trim());
        newChat.autoCreated = true;

        setChats((prevInitialChats) => [...prevInitialChats, newChat]);

        setSelectedChat(newChat);
        setNewChat(false);
        setSelectedItemId(newChat.id);
      } else {
        socketRef.current.emit('message', { message: inputMessage });
        setMessages((prevMessages) => [...prevMessages, { request: inputMessage }]);
      }

      setIsLoading(false);
    } catch (error) {
      handleError('Message could not be sent', error);
    }
  };

  const selectedChatListItem = chats?.find((chat) => chat.id === selectedItemId);

  return (
    <>
      <Helmet>
        <title> {selectedProject?.name || 'Prompto Chat'} </title>
      </Helmet>

      <Box id="chat_parent_container" sx={{ display: 'flex', height: '100%', marginTop: { xs: 0, md: 0 } }}>
        <ChatDrawer
          projectId={selectedProject?.id}
          color={selectedProject?.chatConfig?.mainColor}
          openChatList={openChatList}
          chats={chats}
          handleClose={() => setOpenChatList(false)}
          addNewChat={addNewChat}
          selectedItemId={selectedItemId}
          handleItemClick={handleItemClick}
          deleteChat={deleteChat}
          loading={Boolean(!selectedProject)}
        />

        {error && (
          <Container
            disableGutters
            maxWidth={false}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100vh - 64px)',
              boxSizing: 'border-box',
              px: 4,
            }}
          >
            <Box mt={5}>
              <ErrorBox />
            </Box>
          </Container>
        )}

        {!error && (
          <Chat
            projectName={selectedProject?.name}
            mainColor={selectedProject?.chatConfig?.mainColor}
            logoURL={selectedProject?.chatConfig?.logoURL}
            generatingAnswer={generatingAnswer}
            handlePauseGenerating={handlePauseGenerating}
            handleCompleteGenerating={handleCompleteGenerating}
            secondaryColor={selectedProject?.chatConfig?.secondaryColor}
            loading={Boolean(!selectedProject)}
            chat={selectedChat}
            chatName={chatName}
            messages={messages}
            chatHeaderInfo={selectedChatListItem}
            isNewChat={newChat}
            handleSendMessage={handleSendMessage}
            suggestions={selectedProject?.chatConfig?.suggestions}
            customInputFields={selectedProject?.chatConfig?.inputForms}
            isJSONAnswer={selectedProject?.chatConfig?.isJSONAnswer}
          />
        )}

        {/* {!selectedProject && isAuthenticated && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100vh',
              zIndex: 99,
              backdropFilter: 'blur(4px)',
              mb: 4,
            }}
          >
            <NoProjectsCard />
          </Box>
        )} */}
      </Box>
    </>
  );
}
