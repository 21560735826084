import { Chat } from '@carbon/icons-react';
import { Typography } from '@mui/material';
import { memo } from 'react';
import { Position } from 'reactflow';
import CustomHandle from '../CustomHandle';

export default memo(({ data }) => {
  const { isConnectable, maxConnections } = data;

  return (
    <>
      <CustomHandle
        type="target"
        position={Position.Left}
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
        maxConnections={maxConnections}
      />
      <div className="user-output-node glass" style={{ maxWidth: 300 }}>
        <Chat size={16} color="white" />
        <Typography variant="subtitle1" color="white">
          Assistant response
        </Typography>
      </div>
    </>
  );
});
