import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import SendButton from '../chat/SendButton';

import './customized-input-base.scss';

export default function CustomizedInputBase({
  value,
  onChange,
  onKeyDown,
  onSend,
  disabled,
  secondaryColor,
  files,
  setFiles,
  inputFileRef,
  resetInput,
}) {
  return (
    <Paper
      component="form"
      className="custom-textfield"
      sx={{
        pl: 1,
        pr: 0.5,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        // borderRadius: 10,
        // backgroundColor: 'transparent !important',
        background: 'rgba(0, 0, 0, 0.3) !important',
        boxShadow: `inset 1.52px 3.04px 3.04px -0.76px rgba(0, 0, 0, 0.25), inset 0px -0.76px 0.76px rgba(255, 255, 255, 0.35)`,
      }}
    >
      {/* <Box p={1}>
        <ExcelUploader files={files} setFiles={setFiles} inputFileRef={inputFileRef} resetInput={resetInput} />
      </Box> */}
      {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
      <InputBase
        autoFocus
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        multiline
        sx={{ ml: 1, my: 1, flex: 1, width: '100%', alignSelf: 'center' }}
        placeholder="Write a message..."
        inputProps={{ 'aria-label': 'write a message', maxLength: 4096 }}
        maxRows={10}
      />

      <SendButton color={secondaryColor} onClick={onSend} disabled={disabled} />
    </Paper>
  );
}
