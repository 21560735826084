// @mui
import { Box, CircularProgress, Container, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// components
import Logo from '../components/logo';
// sections
import { useUserApi } from '../hooks/api/useUserApi';
import { useAuth } from '../hooks/useAuth';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  background: `linear-gradient(45deg, ${theme.palette.primary.light} 30%, ${theme.palette.primary.dark} 90%)`,
}));

const StyledCard = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(4),
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.paper,
}));

const StyledLogo = styled(Logo)(({ theme }) => ({
  width: 150,
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

export default function RedirectPage() {
  const auth = useAuth();
  const theme = useTheme();

  const { generateLoginToken, getLoginEndpoint } = useUserApi();

  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');

  useEffect(() => {
    if (code) {
      generateLoginToken(code).then((res) => {
        if (res.data) {
          const { accessToken } = res.data;
          auth.login(accessToken);
        }
      });
    }
  }, []);

  return (
    <>
      <StyledRoot>
        <StyledCard>
          <StyledLogo />

          {code ? (
            <CircularProgress />
          ) : (
            <>
              <Typography variant="h4" gutterBottom mb={5}>
                Inicia sesión en Prompto
              </Typography>

              <Container maxWidth="xs">
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  loading={loading}
                  onClick={async () => {
                    setLoading(true);
                    const { data } = await getLoginEndpoint();

                    if (data?.url) window.location.href = data.url;
                  }}
                >
                  Iniciar sesión
                </LoadingButton>
              </Container>
            </>
          )}
        </StyledCard>
      </StyledRoot>
    </>
  );
}
