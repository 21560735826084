import socketIOClient from 'socket.io-client';
import env from '../../env';
import resolve from '../../utils/APIUtils';
import { useAuth } from '../useAuth';

const end = '/v1';

export function useChatApi() {
  const { axiosInstance } = useAuth();

  async function createChat(projectId, type) {
    return resolve(axiosInstance.post(`${end}/chats`, { projectId, type }).then((res) => res.data));
  }

  async function getChat(chatId) {
    return resolve(axiosInstance.get(`${end}/chats/${chatId}`).then((res) => res.data));
  }

  async function removeChat(chatId) {
    return resolve(axiosInstance.delete(`${end}/chats/${chatId}`).then((res) => res.data));
  }
  async function getChatMessages(chatId) {
    return resolve(axiosInstance.get(`${end}/chats/${chatId}/messages`).then((res) => res.data));
  }

  async function getAllChats(projectId) {
    return resolve(axiosInstance.get(`${end}/chats`, { params: { projectId, mine: false } }).then((res) => res.data));
  }

  async function getChats(projectId) {
    return resolve(axiosInstance.get(`${end}/chats`, { params: { projectId, mine: true } }).then((res) => res.data));
  }
  async function markMessageAsValid(chatId, messageId) {
    return resolve(axiosInstance.put(`${end}/chats/${chatId}/messages/${messageId}/valid`).then((res) => res.data));
  }

  async function markMessageAsInvalid(chatId, messageId) {
    return resolve(axiosInstance.put(`${end}/chats/${chatId}/messages/${messageId}/invalid`).then((res) => res.data));
  }

  function getSocket() {
    const authToken = sessionStorage.getItem('authToken');

    return socketIOClient(`${env.REACT_APP_SOCKET_URL}/events/chats`, {
      autoConnect: false,
      path: `/v1/chatEvents`,
      reconnectionDelayMax: 10000,
      auth: {
        token: `Bearer ${authToken}`,
      },
    });
  }

  return {
    createChat,
    getChat,
    getAllChats,
    removeChat,
    getChats,
    getChatMessages,
    getSocket,
    markMessageAsValid,
    markMessageAsInvalid,
  };
}
