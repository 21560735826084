import { Close } from '@carbon/icons-react';
import { Article } from '@mui/icons-material';
import { Box, Chip, Stack, TextField, Typography, styled, useTheme } from '@mui/material';
import { green } from '@mui/material/colors';
import { useRef, useState } from 'react';
import './message-input.scss';

import CustomizedInputBase from '../../CustomizedInputBase/CustomizedInputBase';

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputBase-root': {
    color: 'white',
  },
}));

const lineHeight = 31;

const MessageInput = ({
  onSendMessage,
  generatingAnswer,
  handlePauseGenerating,
  mainColor,
  secondaryColor,
  showPoweredBy = true,
  sx,
}) => {
  const [inputMessage, setInputMessage] = useState('');

  const [files, setFiles] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  const inputRef = useRef(null);
  const inputFileRef = useRef(null);

  const handleSendMessage = async () => {
    if (inputMessage.trim() === '') return;
    setIsLoading(true);

    let messageToSend = inputMessage;
    if (files.length > 0) {
      const filesPrompt = turnFilesToPrompt();
      messageToSend += `\n\n${filesPrompt}`;
    }

    setInputMessage('');
    setFiles([]);

    await onSendMessage(messageToSend);
    setIsLoading(false);
  };

  const turnFilesToPrompt = () =>
    files.map((file) => `File name: ${file.name}\nFile data: ${JSON.stringify(file.data)}\n`).join('\n');

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const resetInput = () => {
    inputFileRef.current.value = '';
  };

  const handleDelete = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f.name !== fileName));
    resetInput();
  };

  return (
    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
      {/* {generatingAnswer === 'generating' && (
        <Box sx={{ textAlign: 'center', zIndex: 99, mt: 0.5 }}>
          <Button kind="secondary" renderIcon={Stop} onClick={handlePauseGenerating}>
            Stop generating
          </Button>
        </Box>
      )} */}

      <Box
        component="form"
        onSubmit={handleSendMessage}
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'flex-end',
          position: 'relative',
          // gap: '0.5rem',
          ...sx,
        }}
      >
        {/* <Box sx={{ mb: 0.5, mr: 2 }}>
          <ExcelUploader files={files} setFiles={setFiles} inputFileRef={inputFileRef} resetInput={resetInput} />
        </Box> */}

        <Box display="flex" sx={{ width: '100%', flexDirection: 'column' }}>
          {files.length > 0 && (
            <Stack spacing={2} mb={2} alignItems={'center'}>
              {files.map((file) => (
                <Chip
                  key={file.name}
                  icon={<Article style={{ color: green[800] }} />}
                  label={file.name}
                  onDelete={() => handleDelete(file.name)}
                  deleteIcon={<Close />}
                  sx={{ width: 'fit-content' }}
                  color="success"
                />
              ))}
            </Stack>
          )}

          <CustomizedInputBase
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            secondaryColor={secondaryColor}
            onSend={handleSendMessage}
            files={files}
            setFiles={setFiles}
            inputFileRef={inputFileRef}
            resetInput={resetInput}
            disabled={inputMessage.length === 0 || isLoading}
          />
        </Box>
        {/* <SendButton
          color={secondaryColor}
          onClick={handleSendMessage}
          disabled={inputMessage.length === 0 || isLoading || generatingAnswer}
          style={{ height: inputRef.current ? inputRef.current.clientHeight : undefined }}
        >
          {isLoading ? <CircularProgress color="inherit" size={24} /> : <Send size={24} />}
        </SendButton> */}
      </Box>
      {showPoweredBy && (
        <Box sx={{ textAlign: 'center', zIndex: 99, mt: 0.5, userSelect: 'none' }}>
          <Typography variant="caption" color="text.secondary">
            Powered by{' '}
          </Typography>
          <Typography variant="caption" sx={{ fontWeight: 600 }}>
            watson<span style={{ color: theme.palette.primary.light }}>x</span>.ai
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default MessageInput;
