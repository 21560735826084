import { Add } from '@carbon/icons-react';
import { Box, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { motion } from 'framer-motion';

const ChatListHeader = ({ color, numChats, onAddButtonClick, sx }) => {
  const theme = useTheme();

  return (
    <Box sx={{ ...sx }}>
      <Typography variant="overline" sx={{ fontWeight: 500 }}>
        ALL CHATS
      </Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" sx={{ fontWeight: 450 }}>
          Chats{' '}
          <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
            ({numChats || 0})
          </Typography>
        </Typography>

        <Tooltip title="New chat" arrow>
          <IconButton
            component={motion.button}
            whileHover={{ scale: 1.1 }}
            onClick={onAddButtonClick}
            size="small"
            className="advanced_glass_chat"
            sx={{
              boxShadow:
                'inset 0px 1px 6px rgba(255, 255, 255, 0.35), inset -1px 1px 1px -2px rgba(255, 255, 255, 0.35)',
              filter: 'drop-shadow(0px 18px 23px rgba(0, 0, 0, 0.05))',
              '&:hover': {
                backgroundColor: (theme) => color || theme.palette.primary.main,
              },
            }}
          >
            <Add
              size={20}
              style={{
                color: 'white',
                '&:hover': {
                  color: theme.palette.getContrastText(color || theme.palette.primary.main),
                },
              }}
            />
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  );
};

export default ChatListHeader;
