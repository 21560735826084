import { Chat } from '@carbon/icons-react';
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { isValid } from 'date-fns';
import CustomBreadcrumbs from '../CustomBreadcrumbs';
import TypingEffect from '../TypingEffect';

const ChatHeader = ({ name, createdAt, onOpenChatsList, logoURL, isNewChat }) => {
  const isDateValid = createdAt && isValid(new Date(createdAt));
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <Stack>
      {matches ? (
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Stack direction="row" alignItems="center" spacing={1.5} ml={0}>
            <Chat />
            <Typography color="text.primary" variant="subtitle1" sx={{ maxWidth: 300 }} noWrap>
              {name ? isNewChat ? <TypingEffect text={name} /> : name : 'New chat'}
            </Typography>
          </Stack>

          {logoURL && <Box component="img" src={logoURL} sx={{ maxWidth: 150, maxHeight: 24, objectFit: 'contain' }} />}
        </Stack>
      ) : (
        <CustomBreadcrumbs onOpenChatsList={onOpenChatsList} />
      )}

      {/* <Typography variant="body2" color="text.secondary">
        {!isDateValid ? '' : <TypingEffect text={format(new Date(createdAt), 'd MMMM yyyy, HH:mm', { locale: es })} />}
      </Typography> */}
    </Stack>
  );
};

export default ChatHeader;
