import { Chat, Renew, TrashCan, UserFollow } from '@carbon/icons-react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  InlineNotification,
  SkeletonPlaceholder,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@carbon/react';
import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import AddMembersDialog from '../../components/AddMembersDialog';
import AvatarGroup from '../../components/AvatarGroup/AvatarGroup';
import ChatCustomization from '../../components/ChatCustomization/ChatCustomization';
import EditableProjectName from '../../components/ProjectName';
import SettingsDialog from '../../components/SettingsDialog/SettingsDialog';
import { useProjectApi } from '../../hooks/api/useProjectsApi';
import { useUserApi } from '../../hooks/api/useUserApi';
import { integrationsInfo } from '../../utils/integrationsInfo';
import IntegrationsFlow from '../IntegrationsPage/IntegrationsFlow';
import './project-page.scss';

const ProjectPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { getUsers } = useUserApi();

  const {
    getProject,
    getIntegrations,
    updateIntegrationDetails,
    updateProjectMembers,
    removeProject,
    updateProjectName,
  } = useProjectApi();

  const [project, setProject] = useState(null);
  const [openMembersDialog, setOpenMembersDialog] = useState(false);

  const [users, setUsers] = useState(null);

  const [integrations, setIntegrations] = useState(null);

  const [error, setError] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const [selectedIntegration, setSelectedIntegration] = useState(null);

  const handleCloseMembersDialog = () => {
    setOpenMembersDialog(false);
  };

  const handleOpenMembersDialog = () => {
    setOpenMembersDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedIntegration(null);
    setOpenDialog(false);
  };

  const handleSaveSettings = (integrationId, formValues) => {
    updateIntegrationDetails(project.id, integrationId, formValues).then((res) => {
      const integration = res.data;

      if (!integration) setError('Something failed while trying to update the integration');
    });
  };

  const handleSaveMembers = (selected) => {
    updateProjectMembers(project.id, selected).then((res) => {
      if (res.data) {
        retrieveProject();
        handleCloseMembersDialog();
      } else setError('Something failed while trying to update the project members');
    });
  };

  const handleSaveName = (name) => {
    updateProjectName(project.id, name).then((res) => {
      if (res.data) setProject({ ...project, name });
      else setError('Something failed while trying to update the project name');
    });
  };

  const handleDeleteProject = () => {
    removeProject(project.id).then((res) => {
      if (!res.error) navigate('/projects');
      else setError('Something failed while trying to delete the project');
    });
  };

  const retrieveProject = () => {
    setProject(null);
    getProject(id).then((res) => {
      const nProject = res.data;
      if (nProject) setProject(nProject);
      else setError('The project you are trying to access could not be found');
    });
  };

  const retrieveIntegrations = () => {
    setIntegrations(null);
    getIntegrations().then((res) => {
      if (res.data) setIntegrations(res.data);
      else setError('The integrations list could not be retrieved');
    });
  };

  const retrieveUsers = () => {
    getUsers().then((res) => {
      const mUsers = res.data;
      if (mUsers) setUsers(mUsers);
      else setError('Something failed while trying to retrieve the users list');
    });
  };

  useEffect(() => {
    retrieveProject();
    retrieveIntegrations();
    retrieveUsers();
  }, []);

  return (
    <Box sx={{ height: '100%', borderRadius: '10px' }}>
      <Helmet>
        <title> Settings </title>
      </Helmet>

      <Box
        sx={{
          height: '100%',
          pt: 3,
          px: 4,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          '&:after': {
            content: '""',
            position: 'absolute',
            width: 210,
            height: 210,
            background: `linear-gradient(210.04deg, ${project?.color} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
            borderRadius: '50%',
            top: -30,
            right: -180,
          },
          '&:before': {
            content: '""',
            position: 'absolute',
            width: 210,
            height: 210,
            background: `linear-gradient(140.9deg, ${project?.color} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
            borderRadius: '50%',
            top: -160,
            right: -110,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }} mb={3}>
          <Box>
            <Breadcrumb>
              <BreadcrumbItem href="#" onClick={() => navigate('/projects')}>
                Projects
              </BreadcrumbItem>
            </Breadcrumb>

            <EditableProjectName initialName={project?.name} onSave={handleSaveName} color={project?.color} />
          </Box>
          <Stack direction="row" gap={6}>
            <Stack direction="row" gap={1} style={{ alignItems: 'center' }}>
              <AvatarGroup avatars={project?.users} maxVisibleAvatars={3} />
              <Button
                tooltipPosition="bottom"
                kind="ghost"
                size="sm"
                hasIconOnly
                renderIcon={UserFollow}
                iconDescription="Edit members"
                onClick={handleOpenMembersDialog}
              />
            </Stack>

            <Stack direction="row" gap={1} style={{ alignItems: 'center' }}>
              <Button kind="tertiary" renderIcon={Chat} onClick={() => navigate(`/chat/${project.id}`)}>
                Go to chat
              </Button>
              <Button
                hasIconOnly
                renderIcon={Renew}
                iconDescription="Refresh"
                tooltipPosition="bottom"
                onClick={() => {
                  retrieveProject();
                  retrieveIntegrations();
                  retrieveUsers();
                }}
              >
                Refresh
              </Button>
              <Button
                kind="danger"
                hasIconOnly
                renderIcon={TrashCan}
                onClick={handleDeleteProject}
                iconDescription="Remove project"
                tooltipPosition="bottom"
              >
                Delete
              </Button>
            </Stack>
          </Stack>
        </Box>

        <Tabs>
          <TabList aria-label="List of tabs" contained>
            <Tab>Integrations</Tab>

            <Tab>Customization</Tab>
          </TabList>
          <TabPanels>
            <TabPanel className="tab-panel">
              {project && <IntegrationsFlow integrations={integrations} project={project} />}
            </TabPanel>

            <TabPanel className="tab-panel">
              {project ? (
                <ChatCustomization project={project} />
              ) : (
                <SkeletonPlaceholder style={{ width: '100%', height: '100%' }} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>

        {project?.users && (
          <AddMembersDialog
            initialSelected={project?.users.map((user) => user.id)}
            users={users}
            open={openMembersDialog}
            handleClose={handleCloseMembersDialog}
            handleSave={handleSaveMembers}
          />
        )}

        <SettingsDialog
          open={openDialog}
          integration={selectedIntegration}
          projectId={project?.id}
          extraDetails={selectedIntegration && integrationsInfo[selectedIntegration.id]}
          onClose={handleCloseDialog}
          onSave={handleSaveSettings}
        />

        {error && (
          <InlineNotification
            kind="error"
            title={'Error'}
            subtitle={error}
            lowContrast
            style={{ position: 'absolute', bottom: 8, left: 8 }}
          />
        )}
      </Box>
    </Box>
  );
};

export default ProjectPage;
