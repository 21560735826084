import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#FDFDFE',
  200: '#F1F1F2',
  300: '#DADADB',
  400: '#BFBFC1',
  500: '#9E9FA1',
  600: '#7D7E80',
  700: '#616163',
  800: '#424243',
  900: '#2D2D2E',
};

const PRIMARY = {
  lighter: '#A6C8FF',
  light: '#539BFF',
  main: '#4589ff',
  dark: '#0043CE',
  darker: '#001D6C',
  contrastText: '#FFFFFF',
};

const SECONDARY = {
  lighter: '#B3B3B3',
  light: '#7F7F7F',
  main: '#4C4C4C',
  dark: '#1A1A1A',
  darker: '#000000',
  contrastText: '#FFFFFF',
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#A3DFA6',
  light: '#7BCE7E',
  main: '#4AA261',
  dark: '#3C844D',
  darker: '#2E6239',
  contrastText: GREY[100],
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    // primary: GREY[800],
    // secondary: GREY[600],
    // disabled: GREY[500],
  },
  // background: {
  //   paper: '#fff',
  //   default: '#fff',
  //   neutral: '#fff',
  //   dark: '#181818',
  // },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    // selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
