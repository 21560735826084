import { Chat } from '@carbon/icons-react';
import { Typography, alpha, emphasize, styled } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import TypingEffect from './TypingEffect';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor = alpha(theme.palette.primary.light, 1);
  return {
    backgroundColor,
    cursor: 'pointer',
    height: theme.spacing(3),
    color: 'white',
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default function CustomBreadcrumbs({ onOpenChatsList }) {
  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        <StyledBreadcrumb
          color="primary"
          component="a"
          onClick={onOpenChatsList}
          label="All chats"
          icon={<Chat size={12} style={{ marginLeft: 8 }} />}
        />

        <Typography color="text.primary" variant="subtitle2">
          <TypingEffect text={'New chat'} />
        </Typography>
      </Breadcrumbs>
    </div>
  );
}
