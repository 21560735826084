import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')(({ theme, hasGradient }) => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  background: hasGradient
    ? `linear-gradient(45deg, ${theme.palette.primary.light} 30%, ${theme.palette.primary.dark} 90%)`
    : 'none',
}));

export default function LoadingPage({ includeGradient }) {
  return (
    <StyledRoot hasGradient={includeGradient}>
      <CircularProgress sx={{ color: 'white' }} />
    </StyledRoot>
  );
}
