import { Chat, TrashCan } from '@carbon/icons-react';
import { IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import TypingEffect from '../TypingEffect';

const ListItemProps = {
  sx: {
    width: '100%',
    borderRadius: '12px',
  },
};

const ListItemTextProps = {
  primaryTypographyProps: {
    sx: {
      fontSize: 13,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
};

const IconWrapper = ({ onClickHandler, children, sx }) => (
  <IconButton size="small" color="inherit" sx={{ ...sx, pointerEvents: 'auto' }} onClick={onClickHandler}>
    {children}
  </IconButton>
);

const ChatListItem = ({ title, isSelected, isAutoCreated, onClick, onDelete, autoCreated, color }) => {
  const handleEditClick = (event) => {
    event.stopPropagation();
    // Add logic to handle chat editing here
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    onDelete();
    // Call the onDelete function when the delete button is clicked
  };

  return (
    <>
      {isSelected ? (
        <ListItem
          {...ListItemProps}
          sx={{
            ...ListItemProps.sx,
            backgroundColor: color || ((theme) => theme.palette.primary.main),
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <ListItemIcon
            sx={{ minWidth: 28, color: (theme) => (color ? theme.palette.getContrastText(color) : 'white') }}
          >
            <Chat color="inherit" />
          </ListItemIcon>
          <ListItemText
            {...ListItemTextProps}
            primary={autoCreated ? <TypingEffect text={title} /> : title || 'New chat'}
            sx={{ color: (theme) => (color ? theme.palette.getContrastText(color) : 'auto') }}
          />
          <Stack direction="row">
            <IconWrapper
              onClickHandler={handleDeleteClick}
              sx={{ color: (theme) => (color ? theme.palette.getContrastText(color) : 'auto') }}
            >
              <TrashCan sx={{ fontSize: 14 }} />
            </IconWrapper>
          </Stack>
        </ListItem>
      ) : (
        <ListItemButton onClick={onClick} {...ListItemProps}>
          <ListItemIcon sx={{ minWidth: 28, color: 'text.primary' }}>
            <Chat color="inherit" />
          </ListItemIcon>
          <ListItemText {...ListItemTextProps} sx={{ color: 'text.primary' }} primary={title || 'New chat'} />
        </ListItemButton>
      )}
    </>
  );
};

export default ChatListItem;
