import { Clean, Close } from '@carbon/icons-react';
import { Button } from '@carbon/react';
import {
  Box,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { deepPurple } from '@mui/material/colors';
import { Reorder } from 'framer-motion';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { onlyQuestionItems, typesInfo } from '../../utils/promptBuilerUtils';
import CustomOverflowButton from '../CircularMenu/CustomOverflowButton';
import InfoTooltip from '../InfoTooltip';
import { Item } from './Item';
import MessageComponent from './UserMessageComponent';
import './prompt-builder.scss';

const CustomChip = styled(Chip)(({ theme }) => ({
  borderRadius: 0,
  height: 50,
  width: '100%',
  color: 'white',
}));

const initialItems = [
  { id: 1, type: 'textfield' },
  { id: 2, type: 'question' },
];

const DEFAULT_EMPTY = { prefix: '', suffix: '' };

const DEFAULT_GLOBAL_VALUES = { prefix: '<s>[INST]', suffix: '[/INST]' };
const DEFAULT_INSTRUCTION_VALUES = { prefix: '<<SYS>>', suffix: '<</SYS>>' };
const DEFAULT_CONTEXT_VALUES = { prefix: '<<CTX_START>>', suffix: '<<CTX_END>>' };
const DEFAULT_BOT_MESSAGE_VALUES = { prefix: '<<BOT>>', suffix: '<<\\BOT>>' };
const DEFAULT_USER_MESSAGE_VALUES = { prefix: '<<USER>>', suffix: '<<\\USER>>' };

const Element = ({ item, handleDelete, handleValueChange }) => {
  if (item.type === 'question')
    return (
      <CustomChip
        // icon={<Information />}
        label={typesInfo[item.type].label}
        sx={{ backgroundColor: typesInfo[item.type].color }}
      />
    );

  if (item.type === 'context')
    return (
      <CustomChip
        // icon={<Information />}
        label={typesInfo[item.type].label}
        onDelete={() => handleDelete(item.id)}
        sx={{ backgroundColor: typesInfo[item.type].color }}
      />
    );

  if (item.type === 'information')
    return (
      <CustomChip
        // icon={<Information />}
        label={typesInfo[item.type].label}
        onDelete={() => handleDelete(item.id)}
        sx={{ backgroundColor: typesInfo[item.type].color }}
      />
    );

  if (item.type === 'instruction')
    return (
      <FormControl sx={{ width: '100%' }} variant="outlined" size="small" label="Instruction">
        <InputLabel htmlFor="component-simple">Instruction</InputLabel>

        <OutlinedInput
          label="Instruction"
          id="outlined-adornment-password"
          multiline
          maxRows={3}
          value={item.value || ''}
          onChange={(e) => handleValueChange(item.id, e.target.value)}
          sx={{ borderRadius: 0, fontSize: 14 }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                size="small"
                aria-label="toggle password visibility"
                onClick={() => handleDelete(item.id)}
                edge="end"
              >
                <Close />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    );

  return (
    <FormControl sx={{ width: '100%' }} variant="outlined" size="small">
      <OutlinedInput
        id="outlined-adornment-password"
        multiline
        maxRows={3}
        value={item.value || ''}
        onChange={(e) => handleValueChange(item.id, e.target.value)}
        sx={{ borderRadius: 0, fontSize: 14 }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              size="small"
              aria-label="toggle password visibility"
              onClick={() => handleDelete(item.id)}
              edge="end"
            >
              <Close />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
  // return <TextField size="small" />;
};

function PromptBuilder({ value, handleChange, description }) {
  const [items, setItems] = useState(value?.items || initialItems);

  const [globalValues, setGlobalValues] = useState(value?.globalValues || DEFAULT_EMPTY);
  const [instructionValues, setInstructionValues] = useState(value?.instructionValues || DEFAULT_EMPTY);
  const [botMessageValues, setBotMessageValues] = useState(value?.botMessageValues || DEFAULT_EMPTY);
  const [userMessageValues, setUserMessageValues] = useState(value?.userMessageValues || DEFAULT_EMPTY);
  const [contextValues, setContextValues] = useState(value?.contextValues || DEFAULT_EMPTY);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleAdd = (option, addToEnd) => {
    setAnchorEl(null);

    const newItem = { id: nanoid(), type: option, value: '' };

    if (newItem) setItems(addToEnd ? [...items, newItem] : [newItem, ...items]);
  };

  const handleValueChange = (id, newValue) => {
    setItems(items.map((item) => (item.id === id ? { ...item, value: newValue } : item)));
  };

  const handleDelete = (id) => {
    setItems(items.filter((item) => item.id !== id));
  };

  const handleSampleFlow = (flowType) => {
    setGlobalValues(DEFAULT_GLOBAL_VALUES);
    setInstructionValues(DEFAULT_INSTRUCTION_VALUES);
    setBotMessageValues(DEFAULT_BOT_MESSAGE_VALUES);
    setUserMessageValues(DEFAULT_USER_MESSAGE_VALUES);
    setContextValues(DEFAULT_CONTEXT_VALUES);

    if (flowType === 'qa-discovery')
      setItems([
        {
          id: nanoid(),
          value: `You are a helpful, respectful, and honest assistant. Always answer as helpfully as possible, while being safe. Your answers should not include any harmful, unethical, racist, sexist, toxic, dangerous, or illegal content. Please ensure that your responses are socially unbiased and positive in nature. If a question does not make any sense, or is not factually coherent, explain why instead of answering something not correct. If you don't know the answer to a question, please don't share false information. Any code examples must be between \\begin{code} and \\end{code}`,
          type: 'instruction',
        },
        { id: nanoid(), value: '<<SYS>> Use the following information to answer:', type: 'textfield' },
        { id: nanoid(), value: '{information}', type: 'information' },
        { id: nanoid(), value: '<</SYS>>', type: 'textfield' },
        { id: nanoid(), value: '{context}', type: 'context' },
        { id: nanoid(), value: '{question}', type: 'question' },
      ]);
    else if (flowType === 'qa-context')
      setItems([
        {
          id: nanoid(),
          value: `You are a helpful, respectful, and honest assistant. Always answer as helpfully as possible, while being safe. Your answers should not include any harmful, unethical, racist, sexist, toxic, dangerous, or illegal content. Please ensure that your responses are socially unbiased and positive in nature. If a question does not make any sense, or is not factually coherent, explain why instead of answering something not correct. If you don't know the answer to a question, please don't share false information. Any code examples must be between \\begin{code} and \\end{code}`,
          type: 'instruction',
        },
        { id: nanoid(), value: '{context}', type: 'context' },
        { id: nanoid(), value: '{question}', type: 'question' },
      ]);
    else if (flowType === 'simple') setItems([{ id: nanoid(), value: '{question}', type: 'question' }]);
    else
      setItems([
        {
          id: nanoid(),
          value: `You are a helpful, respectful, and honest assistant. Always answer as helpfully as possible, while being safe. Your answers should not include any harmful, unethical, racist, sexist, toxic, dangerous, or illegal content. Please ensure that your responses are socially unbiased and positive in nature. If a question does not make any sense, or is not factually coherent, explain why instead of answering something not correct. If you don't know the answer to a question, please don't share false information. Any code examples must be between \\begin{code} and \\end{code}`,
          type: 'instruction',
        },
        { id: nanoid(), value: '{question}', type: 'question' },
      ]);
  };

  const handleClear = () => {
    setItems(onlyQuestionItems);

    setGlobalValues(DEFAULT_EMPTY);
    setInstructionValues(DEFAULT_EMPTY);
    setBotMessageValues(DEFAULT_EMPTY);
    setUserMessageValues(DEFAULT_EMPTY);
    setContextValues(DEFAULT_EMPTY);
  };

  useEffect(() => {
    const newFlowValueList = [];

    items.forEach((item) => {
      newFlowValueList.push({ id: item.id, value: item.value, type: item.type });
    });

    const combinedValues = {
      items: newFlowValueList,
      globalValues,
      instructionValues,
      botMessageValues,
      userMessageValues,
      contextValues,
    };

    handleChange(combinedValues);
  }, [items, globalValues, instructionValues, contextValues, userMessageValues, botMessageValues]);

  const textFieldsCount = items?.filter((item) => item.type === 'textfield').length;
  const informationCount = items?.some((item) => item.type === 'information');
  const contextCount = items?.some((item) => item.type === 'context');
  const instructionCount = items?.some((item) => item.type === 'instruction');
  const hide = textFieldsCount > 2 && informationCount && contextCount;

  const options = [
    { label: 'Free text', type: 'textfield', disabled: textFieldsCount > 4 },
    { label: 'Instruction', type: 'instruction', disabled: instructionCount },
    { label: 'Chat History', type: 'context', disabled: contextCount },
    { label: 'Discovery Data', type: 'information', disabled: informationCount },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Box mb={2}>
        <InfoTooltip name="Prompt constructor" description={description} />
      </Box>

      <Grid
        container
        sx={{
          '--Grid-borderWidth': '1px',
          borderTop: 'var(--Grid-borderWidth) solid',
          borderLeft: 'var(--Grid-borderWidth) solid',
          borderColor: 'divider',
          '& > div': {
            borderRight: 'var(--Grid-borderWidth) solid',
            borderBottom: 'var(--Grid-borderWidth) solid',
            borderColor: 'divider',
          },
        }}
      >
        <Grid xs={4}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }} px={4} py={2}>
            <Typography variant="caption" sx={{ color: (theme) => theme.palette.grey[500] }}>
              Recommended templates
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              <Button size="sm" renderIcon={Clean} onClick={handleClear}>
                Clear flow
              </Button>

              <Chip color="secondary" label="Llama2 Q&A" onClick={() => handleSampleFlow('qa')} />
              <Chip color="primary" label="Llama2 Q&A | With context" onClick={() => handleSampleFlow('qa-context')} />
              <Chip
                sx={{ backgroundColor: deepPurple.A200, color: 'white' }}
                label="Llama2 Q&A | With Context | Discovery"
                onClick={() => handleSampleFlow('qa-discovery')}
              />
            </Box>
          </Box>
        </Grid>
        <Grid xs={4} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Box
            px={4}
            py={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ width: '100%' }}
          >
            <Box sx={{ width: 26 }} mb={1}>
              {!hide && (
                <CustomOverflowButton
                  hide={hide}
                  handleClick={(option) => handleAdd(option, false)}
                  options={options}
                />
              )}
            </Box>

            <Stack
              item
              container
              direction="column"
              xs
              component={Reorder.Group}
              axis="y"
              values={items}
              onReorder={setItems}
              spacing={2}
              sx={{ width: '100%' }}
            >
              {items.map((item, i) => (
                <Item key={item.id} element={item}>
                  <Element item={item} handleDelete={handleDelete} handleValueChange={handleValueChange} />
                </Item>
              ))}
            </Stack>

            <Box sx={{ width: 26 }} mt={1}>
              {!hide && (
                <CustomOverflowButton
                  hide={textFieldsCount > 2 && informationCount}
                  handleClick={(option) => handleAdd(option, true)}
                  options={options}
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid xs={4}>
          <Stack sx={{ width: '100%' }} px={4} py={2} spacing={3}>
            <MessageComponent
              name="Global delimiters"
              description="Markers that wrap the whole prompt."
              values={globalValues}
              onValuesChange={(prefix, suffix) => setGlobalValues({ prefix, suffix })}
            />
            <MessageComponent
              name="Instruction delimiters"
              description="Markers that wrap system notifications or operations. Ideal for emphasizing system-level actions or status messages in a conversation."
              values={instructionValues}
              onValuesChange={(prefix, suffix) => setInstructionValues({ prefix, suffix })}
            />

            <MessageComponent
              name="User Message Delimiters"
              description="Markers used to indicate user messages in a dialogue. Use these to differentiate user input from bot responses or system messages."
              values={userMessageValues}
              onValuesChange={(prefix, suffix) => setUserMessageValues({ prefix, suffix })}
            />
            <MessageComponent
              name="Bot Message Delimiters"
              description="Markers to signify messages originating from the bot. Enclose bot responses to highlight or segregate them in the conversation."
              values={botMessageValues}
              onValuesChange={(prefix, suffix) => setBotMessageValues({ prefix, suffix })}
            />
            <MessageComponent
              name="Chat history delimiters"
              description="Delimiters to frame contextual or background information in a conversation. Helps the model differentiate prior context from active dialogue."
              values={contextValues}
              onValuesChange={(prefix, suffix) => setContextValues({ prefix, suffix })}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PromptBuilder;
