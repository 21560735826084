import { memo } from 'react';
import { Position } from 'reactflow';
import SettingsCard from '../../../components/SettingsCard/SettingsCard';
import { integrationsInfo } from '../../../utils/integrationsInfo';
import CustomHandle from '../CustomHandle';

export default memo(({ data }) => {
  const { isConnectable, maxConnections } = data;

  return (
    <>
      <CustomHandle
        type="target"
        position={Position.Left}
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
        maxConnections={maxConnections}
      />
      <div style={{ maxWidth: 300 }}>
        <SettingsCard
          title={integrationsInfo[data.typeId]?.title}
          description={integrationsInfo[data.typeId]?.description}
          icon={integrationsInfo[data.typeId]?.icon}
          color={integrationsInfo[data.typeId]?.color}
          onClick={data.onConnect}
          onConnectToggle={data.onConnectTest}
          isEnabled={data.isEnabled}
        />
      </div>
      <CustomHandle
        type="source"
        position={Position.Right}
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
        maxConnections={maxConnections}
      />
    </>
  );
});
