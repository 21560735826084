import { ModelBuilder, Save } from '@carbon/icons-react';
import { Button, InlineNotification } from '@carbon/react';
import { Box, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, {
  Background,
  ReactFlowProvider,
  addEdge,
  useEdgesState,
  useNodesState,
  useStoreApi,
} from 'reactflow';
import 'reactflow/dist/style.css';

import Sidebar from '../../components/IntegrationsBuilder/Sidebar';
import SettingsDialog from '../../components/SettingsDialog/SettingsDialog';
import { useProjectApi } from '../../hooks/api/useProjectsApi';
import ConnectionLine from './ConnectionLine';
import ButtonEdge from './edges/ButtonEdge';
import './integrations-flow.scss';
import InputNode from './nodes/InputNode';
import ModelNode from './nodes/ModelNode';
import OutputNode from './nodes/OutputNode';

const connectionLineStyle = { stroke: '#fff' };
const snapGrid = [20, 20];

const nodeTypes = {
  modelNode: ModelNode,
  inputNode: InputNode,
  outputNode: OutputNode,
};

const idNumber = 0;

const initialNodes = [
  {
    id: 'input',
    sourcePosition: 'right',
    type: 'inputNode',
    data: { id: 'input', isConnectable: true, maxConnections: 1 },
    position: { x: 0, y: 0 },
  },
  {
    id: 'output',
    sourcePosition: 'right',
    type: 'outputNode',
    data: { id: 'output', isConnectable: true, maxConnections: 1 },
    position: { x: 1050, y: 0 },
  },
];

const flowKey = 'integrations-flow';

const defaultViewport = { x: 0, y: 0, zoom: 0.8 };

const proOptions = { hideAttribution: true };

const MIN_DISTANCE = 1000;

const Flow = ({
  onFlowInit,
  integrations,
  setSelectedIntegration,
  setOpenSettingsDialog,
  project,
  nodes,
  setNodes,
  onNodesChange,
  onSave,
}) => {
  const store = useStoreApi();

  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const { createIntegration, removeIntegration } = useProjectApi();

  const reactFlowWrapper = useRef(null);

  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const onConnectTest = (value) => {
    setNodes((nds) =>
      nds.map((node) => {
        const isEnabled = value;

        return {
          ...node,
          data: {
            ...node.data,
            isEnabled,
          },
        };
      })
    );
  };

  const removeEdge = useCallback(
    (edgeId) => {
      setEdges((prevEdges) => prevEdges.filter((edge) => edge.id !== edgeId));
    },
    [setEdges]
  );

  const edgeTypes = {
    buttonedge: (props) => <ButtonEdge {...props} removeEdge={removeEdge} />,
  };

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onConnectNode = (id, type) => {
    setOpenSettingsDialog(true);
    setSelectedIntegration({ id, type });
  };

  const onDrop = (event) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData('application/reactflow');
    const typeId = event.dataTransfer.getData('typeId');

    // check if the dropped element is valid
    if (typeof type === 'undefined' || !type) {
      return;
    }

    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    createIntegration(project.id, typeId).then((res) => {
      if (res.data) {
        const { projectIntegration } = res.data;

        const newNode = {
          id: projectIntegration.id,
          type,
          position,
          data: {
            typeId,
            isConnectable: true,
            maxConnections: 2,
            isEnabled: false,
            onConnect: () => onConnectNode(projectIntegration.id, typeId),
            onConnectTest,
          },
        };

        const newNodes = [...nodes, newNode];

        setNodes((nds) => nds.concat(newNode));

        onSave(newNodes);
      }
    });
  };

  const getClosestEdge = useCallback((node) => {
    const { nodeInternals } = store.getState();
    const storeNodes = Array.from(nodeInternals.values());

    const closestNode = storeNodes.reduce(
      (res, n) => {
        if (n.id !== node.id) {
          const dx = n.positionAbsolute.x - node.positionAbsolute.x;
          const dy = n.positionAbsolute.y - node.positionAbsolute.y;
          const d = Math.sqrt(dx * dx + dy * dy);

          if (d < res.distance && d < MIN_DISTANCE) {
            res.distance = d;
            res.node = n;
          }
        }

        return res;
      },
      {
        distance: Number.MAX_VALUE,
        node: null,
      }
    );

    if (!closestNode.node) {
      return null;
    }

    const closeNodeIsSource = closestNode.node.positionAbsolute.x < node.positionAbsolute.x;

    if (
      (closestNode.node.type === 'inputNode' || closestNode.node.type === 'outputNode') &&
      (node.type === 'inputNode' || node.type === 'outputNode')
    )
      return {};

    return {
      id: `${node.id}-${closestNode.node.id}`,
      source: closeNodeIsSource ? closestNode.node.id : node.id,
      target: closeNodeIsSource ? node.id : closestNode.node.id,
      type: 'buttonedge',
    };
  }, []);

  const onNodeDrag = useCallback(
    (_, node) => {
      const closeEdge = getClosestEdge(node);

      setEdges((es) => {
        const nextEdges = es.filter((e) => e.className !== 'temp');

        const hasExistingEdge = nextEdges.some(
          (edge) => edge?.source === closeEdge?.source || edge?.target === closeEdge?.target
        );

        if (closeEdge && !hasExistingEdge) {
          closeEdge.className = 'temp';
          nextEdges.push(closeEdge);
        }

        return nextEdges;
      });
    },
    [getClosestEdge, setEdges]
  );

  const onNodeDragStop = useCallback(
    (_, node) => {
      const closeEdge = getClosestEdge(node);

      setEdges((es) => {
        const nextEdges = es.filter((e) => e.className !== 'temp');

        const hasExistingEdge = nextEdges.some(
          (edge) => edge?.source === closeEdge?.source || edge?.target === closeEdge?.target
        );

        if (closeEdge && !hasExistingEdge) {
          nextEdges.push(closeEdge);
        }

        return nextEdges;
      });
    },
    [getClosestEdge]
  );

  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      // const flow = JSON.parse(localStorage.getItem(flowKey));
      const flow = project.flowConfig;

      if (flow) {
        const { x = 0, y = 0, zoom = 1 } = flow.viewport;

        flow.nodes = flow.nodes.map((node) => ({
          ...node,
          data: { ...node.data, onConnect: () => onConnectNode(node.id, node.data.typeId) },
        }));

        setNodes(flow.nodes || []);
        setEdges(flow.edges || []);
        // setViewport({ x, y, zoom });
      }
    };

    restoreFlow();
  }, [setNodes]);

  useEffect(() => {
    if (!project.flowConfig || Object.keys(project.flowConfig).length === 0) {
      setNodes(initialNodes);
      setEdges([]);
    } else {
      onRestore();
    }
  }, []);

  const handleDeleteNodes = async (deletedNodes) => {
    try {
      await Promise.all(
        deletedNodes.map((node) => {
          if (node.id !== 'output' && node.id !== 'input') {
            return removeIntegration(project.id, node.id);
          }
          return Promise.resolve();
        })
      );

      onSave();
    } catch (error) {
      console.error('Failed to remove one or more integrations:', error);
    }
  };

  return (
    <>
      <div className="reactflow-wrapper" ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onNodeDrag={onNodeDrag}
          onNodeDragStop={onNodeDragStop}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          connectionLineStyle={connectionLineStyle}
          snapToGrid
          snapGrid={snapGrid}
          defaultViewport={defaultViewport}
          proOptions={proOptions}
          onInit={(instance) => {
            setReactFlowInstance(instance);
            onFlowInit(instance);
          }}
          onNodesDelete={handleDeleteNodes}
          connectionLineComponent={ConnectionLine}
          fitView
          onDrop={onDrop}
          onDragOver={onDragOver}
        >
          <Background />
        </ReactFlow>
      </div>
      <Sidebar integrations={integrations} />
    </>
  );
};

const IntegrationsFlow = ({ integrations, project }) => {
  const { updatePipelines, updateProjectFlow, updateIntegrationDetails } = useProjectApi();

  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);

  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);

  const [saved, setSaved] = useState(false);

  const onFlowInit = useCallback((instance) => {
    setReactFlowInstance(instance);
  }, []);

  const getOrderedNodes = (nodes, edges) => {
    // Find the first node (the one with no incoming edges)
    const firstNode = nodes.find((node) => !edges.some((edge) => edge.target === node.id));

    const orderedNodes = [];
    for (let currentNode = firstNode; currentNode; ) {
      orderedNodes.push(currentNode);
      const nextEdge = edges.find((edge) => edge.source === currentNode.id);
      currentNode = nextEdge ? nodes.find((node) => node.id === nextEdge.target) : null;
    }

    return orderedNodes;
  };

  const onSave = useCallback(
    (mNodes) => {
      setError(false);
      setSaved(false);
      setWarning(false);

      if (reactFlowInstance) {
        const flow = reactFlowInstance.toObject();

        const filteredNodes = getOrderedNodes(flow.nodes, flow.edges);

        const orderedNodes = filteredNodes.filter((n) => n.id !== 'input' && n.id !== 'output').map((n) => n.id);

        // if (flow.nodes.length !== filteredNodes.length) setWarning('One or more nodes are not connected.');
        // else {
        Promise.all([updatePipelines(project.id, orderedNodes), updateProjectFlow(project.id, flow)])
          .then(([res1, res2]) => {
            if (res1.data && res2.data) {
              setSaved(true);
              localStorage.setItem(flowKey, JSON.stringify(flow));
            } else {
              setError('Your changes could not be saved. Please try again.');
            }
          })
          .catch(() => {
            setError('An error occurred while saving. Please try again.');
          });
        // }
      }
    },
    [reactFlowInstance]
  );

  useEffect(() => {
    if (reactFlowInstance) {
      const flow = reactFlowInstance.toObject();

      updateProjectFlow(project.id, flow);
    }
  }, [nodes]);

  const handleNodeChanges = (nodeChanges) => {
    onNodesChange(nodeChanges);
  };

  const handleCloseDialog = () => {
    setSelectedIntegration(null);
    setOpenSettingsDialog(false);
  };

  const updateNodeIsEnabledStatus = (id, value) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              isEnabled: value,
            },
          };
        }
        return node;
      })
    );
  };

  const handleSaveSettings = (projectIntegrationId, formValues) => {
    updateIntegrationDetails(project.id, projectIntegrationId, formValues).then((res) => {
      const integration = res.data;
      if (!integration) setError('Something failed while trying to update the integration');
      else updateNodeIsEnabledStatus(projectIntegrationId, true);
    });
  };

  return (
    <>
      <Box
        className="integrations-flow__actions-header"
        sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px' }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <ModelBuilder />
          <Typography variant="h4" sx={{ fontWeight: 400 }}>
            Integrations builder
          </Typography>
        </Stack>
        <Button size="md" renderIcon={Save} onClick={onSave}>
          Save changes
        </Button>
      </Box>
      <div className="dndflow">
        <ReactFlowProvider>
          <Flow
            onFlowInit={onFlowInit}
            integrations={integrations}
            project={project}
            setError={setError}
            setOpenSettingsDialog={setOpenSettingsDialog}
            setSelectedIntegration={setSelectedIntegration}
            nodes={nodes}
            setNodes={setNodes}
            onNodesChange={handleNodeChanges}
            onSave={onSave}
          />
        </ReactFlowProvider>
      </div>

      <SettingsDialog
        open={openSettingsDialog}
        integration={selectedIntegration}
        projectId={project?.id}
        onClose={handleCloseDialog}
        onSave={handleSaveSettings}
      />

      {error && (
        <InlineNotification
          style={{ position: 'absolute', bottom: 8, right: 8 }}
          kind="error"
          title={error}
          hideCloseButton
        />
      )}

      {warning && (
        <InlineNotification
          style={{ position: 'absolute', bottom: 8, right: 8 }}
          kind="warning"
          title={warning}
          hideCloseButton
        />
      )}

      {saved && (
        <InlineNotification
          style={{ position: 'absolute', bottom: 8, right: 8 }}
          kind="success"
          title="Success"
          subtitle="Your changes have been saved."
          role="alert"
        />
      )}
    </>
  );
};

export default IntegrationsFlow;
