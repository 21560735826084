import PropTypes from 'prop-types';
import { useMemo } from 'react';
// @mui
import { CssBaseline, responsiveFontSizes } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
//
import customShadows from './customShadows';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';
import palette from './palette';
import typography from './typography';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children, mode }) {
  const themeOptions = useMemo(
    () => ({
      palette: {
        ...palette,
        mode,
      },
      shape: { borderRadius: 22 },
      typography,
      // shadows: shadows(),
      customShadows: customShadows(),
    }),
    [mode]
  );

  let theme = createTheme(themeOptions);
  theme = responsiveFontSizes(theme);

  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
