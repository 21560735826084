import { TextInput } from '@carbon/react';
import { Box, Stack } from '@mui/material';
import InfoTooltip from '../InfoTooltip';

const MessageComponent = ({ name, description, values, onValuesChange }) => {
  const handlePrefixChange = (e) => {
    onValuesChange(e.target.value, values.suffix);
  };

  const handleSuffixChange = (e) => {
    onValuesChange(values.prefix, e.target.value);
  };

  return (
    <Box>
      <InfoTooltip name={name} description={description} />
      <Stack direction="row" spacing={1} mt={1}>
        <TextInput size="sm" labelText={'Prefix'} value={values.prefix} onChange={handlePrefixChange} />
        <TextInput size="sm" labelText={'Suffix'} value={values.suffix} onChange={handleSuffixChange} />
      </Stack>
    </Box>
  );
};

export default MessageComponent;
