import { Calendar, OverflowMenuVertical } from '@carbon/icons-react';
import { ClickableTile, OverflowMenu, OverflowMenuItem } from '@carbon/react';
import { useNavigate } from 'react-router-dom';

import AvatarGroup from '../AvatarGroup/AvatarGroup';
import './project-card.scss';

const getFormattedInitials = (name) => {
  const nameParts = name.split(' ');
  const initials = nameParts.length >= 2 ? `${nameParts[0][0]}${nameParts[1][0]}` : nameParts[0][0];
  return initials.toUpperCase();
};

const ProjectCard = ({ project, animate = true, onDelete, onClick }) => {
  const navigate = useNavigate();

  const handleClickProject = () => {
    navigate(`/projects/${project.id}`);
  };

  return (
    <ClickableTile className="project-card" style={{ borderLeft: `6px solid ${project.color}` }} onClick={onClick}>
      <div className="card-header">
        <div className="header-top">
          <h6 className="project-name">{project.name}</h6>
          {onDelete && (
            <OverflowMenu flipped icon={<OverflowMenuVertical />}>
              <OverflowMenuItem isDelete itemText="Remove project" onClick={onDelete} />
            </OverflowMenu>
          )}
        </div>
        <div className="project-date">
          <Calendar size={13} />
          <p className="project-date-label">{new Date(project.createdAt).toLocaleDateString()}</p>
        </div>
      </div>

      <div className="card-content grey-background">
        <div className="project-members">
          <AvatarGroup avatars={project.users} maxVisibleAvatars={3} />
        </div>

        {/* <Button kind="tertiary" size="sm" hasIconOnly renderIcon={ArrowRight} label="Go to project" onClick={onClick}>
          Go to project
        </Button> */}
      </div>
    </ClickableTile>
  );
};

export default ProjectCard;
