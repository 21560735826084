import { Connect, Undefined } from '@carbon/icons-react';
import { Button, InlineLoading, InlineNotification } from '@carbon/react';
import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Skeleton,
  alpha,
} from '@mui/material';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useProjectApi } from '../../hooks/api/useProjectsApi';
import { integrationsInfo } from '../../utils/integrationsInfo';
import { getStatusTag } from '../../utils/status';
import FieldsList from '../FieldsList';
import { getInitFormValues } from './formHelper';
import './settings-dialog.scss';

const validateFormValues = (config, formValues) =>
  config
    .filter((conf) => !conf.optional)
    .every((conf) => formValues[conf.id] !== null && formValues[conf.id] !== undefined && formValues[conf.id] !== '');

const sortFieldsByConnectionRequirement = (config) => {
  const fieldsWithConnection = [];
  const fieldsWithoutConnection = [];

  config.forEach((item) => {
    if (item.requiresConnection) {
      fieldsWithConnection.push(item);
    } else {
      fieldsWithoutConnection.push(item);
    }
  });

  return [fieldsWithConnection, fieldsWithoutConnection];
};

export default function SettingsDialog({ open, integration, onClose, projectId, extraDetails, onSave }) {
  const { getIntegrationDetails, updateIntegrationDetails, getIntegrationConnectionDetails } = useProjectApi();

  const [integrationDetails, setIntegrationDetails] = useState(null);

  const [fields, setFields] = useState(null);
  const [reqConnectionFields, setReqConnectionFields] = useState(null);
  const [extraFields, setExtraFields] = useState(null);

  const [fieldsStatus, setFieldsStatus] = useState({});

  const [formValues, setFormValues] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const [status, setStatus] = useState(null);

  const [error, setError] = useState('');

  const [saveEnabled, setSaveEnabled] = useState(false);

  const reset = () => {
    setIntegrationDetails(null);

    setFields(null);
    setReqConnectionFields(null);
    setExtraFields(null);

    setFormValues({});
    setFormErrors({});

    setStatus(null);
    setError('');

    setSaveEnabled(false);
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleFormChange = (value, id, subId) => {
    setFormValues((prevFormValues) => {
      if (subId) {
        return {
          ...prevFormValues,
          [id]: {
            ...prevFormValues[id],
            [subId]: value,
          },
        };
      }
      return {
        ...prevFormValues,
        [id]: value,
      };
    });
  };

  const handleSubmit = () => {
    const errors = {};

    integrationDetails.config.forEach((conf) => {
      if (
        conf.type !== 'subgroup' &&
        !conf.optional &&
        (formValues[conf.id] === undefined || formValues[conf.id] === null || formValues[conf.id] === '')
      ) {
        errors[conf.id] = 'This field is required';
      }
    });

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      onSave(integration.id, formValues);
      handleClose();
    }
  };

  const handleConnect = () => {
    const mergedValues = {
      ...integrationDetails?.projectIntegration?.config,
      ...formValues,
    };

    updateIntegrationDetails(projectId, integration.id, mergedValues).then((res) => {
      if (res.data) {
        handleRetrieveExtra();
      } else setStatus('error');
    });
  };

  const handleRetrieveExtra = () => {
    setExtraFields(null);
    setStatus('active');
    getIntegrationConnectionDetails(projectId, integration.id).then((res) => {
      const mExtraFields = res.data;

      if (mExtraFields) {
        const mInitFormValues = getInitFormValues(mExtraFields, integrationDetails?.projectIntegration?.config);

        setFormValues((prevState) => ({
          ...prevState,
          ...mInitFormValues,
        }));

        setExtraFields(mExtraFields);
        setStatus('finished');
      } else setStatus('error');
    });
  };

  const handleRetrieveExtraFromField = (fieldId) => {
    setFieldsStatus((prev) => ({ ...prev, [fieldId]: 'active' }));
    updateIntegrationDetails(projectId, integration.id, formValues).then((res) => {
      if (res.data) {
        getIntegrationConnectionDetails(projectId, integration.id, fieldId).then((res) => {
          const mExtraFields = res.data;

          if (mExtraFields) {
            const mInitFormValues = getInitFormValues(mExtraFields, integrationDetails?.projectIntegration?.config);

            setFormValues((prevState) => ({
              ...prevState,
              ...mInitFormValues,
            }));

            setExtraFields((prev) => [...prev, ...mExtraFields]);
            setFieldsStatus((prev) => ({ ...prev, [fieldId]: 'finished' }));
          } else setFieldsStatus((prev) => ({ ...prev, [fieldId]: 'error' }));
        });
      } else setFieldsStatus((prev) => ({ ...prev, [fieldId]: 'error' }));
    });
  };

  const Icon = integrationDetails && integrationsInfo[integrationDetails.id]?.icon;

  useEffect(() => {
    if (integration) {
      getIntegrationDetails(projectId, integration.id).then((res) => {
        const mIntegrationDetails = res.data;

        if (mIntegrationDetails) {
          // if (mIntegrationDetails.projectIntegration?.config) handleRetrieveExtra();

          const [fieldsWithConnection, fieldsWithoutConnection] = sortFieldsByConnectionRequirement(
            mIntegrationDetails.config
          );

          setFields(fieldsWithoutConnection);

          setReqConnectionFields(fieldsWithConnection);

          const mInitalFormValues = getInitFormValues(
            mIntegrationDetails.config,
            mIntegrationDetails.projectIntegration?.config
          );

          setFormValues(mInitalFormValues);

          setIntegrationDetails(mIntegrationDetails);
        }
      });
    } else reset();
  }, [integration]);

  useEffect(() => {
    if (integrationDetails && extraFields) {
      const allNonOptionalFieldsHaveValue = validateFormValues(integrationDetails.config, formValues);
      const allExtraFieldsHaveValue = validateFormValues(extraFields, formValues);

      setSaveEnabled(allNonOptionalFieldsHaveValue && allExtraFieldsHaveValue);
    }
  }, [formValues, integrationDetails, extraFields]);

  // const disableConnection = reqConnectionFields.map()

  const isAnyConnectionFieldEmpty = reqConnectionFields?.some(
    (f) =>
      !f.optional &&
      (formValues[f.id] === null ||
        formValues[f.id] === '' ||
        (Array.isArray(formValues[f.id]) && formValues[f.id].length === 0))
  );

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      preventCloseOnClickOutside
      onRequestClose={handleClose}
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      primaryButtonDisabled={!saveEnabled}
      onRequestSubmit={handleSubmit}
      onSecondarySubmit={handleClose}
      PaperProps={{
        style: {
          backgroundColor: 'rgb(255,255,255,0.1)',
          backdropFilter: 'blur(100px)',
          boxShadow: `inset 1.52px 3.04px 3.04px -0.76px rgba(0, 0, 0, 0.15), inset 0px -0.76px 0.76px rgba(255, 255, 255, 0.25)`,
          filter: 'drop-shadow(0px 18px 23px rgba(0, 0, 0, 0.02))',
          borderRadius: '10px',
        },
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(6px)',
            backgroundColor: 'rgb(0,0,0,0.1)',
          },
        },
      }}
    >
      <DialogContent sx={{ p: 5 }}>
        {integration ? (
          <>
            <div
              className="glass"
              style={{
                marginBottom: '10px',
                width: '40px',
                height: '40px',
                backgroundColor: integration && alpha(integrationsInfo[integration.type]?.color, 0.9),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '10px',
                padding: '0.25rem',
              }}
            >
              {Icon && <Icon size={22} fill="#ffffff" />}
            </div>
            <div className="header-container">{integration && integrationsInfo[integration.type]?.title}</div>
            <p className="settings-description" style={{ marginBottom: '1rem' }}>
              {integration && integrationsInfo[integration.type]?.description}
            </p>
          </>
        ) : (
          <>
            <div
              style={{
                marginBottom: '10px',
                width: '40px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'grey',
                alignItems: 'center',
                borderRadius: '10px',
                padding: '0.25rem',
              }}
            >
              <Undefined size={16} fill="#ffffff" />
            </div>
            <div className="header-container">
              <Skeleton width={150} />
            </div>
            <p className="settings-description" style={{ marginBottom: '1rem' }}>
              <Skeleton width={350} />
            </p>
          </>
        )}

        {integration && (
          <>
            {error && <InlineNotification kind="error" title="An error occurred" subtitle={error} />}
            {(!integrationDetails || !formValues) && <CircularProgress />}

            <Box spacing={4} mt={4}>
              {formValues && (
                <>
                  {Boolean(reqConnectionFields?.length) && (
                    <Box
                      mb={4}
                      className="glass_dark glass_border"
                      sx={{ padding: 4 }}
                      component={motion.div}
                      animate={{ y: [-10, 0], opacity: [0, 1] }}
                    >
                      <Grid container rowSpacing={4} columnSpacing={10}>
                        <Grid item container xs={12} md={8} spacing={3}>
                          <FieldsList
                            fields={reqConnectionFields}
                            formValues={formValues}
                            formErrors={formErrors}
                            handleFormChange={handleFormChange}
                            handleRetrieveExtraFromField={handleRetrieveExtraFromField}
                            fieldsStatus={fieldsStatus}
                          />
                        </Grid>
                        <Grid item container xs={12} md={4}>
                          <div className="integration-actions-container">
                            {status && <InlineLoading description={getStatusTag(status)} status={status} />}
                            <Button
                              size="md"
                              variant="outlined"
                              renderIcon={Connect}
                              color="primary"
                              onClick={handleConnect}
                              // disabled={isAnyConnectionFieldEmpty}
                            >
                              Connect
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  )}

                  {fields && (
                    <Box mt={2} component={motion.div} animate={{ y: [-100, 0] }}>
                      <Grid container spacing={4}>
                        <FieldsList
                          fields={fields}
                          formValues={formValues}
                          formErrors={formErrors}
                          handleFormChange={handleFormChange}
                          handleRetrieveExtraFromField={handleRetrieveExtraFromField}
                          fieldsStatus={fieldsStatus}
                        />
                      </Grid>
                    </Box>
                  )}

                  {extraFields && (
                    <Box mt={4} component={motion.div} animate={{ y: [-10, 0], opacity: [0, 1] }}>
                      <Grid container spacing={4}>
                        <FieldsList
                          fields={extraFields}
                          formValues={formValues}
                          formErrors={formErrors}
                          handleFormChange={handleFormChange}
                          handleRetrieveExtraFromField={handleRetrieveExtraFromField}
                          fieldsStatus={fieldsStatus}
                        />
                      </Grid>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ px: 5, pb: 3 }}>
        <Button kind="secondary" color="success" onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Save changes</Button>
      </DialogActions>
    </Dialog>
  );
}
