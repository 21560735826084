import {
  Breadcrumb,
  BreadcrumbItem,
  CodeSnippet,
  CodeSnippetSkeleton,
  InlineNotification,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tile,
} from '@carbon/react';
import { Box, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';

import { DotMark } from '@carbon/icons-react';
import { useChatApi } from '../../hooks/api/useChatApi';
import { useProjectApi } from '../../hooks/api/useProjectsApi';
import './evaluation-page.scss';

const EvaluationPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();

  const { getAllChats, getChatMessages } = useChatApi();
  const [messages, setMesagges] = useState(null);

  const { getProject } = useProjectApi();
  const [chats, setChats] = useState(null);

  const [project, setProject] = useState(null);

  const [error, setError] = useState(false);

  const retrieveProject = () => {
    getProject(id).then((res) => {
      if (res.data) setProject(res.data);
      else setError('The project you are trying to access could not be found');
    });
  };

  useEffect(() => {
    retrieveProject();
  }, []);

  useEffect(() => {
    getAllChats().then((res) => {
      const mChats = res.data;
      if (mChats) {
        setChats(mChats);

        const promiseArray = mChats.map((mChat) => getChatMessages(mChat.id));

        Promise.all(promiseArray)
          .then((chatMessagesArray) => {
            // Aquí cada elemento de chatMessagesArray es la resolución de una de las promesas
            // chatMessagesArray[i] es la respuesta de getChatMessages(mChats[i].id)
            // Podrías realizar un mapeo para transformarlo a un formato que prefieres
            const allMessages = chatMessagesArray.map((res) => {
              const mChatMessages = res.data;

              return mChatMessages;
              // return mChatMessages.map((item) => {

              //   return { input: item.request, output: item.response };
              // });
            });

            // Unir todos los mensajes en un solo array
            const mergedMessages = [].concat(...allMessages);

            setMesagges(mergedMessages); // Asumiendo que quieres todos los mensajes en un solo array
          })
          .catch((error) => {
            console.error('Error getting chat messages:', error);
            // Manejar el error
          });
      }
    });
  }, []);

  const allMessages = messages?.map((item) => ({ input: item?.request, output: item?.response }));
  const correctMessages = messages
    ?.filter((m) => m?.isValid)
    .map((item) => ({ input: item.request, output: item.response }));
  const incorrectMessages = messages
    ?.filter((m) => m?.isValid === false)
    .map((item) => ({ input: item.request, output: item.response }));

  const totalAnsweredMessages = (correctMessages?.length || 0) + (incorrectMessages?.length || 0);
  const modelKitAccuracy =
    totalAnsweredMessages > 0 ? (((correctMessages?.length || 0) / totalAnsweredMessages) * 100).toFixed(0) : '-';

  // Calculating evaluation by users rate
  const totalMessages = allMessages?.length || 0;
  const evaluationRate = totalMessages > 0 ? ((totalAnsweredMessages / totalMessages) * 100).toFixed(0) : '-';

  const options = {
    chart: {
      type: 'donut',
    },
    labels: ['Correct Messages', 'Incorrect Messages', 'Not evaluated Messages'],
    colors: ['#42be65', '#fa4d56', '#c6c6c6'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  const series = [
    correctMessages?.length || 0,
    incorrectMessages?.length || 0,
    allMessages?.length - correctMessages?.length - incorrectMessages?.length,
  ];

  return (
    <>
      <Helmet>
        <title> Settings </title>
      </Helmet>
      <Box
        sx={{
          height: '100%',
          pt: 3,
          px: 4,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          // backgroundImage: `linear-gradient(to bottom, black, ${project?.color})`,
          '&:after': {
            content: '""',
            position: 'absolute',
            width: 210,
            height: 210,
            background: `linear-gradient(210.04deg, ${project?.color} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
            borderRadius: '50%',
            top: -30,
            right: -180,
          },
          '&:before': {
            content: '""',
            position: 'absolute',
            width: 210,
            height: 210,
            background: `linear-gradient(140.9deg, ${project?.color} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
            borderRadius: '50%',
            top: -160,
            right: -110,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }} mb={4} mt={2}>
          <Box>
            <Breadcrumb>
              <BreadcrumbItem href="#" onClick={() => navigate('/evaluation')}>
                Projects
              </BreadcrumbItem>
            </Breadcrumb>

            <Box mt={0.5} sx={{ height: 40 }}>
              <Typography variant="h3" sx={{ fontWeight: 500 }}>
                {project?.name || <Skeleton width={170} />}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Stack spacing={6}>
          <Box>
            <Stack direction="row" mb={6} spacing={4}>
              <Tile className="customization-tile">
                <Typography variant="h2">{modelKitAccuracy}%</Typography>
                <Typography variant="body2"> model kit accuracy</Typography>
              </Tile>

              <Tile className="customization-tile">
                <Typography variant="h2">{evaluationRate}%</Typography>
                <Typography variant="body2"> evaluation by users rate</Typography>
              </Tile>
            </Stack>

            <Typography variant="subtitle1" mb={2}>
              Messages
            </Typography>

            <Tabs>
              <TabList aria-label="List of tabs" contained>
                <Tab>
                  <div>All messages ({allMessages?.length})</div>
                </Tab>
                <Tab>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <DotMark className="evaluation-page__incorrect-dotmark" />
                    <div>Incorrect ({incorrectMessages?.length})</div>
                  </Stack>
                </Tab>
                <Tab>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <DotMark className="evaluation-page__correct-dotmark" />
                    <div>Correct ({correctMessages?.length})</div>
                  </Stack>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel className="tab-panel">
                  {allMessages ? (
                    <CodeSnippet className="glass glass_border" type="multi" feedback="Copied to clipboard">
                      {`${JSON.stringify(allMessages, null, 2)}`}
                    </CodeSnippet>
                  ) : (
                    <CodeSnippetSkeleton type="multi" />
                  )}
                </TabPanel>

                <TabPanel className="tab-panel">
                  {incorrectMessages ? (
                    <CodeSnippet type="multi" feedback="Copied to clipboard">
                      {`${JSON.stringify(incorrectMessages, null, 2)}`}
                    </CodeSnippet>
                  ) : (
                    <CodeSnippetSkeleton type="multi" />
                  )}
                </TabPanel>

                <TabPanel className="tab-panel">
                  {correctMessages ? (
                    <CodeSnippet type="multi" feedback="Copied to clipboard">
                      {`${JSON.stringify(correctMessages, null, 2)}`}
                    </CodeSnippet>
                  ) : (
                    <CodeSnippetSkeleton type="multi" />
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Stack>

        {error && (
          <InlineNotification
            kind="error"
            title={'Error'}
            subtitle={error}
            lowContrast
            style={{ position: 'absolute', bottom: 8, left: 8 }}
          />
        )}
      </Box>
    </>
  );
};

export default EvaluationPage;
