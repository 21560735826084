import { Copy, Information, Plug } from '@carbon/icons-react';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { integrationsInfo } from '../../utils/integrationsInfo';
import SettingsNode from '../IntegrationsPlayground/SettingsNode/SettingsNode';

const Sidebar = ({ integrations }) => {
  const onDragStart = (event, nodeType, typeId) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.setData('typeId', typeId);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <aside style={{ overflow: 'auto' }}>
      <Typography mt={2} mb={3} variant="body2">
        Drag integrations to the pane{' '}
      </Typography>

      {integrations
        ?.sort((a, b) => b.name.localeCompare(a.name))
        .map((integration, index) => (
          <Box
            className={`dndnode ${!integrationsInfo[integration.id].disabled ? 'grab' : 'not-allowed'}`}
            component={motion.div}
            key={index}
            initial="hidden"
            animate="show"
            exit="exit"
            custom={index}
            onDragStart={(event) => onDragStart(event, `modelNode`, integration.id)}
            draggable={!integrationsInfo[integration.id].disabled}
            sx={{ opacity: !integrationsInfo[integration.id].disabled ? 1 : 0.6 }}
          >
            <SettingsNode integration={integration} />
          </Box>
        ))}

      <Box mt={4} color="text.secondary" display="flex" alignItems={'center'}>
        <Copy size={20} />
        <Typography ml={1} variant="body2">
          Multiple integrations of each type can be added.
        </Typography>
      </Box>

      <Box mt={4} color="text.secondary" display="flex" alignItems={'center'}>
        <Plug size={20} />
        <Typography ml={1} variant="body2">
          Every integration needs to be connected.
        </Typography>
      </Box>

      <Box mt={4} color="text.secondary" display="flex" alignItems={'center'}>
        <Information size={20} />
        <Typography ml={1} variant="body2">
          To remove an integration, select it and press the '⌫' button.
        </Typography>
      </Box>
    </aside>
  );
};

export default Sidebar;
