import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingPage from '../components/LoadingPage';
import { ProjectContext } from '../contexts/ProjectContext';

export default function ChatPageRedirect() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { projects } = useContext(ProjectContext);

  useEffect(() => {
    if (projects) {
      if (projects.length) navigate(`/chat/${projects[0].id}`);
      else navigate('/projects');
    }
  }, [projects]);

  return (
    <>
      <LoadingPage />
    </>
  );
}
