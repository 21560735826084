import { Connect, Edit } from '@carbon/icons-react';
import { Button, Tag, Tile } from '@carbon/react';
import { Box, Divider, Paper, Popper, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useProjectApi } from '../../hooks/api/useProjectsApi';
import './settings-card.scss';

const SettingsCard = ({
  color,
  projectId,
  integration,
  icon: Icon,
  title,
  description,
  mandatary,
  onClick,
  onDelete,
  togglePipeline,
  onConnectToggle,
  isEnabled,
}) => {
  const { removeIntegrationDetails } = useProjectApi();

  const [switchEnabled, setSwitchEnabled] = useState(Boolean(integration?.projectIntegration?.pipelineStep));

  // const [isConnected, setIsConnected] = useState(integration?.projectIntegration);

  const [highlight, setHighlight] = useState(false);
  const anchorEl = useRef(null);

  const handleSwitchChange = (checked) => {
    if (highlight) setHighlight(false);

    if (integration?.projectIntegration) {
      setSwitchEnabled(checked);
      togglePipeline(checked);
    }
  };

  const handleDeleteIntegration = (integrationId) => {
    removeIntegrationDetails(projectId, integrationId).then((res) => {
      if (!res.error) {
        onConnectToggle(false);
      }
    });
  };

  const prevIntegrationRef = useRef();

  useEffect(() => {
    if (
      prevIntegrationRef.current &&
      !prevIntegrationRef.current.projectIntegration &&
      integration?.projectIntegration
    ) {
      setHighlight(true);
    } else {
      setHighlight(false);
    }

    // Actualizamos la referencia con el valor actual
    prevIntegrationRef.current = integration;
  }, [integration]);

  return (
    <>
      <Tile className="settings-tile" style={{ height: 200, width: 250, opacity: switchEnabled ? 1 : 0.9 }}>
        <Stack direction="row" sx={{}} alignItems={'flex-start'} justifyContent={'space-between'}>
          <Box
            sx={{
              width: 40,
              height: 40,
              backgroundColor: color,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 1,
              p: 1,
              mb: 2,
            }}
          >
            {Icon && <Icon size={22} color="white" style={{ opacity: switchEnabled ? 1 : 0.5 }} />}
          </Box>
          {isEnabled ? (
            <Tag className="some-class" type="green" title="Clear Filter">
              Connected
            </Tag>
          ) : (
            <Tag className="some-class" type="red" title="Clear Filter">
              Pending
            </Tag>
          )}
        </Stack>

        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            color={!switchEnabled ? 'text.secondary' : 'text.primary'}
          >
            {title}
          </Typography>
          {/* <Typography variant="body2" color="text.secondary" mb={2}>
            {description}
          </Typography> */}
        </Box>
        <Divider sx={{ my: 2 }} />
        <Stack direction="row" alignItems="center" justifyContent={'space-between'} spacing={2}>
          <Stack direction="row" alignItems="center" spacing={1}>
            {!isEnabled && (
              <>
                <Button
                  size="sm"
                  variant="outlined"
                  renderIcon={Connect}
                  color="primary"
                  onClick={() => {
                    onClick();
                  }}
                  // disabled={integration.id === 'integration.wd'}
                >
                  Setup Connection
                </Button>
              </>
            )}
            {isEnabled && (
              <>
                <Button
                  size="sm"
                  variant="outlined"
                  renderIcon={Edit}
                  color="primary"
                  onClick={onClick}
                  kind="tertiary"
                  // disabled={integration.id === 'integration.wd'}
                >
                  Edit
                </Button>

                {/* <Button label="Edit" hasIconOnly renderIcon={Edit} size="md" onClick={onClick} /> */}
                {/* <Button
                  label="Clear values"
                  kind="danger--ghost"
                  hasIconOnly
                  renderIcon={TrashCan}
                  size="md"
                  onClick={handleDeleteIntegration}
                /> */}
              </>
            )}
          </Stack>

          {!mandatary && (
            <>
              <div ref={anchorEl}>
                {/* <Toggle
                  className={highlight && 'glow-button'}
                  disabled={!integration?.projectIntegration}
                  toggled={switchEnabled}
                  onToggle={handleSwitchChange}
                  labelA=""
                  labelB=""
                /> */}
              </div>
              <Popper
                id="mouse-over-popover"
                open={highlight}
                anchorEl={anchorEl.current}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                disableRestoreFocus
                modifiers={[
                  {
                    name: 'flip',
                    enabled: true,
                    options: {
                      altBoundary: true,
                      rootBoundary: 'document',
                      padding: 8,
                    },
                  },
                  {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                      altAxis: true,
                      altBoundary: true,
                      tether: true,
                      rootBoundary: 'document',
                      padding: 8,
                    },
                  },
                ]}
              >
                <Paper>
                  <Box p={2}>
                    <Typography>Click to enable integration!</Typography>
                  </Box>
                </Paper>
              </Popper>
            </>
          )}
        </Stack>
      </Tile>
    </>
  );
};

export default SettingsCard;
