export const getStatusTag = (status) => {
  switch (status) {
    case 'finished':
      return 'Connected';
    case 'error':
      return 'Connection failed';
    default:
      return 'Loading...';
  }
};
