import { pickerColors } from '../../../utils/colors';

const { Box, Typography, Card, CardMedia } = require('@mui/material');
const { TwitterPicker } = require('react-color');

const pickerStyles = {
  default: {
    card: {
      boxShadow: 'none',
      border: 'none',
      borderRadius: '0',
    },
    swatch: {
      // borderRadius: '50%',
    },
  },
};

const StepColor = ({ selectedColor, handleColorClick }) => (
  <Box
    py={2}
    px={4}
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Card sx={{ width: 150 }} elevation={5}>
      <Box sx={{ width: '100%', height: 100, backgroundColor: selectedColor }} />
      <Box display="flex" justifyContent="center">
        <Typography
          align="center"
          variant="overline"
          sx={{
            padding: 2,
            borderRadius: 2,
          }}
        >
          {selectedColor}
        </Typography>
      </Box>
    </Card>

    <Box mt={5}>
      <TwitterPicker
        colors={pickerColors}
        triangle="hide"
        styles={pickerStyles}
        color={selectedColor}
        onChange={handleColorClick}
      />
    </Box>
  </Box>
);

export default StepColor;
