import { Box, List, Skeleton, Typography } from '@mui/material';
import { format, isThisMonth, isToday, isYesterday } from 'date-fns'; // Import date-fns for date manipulation
import { motion } from 'framer-motion';
import React from 'react';
import ChatListItem from './ChatListItem';

const ChatList = ({ chats, selectedItemId, handleItemClick, handleDelete, loading, color }) => {
  const sortByDateDescending = (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt);

  const listItemVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
  };

  const sortedChats = [...(chats || [])].sort(sortByDateDescending);

  const getSubcategoryTitle = (date) => {
    if (isToday(new Date(date))) {
      return 'Today';
    }
    if (isYesterday(new Date(date))) {
      return 'Yesterday';
    }
    if (isThisMonth(new Date(date))) {
      return format(new Date(date), 'MMMM');
    }
    return format(new Date(date), 'MMMM yyyy');
  };

  let lastSubcategory = '';

  return (
    <List sx={{ width: '100%' }}>
      {loading ? (
        <Skeleton variant="rounded" width={'100%'} height={32} />
      ) : (
        sortedChats.map((chat, index) => {
          const currentSubcategory = chat.updatedAt && getSubcategoryTitle(chat.updatedAt);
          const isNewSubcategory = lastSubcategory !== currentSubcategory;
          lastSubcategory = currentSubcategory;
          return (
            <React.Fragment key={`chat-list-${chat.id}`}>
              {isNewSubcategory && (
                <Box my={0.5}>
                  <Typography variant="caption" color="text.secondary" sx={{ fontWeight: 600 }}>
                    {currentSubcategory}
                  </Typography>
                </Box>
              )}
              <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={listItemVariants}
                transition={{ duration: 0.4, delay: index * 0.1 }}
              >
                <ChatListItem
                  title={chat.name}
                  updatedAt={chat.updatedAt}
                  isSelected={chat.id === selectedItemId}
                  onClick={() => handleItemClick(chat.id)}
                  onDelete={() => handleDelete(chat.id)}
                  autoCreated={chat.autoCreated}
                  color={color}
                />
              </motion.div>
            </React.Fragment>
          );
        })
      )}
    </List>
  );
};

export default ChatList;
