import { Box } from '@mui/material';
import { useState } from 'react';
import { SketchPicker } from 'react-color';

const ColorPicker = ({ color, onColorChange }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const styles = {
    color: {
      width: '100%',
      height: '25px',
      borderRadius: '12px',
      background: color,
    },
    swatch: {
      padding: '5px',
      background: '#fefefe',
      borderRadius: '20px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
      width: '100%',
    },
    popover: {
      position: 'absolute',
      zIndex: '2',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  };

  return (
    <div>
      <Box style={styles.swatch} onClick={handleClick} onKeyDown={handleClick}>
        <div style={styles.color} />
      </Box>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <Box style={styles.cover} onClick={handleClose} />
          <SketchPicker color={color} onChange={onColorChange} />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
