import { Chat, Db2Database, License, MessageQueue, TextAlignCenter } from '@carbon/icons-react';
import { grey } from '@mui/material/colors';

export const onlyQuestionItems = [{ id: 1, type: 'question' }];

export const typesInfo = {
  textfield: {
    icon: TextAlignCenter,
    color: grey[700],
  },
  information: {
    label: 'Discovery Data',
    icon: Db2Database,
    color: '#7c4dff',
  },
  question: {
    label: 'User message',
    icon: Chat,
    color: '#0F62FE',
  },
  instruction: {
    label: 'Instruction',
    icon: License,
    color: '#f44336',
  },
  context: {
    label: 'Chat History',
    icon: MessageQueue,
    color: '#00796b',
  },
};
