import { Idea, Save, TextIndent, WatsonHealth3DCurveManual } from '@carbon/icons-react';
import { Accordion, AccordionItem, Button, InlineNotification, Stack, TextInput, Tile, Toggle } from '@carbon/react';
import { Box, Grid, Stack as MUIStack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { ProjectContext } from '../../contexts/ProjectContext';
import { useProjectApi } from '../../hooks/api/useProjectsApi';
import ChatDrawer from '../ChatDrawer';
import ColorPicker from '../ColorPicker';
import InputList from '../InputList/InputList';
import SuggestionsList from '../SuggestionsList/SuggestionsList';
import Chat from '../chat/Chat';
import './chat-customization.scss';

const GLOBAL_INITIAL_STATES = {
  mainColor: '#0f62fe',
  secondaryColor: '#3F3F3F',
  logoURL: '',
  showPoweredBy: true,
  suggestions: [],
  inputForms: [],
};

const SAMPLE_CHAT = [
  {
    name: 'Generate a 300 word email',
    type: 'default',
    createdAt: new Date().getTime,
    updatedAt: new Date().getTime(),
  },
];

const useConfigState = (initialState, key) => {
  const initial = initialState[key] || GLOBAL_INITIAL_STATES[key];

  const [value, setValue] = useState(initial);
  const [isChanged, setIsChanged] = useState(false);

  const handleChange = (newValue) => {
    setValue(newValue);

    setIsChanged(newValue !== initial);
  };

  return [value, handleChange, isChanged];
};

const HEADER_HEIGHT = 80;

const ChatCustomization = ({ project }) => {
  const initialChatConfig = project?.chatConfig || {};

  const [mainColor, setMainColor, isMainColorChanged] = useConfigState(initialChatConfig, 'mainColor');
  const [secondaryColor, setSecondaryColor, isSecondaryColorChanged] = useConfigState(
    initialChatConfig,
    'secondaryColor'
  );
  const [logoURL, setLogoURL, isLogoURLChanged] = useConfigState(initialChatConfig, 'logoURL');
  const [isJSONAnswer, setIsJSONAnswer, isJSONAnswerChanged] = useConfigState(initialChatConfig, 'isJSONAnswer');
  const [showPoweredBy, setShowPoweredBy, isShowPoweredByChanged] = useConfigState(initialChatConfig, 'showPoweredBy');
  const [suggestions, setSuggestions, isSuggestionsChanged] = useConfigState(initialChatConfig, 'suggestions');
  const [inputForms, setInputForms, isInputFormsChanged] = useConfigState(initialChatConfig, 'inputForms');

  const hasChanged =
    isMainColorChanged ||
    isSecondaryColorChanged ||
    isLogoURLChanged ||
    isShowPoweredByChanged ||
    isSuggestionsChanged ||
    isInputFormsChanged ||
    isJSONAnswerChanged;

  const [saveStatus, setSaveStatus] = useState('');

  const { updateProject } = useProjectApi();
  const { fetchProjects } = useContext(ProjectContext);

  const handleSaveChanges = async () => {
    const data = {
      mainColor,
      secondaryColor,
      logoURL,
      showPoweredBy,
      suggestions,
      inputForms,
      isJSONAnswer,
    };

    try {
      await updateProject(project.id, data);
      await fetchProjects();
      setSaveStatus('success');
    } catch (error) {
      setSaveStatus(error.message);
    }
  };

  return (
    <>
      <Grid container spacing={3} sx={{ height: '100%' }}>
        <Grid item xs={12} sm={4}>
          <div className="customization-header">
            <h4>Customization</h4>
          </div>
          <Stack gap={6} className="controls-container">
            <Stack gap={2} orientation="horizontal">
              <Tile className="customization-tile">
                <h6 className="customization-title">Main color</h6>
                <ColorPicker
                  color={mainColor}
                  onColorChange={(color) => {
                    setMainColor(color.hex);
                  }}
                />
              </Tile>
              <Tile className="customization-tile">
                <h6 className="customization-title">Secondary color</h6>
                <ColorPicker
                  color={secondaryColor}
                  onColorChange={(color) => {
                    setSecondaryColor(color.hex);
                  }}
                />
              </Tile>
            </Stack>

            <Stack gap={2} orientation="horizontal">
              <Tile className="customization-tile">
                <h6 className="customization-title">Logo URL</h6>
                <TextInput id="logoURL" value={logoURL} onChange={(e) => setLogoURL(e.target.value)} />
              </Tile>
              <Tile className="customization-tile">
                <h6 className="customization-title">Powered by tag</h6>
                <Toggle
                  id="myToggle"
                  labelA="Off"
                  labelB="On"
                  toggled={showPoweredBy}
                  onToggle={() => setShowPoweredBy(!showPoweredBy)}
                  defaultToggled
                />{' '}
              </Tile>
            </Stack>
            <Tile className="customization-tile">
              <Accordion className="custom-accordion">
                <AccordionItem
                  title={
                    <span className="accordion-title">
                      <Idea />
                      Initial chat suggestions
                    </span>
                  }
                >
                  <SuggestionsList suggestions={suggestions} setSuggestions={setSuggestions} />
                </AccordionItem>
              </Accordion>
            </Tile>
            <Tile className="customization-tile">
              <Accordion className="custom-accordion">
                <AccordionItem
                  title={
                    <span className="accordion-title">
                      <WatsonHealth3DCurveManual />
                      Custom Entry
                    </span>
                  }
                >
                  {inputForms && <InputList inputFormList={inputForms} setInputForms={setInputForms} />}
                </AccordionItem>
              </Accordion>
            </Tile>
            <Tile className="customization-tile">
              <Accordion className="custom-accordion">
                <AccordionItem
                  title={
                    <span className="accordion-title">
                      <TextIndent />
                      Structured answer
                    </span>
                  }
                >
                  <MUIStack direction="row" spacing={4} mt={1}>
                    <Typography variant="caption">
                      Recommended for transactional answers. Enable this option if your model answers in JSON format.
                      Use answer as field for the model answer (optional) and actions for the model recommended actions.
                    </Typography>
                    <Toggle labelA="Is JSON" labelB="Is JSON" toggled onToggle={() => setIsJSONAnswer(!isJSONAnswer)} />
                  </MUIStack>

                  {/* {inputForms && <InputList inputFormList={inputForms} setInputForms={setInputForms} />} */}
                </AccordionItem>
              </Accordion>
            </Tile>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={8} sx={{ height: '100%' }}>
          <div className="preview-container">
            <div className="preview-header">
              <Button size="md" renderIcon={Save} onClick={handleSaveChanges} disabled={!hasChanged}>
                Save changes
              </Button>
            </div>
            <Box className="chatContainer" sx={{ height: '100%' }}>
              <Box className="preview-chat-container">
                <ChatDrawer color={mainColor} logoURL={logoURL} chats={SAMPLE_CHAT} />
                <Box className="preview-chat-inner-container">
                  <Chat
                    isNewChat
                    projectName={project?.name}
                    color={mainColor}
                    secondaryColor={secondaryColor}
                    headerHeight={HEADER_HEIGHT}
                    suggestions={suggestions}
                    logoURL={logoURL}
                    showPoweredBy={showPoweredBy}
                    customInputFields={inputForms}
                    forceExtend={Boolean(inputForms?.length)}
                  />
                </Box>
              </Box>
            </Box>
          </div>
        </Grid>
      </Grid>

      {saveStatus === 'success' && (
        <InlineNotification
          style={{ position: 'absolute', bottom: 8, right: 8 }}
          kind="success"
          title="Success"
          subtitle="Your changes have been saved."
          role="alert"
        />
      )}
      {saveStatus === 'error' && (
        <InlineNotification
          kind="error"
          title="Error"
          subtitle={`An error occurred while saving your changes: ${saveStatus}`}
          role="alert"
        />
      )}
    </>
  );
};

export default ChatCustomization;
