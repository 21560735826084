import { onlyQuestionItems } from '../../utils/promptBuilerUtils';

export const getInitFormValues = (configValues, integrationConfig) => {
  const res = configValues?.reduce((accum, item) => {
    if (item.type === 'subobject') {
      accum[item.id] = item.fields.reduce((subAccum, subItem) => {
        subAccum[subItem.id] = generateInitialValues(subItem, integrationConfig[item.id]?.[subItem.id]);

        return subAccum;
      }, {});
    } else if (item.type === 'subgroup') {
      item.fields.forEach((subItem) => {
        accum[subItem.id] = generateInitialValues(subItem);
      });
    } else {
      accum[item.id] = generateInitialValues(item, integrationConfig && integrationConfig[item.id]);
    }

    return accum;
  }, {});

  return res;
};

const generateInitialValues = (item, value) => {
  if (value !== null && value !== undefined) return value;
  if (item.type === 'text') return item.default || '';
  if (item.type === 'select') return item.values ? item.values[0]?.id : [];
  if (item.type === 'selectable-tiles') return item.values ? item.values[0]?.id : [];
  if (item.type === 'prompt-builder') return onlyQuestionItems;
  if (item.type === 'toggle') return item.default || false;
  if (['slidebar', 'int', 'number'].includes(item.type)) return item.default || item.min;
  return '';
};
