import { Box, CircularProgress, styled, Typography } from '@mui/material';
import { Fragment, useEffect, useRef, useState } from 'react';
import Message from './Message/Message';
import UserMessage from './UserMessage';

const StyledBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  paddingBottom: '1rem',
  paddingTop: '24px !important',
  position: 'relative',

  // Estilos para ocultar el scrollbar
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none',
  scrollbarWidth: 'none',
}));

const MessageList = ({
  messages,
  chatId,
  mainColor,
  generatingAnswer,
  handleCompleteGenerating,
  handlePauseGenerating,
  isJSONAnswer,
}) => {
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [openFeedback, setOpenFeedback] = useState(false);

  const messagesEndRef = useRef(null);

  const handleCloseFeedback = () => {
    setOpenFeedback(false);
    setSelectedMessage(null);
  };

  const scrollToBottom = () => {
    // debugger;
    // console.log('messagesEndRef.current;', messagesEndRef.current);
    // // messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    // messagesEndRef.current?.scrollIntoViewIfNeeded({ behavior: 'smooth' });

    const element = messagesEndRef.current?.parentNode;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <StyledBox id="test">
      {messages?.length > 0 ? (
        messages.map((message, index) => (
          <Fragment key={`message-${index}`}>
            <UserMessage content={message.request} mainColor={mainColor} />

            <Message
              content={message}
              chatId={chatId}
              messageId={message.id}
              isNew={message.isNew}
              mainColor={mainColor}
              generatingAnswer={generatingAnswer}
              handlePauseGenerating={handlePauseGenerating}
              handleCompleteGenerating={handleCompleteGenerating}
              setOpenFeedback={setOpenFeedback}
              setSelectedMessage={setSelectedMessage}
              isJSONAnswer={isJSONAnswer}
            />
          </Fragment>
        ))
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" flexGrow={1}>
          <CircularProgress />
          <Typography variant="caption" color="text.secondary" mt={2}>
            Loading chat...
          </Typography>
        </Box>
      )}
      <div ref={messagesEndRef} style={{ position: 'relative', backgroundColor: 'red' }} />

      {/* <FeedbackDialog message={selectedMessage} open={openFeedback} handleClose={handleCloseFeedback} /> */}
    </StyledBox>
  );
};

export default MessageList;
