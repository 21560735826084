import { Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

const TYPING_SPEED_BASE = 10;
const TYPING_SPEED_VARIABILITY = 5;

const AnimatedTypography = ({ textElements, onAnimationEnd }) => {
  const [completedLines, setCompletedLines] = useState([]);
  const [currentLine, setCurrentLine] = useState('');
  const [currentTextIdx, setCurrentTextIdx] = useState(0);
  const [charIdx, setCharIdx] = useState(0);

  useEffect(() => {
    let isMounted = true;

    const typeNextChar = () => {
      if (!isMounted) return;
      const currentText = textElements[currentTextIdx];

      if (charIdx < currentText.length) {
        setCurrentLine((prevLine) => prevLine + currentText[charIdx]);
        const randomDuration = Math.random() * TYPING_SPEED_VARIABILITY + TYPING_SPEED_BASE;
        setTimeout(() => {
          setCharIdx((prevCharIdx) => prevCharIdx + 1);
        }, randomDuration);
      } else {
        setCompletedLines((prevLines) => [...prevLines, currentText]);
        setCurrentLine('');
        if (currentTextIdx < textElements.length - 1) {
          setCurrentTextIdx((prevIdx) => prevIdx + 1);
          setCharIdx(0);
        } else if (onAnimationEnd) {
          onAnimationEnd();
        }
      }
    };

    if (!textElements.length) {
      if (onAnimationEnd) onAnimationEnd();
    } else typeNextChar();

    return () => {
      isMounted = false;
    };
  }, [charIdx, currentTextIdx]);

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Stack spacing={1}>
        {completedLines.map((line, index) => (
          <Typography color="text.primary" key={index}>
            {line}
          </Typography>
        ))}
        {currentLine && <Typography color="text.primary">{currentLine}</Typography>}
      </Stack>
    </motion.div>
  );
};

export default AnimatedTypography;
