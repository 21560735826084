import { init } from 'ityped';
import { useEffect, useRef } from 'react';

const TypingEffect = ({ text, fast = false }) => {
  const textRef = useRef();

  useEffect(() => {
    init(textRef.current, {
      strings: [text],
      typeSpeed: fast ? 100 : 50,
      backSpeed: 10,
      startDelay: fast ? 0 : 500,
      showCursor: false,
      cursorChar: '|',
      loop: false,
    });
  }, [text]);

  return <span ref={textRef} />;
};

export default TypingEffect;
