import { SettingsView } from '@carbon/icons-react';
import { Button } from '@carbon/react';
import { Box, Divider, Drawer, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ChatList from './chatlist/ChatList';
import ChatListHeader from './chatlist/ChatListHeader';
import ChatSearchBar from './chatlist/ChatSearchBar';

const Content = ({ projectId, color, chats, addNewChat, selectedItemId, handleItemClick, deleteChat, loading }) => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');

  const filteredChats = chats?.filter((chat) => {
    const name = chat.name?.toLowerCase();
    return name ? name.includes(searchValue.toLowerCase()) : true;
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <ChatListHeader color={color} numChats={chats?.length} onAddButtonClick={addNewChat} />
      <ChatSearchBar searchValue={searchValue} setSearchValue={setSearchValue} sx={{ mb: 1 }} />
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          scrollbarWidth: 'none',
        }}
      >
        <ChatList
          color={color}
          chats={filteredChats}
          loading={loading}
          selectedItemId={selectedItemId}
          handleItemClick={handleItemClick}
          handleDelete={deleteChat}
        />
      </Box>

      {projectId && (
        <>
          <Divider my={1} />
          <Box mb={2} mt={1}>
            <Button
              kind="ghost"
              renderIcon={(props) => <SettingsView size={24} {...props} />}
              iconDescription="Project settings"
              onClick={() => navigate(`/projects/${projectId}`)}
            >
              Go to project settings
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default function ChatDrawer({
  color,
  projectId,
  openChatList,
  handleClose,
  addNewChat,
  selectedItemId,
  handleItemClick,
  deleteChat,
  loading,
  chats,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return matches ? (
    <Box
      sx={{
        px: 2,
        pt: 3,
        minWidth: 230,
        maxWidth: 230,
        maxHeight: '100vh',
        // backdropFilter: 'blur(40px)',
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)), rgba(255, 255, 255, 0.1)`,
        borderRadius: '10px 0px 0px 10px',
      }}
    >
      <Content
        projectId={projectId}
        color={color}
        chats={chats}
        addNewChat={addNewChat}
        selectedItemId={selectedItemId}
        handleItemClick={handleItemClick}
        deleteChat={deleteChat}
        loading={loading}
      />
    </Box>
  ) : (
    <Drawer
      open={openChatList}
      onClose={handleClose}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: { width: 300, bgcolor: 'background.light' },
      }}
    >
      <Box sx={{ mx: 2, mt: 3 }}>
        <Content
          color={color}
          chats={chats}
          addNewChat={addNewChat}
          selectedItemId={selectedItemId}
          handleItemClick={handleItemClick}
          deleteChat={deleteChat}
          loading={loading}
        />
      </Box>
    </Drawer>
  );
}
