import { AddFilled, Unknown } from '@carbon/icons-react';
import { Backdrop, Box, IconButton, Paper, Stack, Typography, alpha } from '@mui/material';
import { grey } from '@mui/material/colors';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { typesInfo } from '../../utils/promptBuilerUtils';

const CircleMenuItem = ({ sx, icon, color, onClick, label, disabled, ...props }) => {
  const itemVariants = {
    rest: { opacity: 0 },
    hover: { opacity: 1 },
  };

  const Icon = icon || <Unknown />;

  return (
    <Stack
      component={motion.div}
      whileHover={{ scale: !disabled && 1.1 }}
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{ cursor: disabled ? 'not-allowed' : 'pointer', opacity: disabled ? 0.5 : 1 }}
      onClick={onClick}
    >
      <Paper
        component={motion.li}
        variants={itemVariants}
        elevation={8}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 30,
          height: 30,
          backgroundColor: color || 'white',
          borderRadius: '50%',
          backdropFilter: 'blur(6px)',
          ...sx,
        }}
        {...props}
      >
        {Icon}
      </Paper>
      <Typography noWrap variant="body2">
        {label}
      </Typography>
    </Stack>
  );
};

const CircularMenu = ({ options, handleClick, setIsMenuExpanded }) => {
  const menuVariants = {
    rest: { opacity: 0, display: 'none' },
    hover: {
      display: 'block',
      opacity: 1,
      transition: {
        type: 'spring',
        delayChildren: 0.1,
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <Stack
      spacing={1.5}
      component={motion.ul}
      variants={menuVariants}
      sx={{
        position: 'absolute',
        bottom: -17,
        left: 26,
        zIndex: 99999,
        // backdropFilter: 'blur(5px)',
        borderRadius: 4,
        p: 2,
      }}
    >
      {options
        ?.filter((option) => !option.disabled)
        .map((option) => {
          const Icon = typesInfo[option.type]?.icon;
          return (
            <CircleMenuItem
              key={option.id}
              label={option.label}
              disabled={option.disabled}
              color={typesInfo[option.type]?.color || grey[200]}
              onClick={() => {
                if (!option.disabled) {
                  setIsMenuExpanded(false);
                  handleClick(option.type);
                }
              }}
              icon={Icon && <Icon sx={{ fontSize: 8 }} color="white" />}
              tooltip={option.label}
            />
          );
        })}
    </Stack>
  );
};

const CustomOverflowButton = ({ options, handleClick, hide }) => {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  const pressVariants = {
    rest: { scale: 1, right: 0 },
    hover: {
      scale: 0.85,
    },
  };

  const handleButtonClick = () => {
    setIsMenuExpanded((prevIsMenuExpanded) => !prevIsMenuExpanded);
  };

  const handleClose = () => {
    setIsMenuExpanded(false);
  };

  return (
    <Box
      component={motion.div}
      sx={{ position: 'relative', userSelect: 'none' }}
      initial="rest"
      whileHover={isMenuExpanded ? 'hover' : 'rest'}
      animate={isMenuExpanded ? 'hover' : 'rest'}
    >
      <Backdrop
        sx={{ backgroundColor: alpha('#000', 0.3), zIndex: (theme) => theme.zIndex.drawer + 1 }}
        onClick={handleClose}
        open={isMenuExpanded}
      />
      <CircularMenu handleClick={handleClick} options={options} setIsMenuExpanded={setIsMenuExpanded} />

      <Box component={motion.div} animate={{ rotate: isMenuExpanded ? 45 : 0 }}>
        <IconButton onClick={handleButtonClick}>
          <AddFilled />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CustomOverflowButton;
