import { TextInput } from '@carbon/react';

const { Box, TextField } = require('@mui/material');

const StepName = ({ projectName, handleTextFieldChange, nextStep }) => {
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      nextStep();
    }
  };

  return (
    <Box
      py={2}
      px={4}
      sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
    >
      {/* <TextField
        value={projectName}
        onChange={handleTextFieldChange}
        autoFocus
        onKeyPress={handleKeyPress}
        sx={{ borderRadius: 0 }}
      /> */}

      <Box>
        <TextInput
          id="projectName"
          value={projectName}
          onChange={handleTextFieldChange}
          autoFocus
          onKeyPress={handleKeyPress}
          labelText="Project Name" // example label text, you can change this according to your needs
        />
      </Box>
    </Box>
  );
};

export default StepName;
