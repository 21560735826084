import { Avatar, Box, Divider, IconButton, Tooltip, Typography, alpha, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProjectContext } from '../../../contexts/ProjectContext';
import AccountPopover from '../header/AccountPopover';
import navConfig from './config';
import './nav.scss';

const Nav = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { projects } = useContext(ProjectContext);

  const theme = useTheme();

  const bubbleAnimation = {
    hidden: { scale: 0, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 260,
        damping: 20,
      },
    },
    exit: { scale: 0, opacity: 0 },
  };

  return (
    <Box id="sidenav" className="sidenav_container">
      <AccountPopover />

      {projects && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Divider sx={{ width: '42px' }} />

          <Box
            sx={{
              height: 230,
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: '100%',
              alignItems: 'center',
            }}
            py={1.5}
          >
            {projects.map((project) => {
              const itemPath = `/chat/${project.id}`;
              const isActive = pathname === itemPath;

              const mainColor = project.chatConfig?.mainColor || project.color;
              const secondaryColor = project.chatConfig?.secondaryColor || project.color;

              return (
                <Tooltip key={`it-${project.id}`} title={project.name} arrow placement="right">
                  <motion.div initial="hidden" animate="visible" exit="exit" variants={bubbleAnimation}>
                    <IconButton
                      size="large"
                      className="advanced_glass"
                      onClick={() => navigate(itemPath)}
                      sx={{
                        backgroundColor: isActive ? mainColor : alpha('#fff', 0.1),
                        // outline: isActive && '2px solid white',
                        borderRadius: (theme) => theme.spacing(2, 2, 2, 0.5),
                        width: 44,
                        height: 37,

                        filter: 'drop-shadow(0px 18px 23px rgba(0, 0, 0, 0.05))',
                        '&:hover': {
                          backgroundColor: mainColor,
                        },
                      }}
                    >
                      <Typography
                        variant="overline"
                        sx={{
                          color: (theme) => (isActive ? theme.palette.getContrastText(mainColor) : 'white'),
                          fontWeight: isActive ? 900 : 500,
                          '&:hover': {
                            color: (theme) => theme.palette.getContrastText(mainColor),
                          },
                        }}
                      >
                        {project.name.slice(0, 3).toUpperCase()}
                      </Typography>
                      {/* <Watson size={18} color={theme.palette.getContrastText(mainColor)} /> */}
                    </IconButton>
                  </motion.div>
                </Tooltip>
              );
            })}
          </Box>

          <Divider sx={{ width: '42px' }} />
        </Box>
      )}

      {navConfig.map((item, i) => {
        const Icon = item.icon;

        const isActive = pathname.startsWith(item.path);
        return (
          <Avatar
            key={`it-${i}`}
            sx={{ color: 'white', background: (theme) => (!isActive ? 'transparent' : alpha('#fff', 0.3)) }}
          >
            <IconButton size="large" onClick={() => navigate(item.path)} sx={{ color: 'white' }}>
              <Icon size={24} />
            </IconButton>
          </Avatar>
        );
      })}
    </Box>
  );
};

export default Nav;
