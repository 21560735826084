import { Send } from '@carbon/icons-react';
import { alpha, useTheme } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';

const DEFAULT_COLOR = '#454545';

const SendButton = ({ onClick, color, disabled }) => {
  const theme = useTheme();

  const [isSent, setIsSent] = useState(false);

  const mColor = color || DEFAULT_COLOR;

  const buttonVariants = {
    hover: {
      backgroundColor: '#3a3a3a',
      transition: {
        duration: 0.3,
      },
    },
    tap: {
      transition: {
        duration: 0.2,
      },
    },
  };

  const airplaneVariants = {
    initial: { opacity: 1, x: 0, y: 0, rotate: 0 },
    animate: { opacity: 0, x: 50, y: -50, rotate: -45, transition: { duration: 0.5 } },
    return: { opacity: 0, x: -50, y: 50, rotate: 45, transition: { duration: 0.5 } },
  };

  useEffect(() => {
    if (isSent) {
      setTimeout(() => {
        setIsSent(false);
      }, 1000);
    }
  }, [isSent]);

  const handleSendMessage = async (e) => {
    if (disabled) return;
    e.preventDefault();
    setIsSent(true);
    await onClick();
  };

  return (
    <motion.div
      variants={buttonVariants}
      whileHover={!disabled && 'hover'}
      style={{
        background: disabled ? alpha(mColor, 0.3) : mColor,
        boxShadow: 'inset 0px 1px 6px rgba(255, 255, 255, 0.35), inset -1px 1px 1px -2px rgba(255, 255, 255, 0.35)',
        filter: 'drop-shadow(0px 18px 23px rgba(0, 0, 0, 0.05))',
        '&:hover': {
          backgroundColor: (theme) => color || theme.palette.primary.main,
        },
        borderRadius: 50,
        minWidth: '40px',
        minHeight: '40px',
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        cursor: !disabled && 'pointer',
        color: '#fff',
        outline: 'none',
        position: 'relative',
      }}
      onClick={handleSendMessage}
    >
      <AnimatePresence>
        {isSent ? (
          <motion.div
            key="sending"
            initial="initial"
            animate="animate"
            exit="return"
            variants={airplaneVariants}
            style={{ position: 'absolute', marginTop: 5, marginLeft: 2 }}
          >
            <Send size={22} />
          </motion.div>
        ) : (
          <motion.div
            key="idle"
            initial="return"
            animate="initial"
            exit="animate"
            variants={airplaneVariants}
            style={{ marginTop: 5, marginLeft: 2 }}
          >
            <Send size={22} color={disabled ? alpha(mColor, 0.6) : theme.palette.getContrastText(mColor)} />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default SendButton;
