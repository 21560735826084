import { Chat } from '@carbon/icons-react';
import Typography from '@mui/material/Typography';

const UserMessageInput = () => (
  <div className="user-input-node glass" style={{ maxWidth: 300 }}>
    <Chat size={16} color="white" />
    <Typography variant="subtitle1" color="white">
      User message
    </Typography>
  </div>
);

export default UserMessageInput;
