import { Connect } from '@carbon/icons-react';
import { Accordion, AccordionItem, Button, InlineLoading } from '@carbon/react';
import { Grid, Stack } from '@mui/material';
import { getStatusTag } from '../utils/status';
import FormInput from './FormInput/FormInput';

const FieldsList = ({ fields, formValues, formErrors, handleFormChange, handleRetrieveExtraFromField, fieldsStatus }) =>
  fields?.map((conf) => {
    if (conf.type === 'subobject') {
      return (
        <Grid item xs={12} key={`subobj-flist-${conf.id}`}>
          <Accordion>
            <AccordionItem title={conf.name} className="custom-accordion-item">
              <Grid container spacing={4}>
                {conf.fields?.map((subObj) => (
                  <Grid item xs={12} sm={12} md={6} key={`${conf.id}.${subObj.id}`}>
                    <FormInput
                      config={subObj}
                      value={formValues[conf.id]?.[subObj.id]}
                      onChange={(value, subId) => {
                        handleFormChange(value, conf.id, subId);
                      }}
                      error={formErrors[conf.id]?.[subObj.id]}
                    />
                  </Grid>
                ))}
              </Grid>
            </AccordionItem>
          </Accordion>
        </Grid>
      );
    }

    if (conf.type === 'subgroup') {
      return (
        <Grid item xs={12} key={`subgroup-flist-${conf.id}`}>
          <Accordion>
            <AccordionItem title={conf.name} className="custom-accordion-item">
              <Grid container spacing={4}>
                {conf.fields.map((subConf) => (
                  <Grid item xs={12} sm={12} md={6} key={subConf.id}>
                    <FormInput
                      config={subConf}
                      value={formValues[subConf.id]}
                      onChange={(value) => {
                        handleFormChange(value);
                      }}
                      error={formErrors[subConf.id]}
                    />
                  </Grid>
                ))}
              </Grid>
            </AccordionItem>
          </Accordion>
        </Grid>
      );
    }

    return (
      <Grid item xs={12} sm={12} key={`def-flist-${conf.id}`}>
        <Stack direction="row" spacing={1} alignItems="flex-end" justifyContent="space-between" sx={{ width: '100%' }}>
          <FormInput
            config={conf}
            value={formValues[conf.id]}
            onChange={(value) => handleFormChange(value, conf.id)}
            error={formErrors[conf.id]}
          />
          {conf.requiresConnection && conf.buttonLabel && (
            <div className="integration-actions-container">
              {fieldsStatus[conf.id] && (
                <InlineLoading description={getStatusTag(fieldsStatus[conf.id])} status={fieldsStatus[conf.id]} />
              )}
              <Button
                kind="tertiary"
                size="md"
                onClick={() => handleRetrieveExtraFromField(conf.id)}
                renderIcon={Connect}
              >
                {conf.buttonLabel}
              </Button>
            </div>
          )}
        </Stack>
      </Grid>
    );
  });

FieldsList.defaultProps = {
  fields: [],
  formValues: {},
  formErrors: {},
  handleFormChange: () => {},
};

export default FieldsList;
