import { ModelBuilder } from '@carbon/icons-react';
import { Avatar, Box, Divider, IconButton, Stack, Tooltip, Typography, styled, tooltipClasses } from '@mui/material';
import { integrationsInfo } from '../utils/integrationsInfo';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#f4f4f4',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f4f4f4',
    maxWidth: 400,
    color: 'black',
    border: '1px solid #dadde9',
  },
}));

const PathInfoBubble = ({ chain, index }) => (
  <BootstrapTooltip
    title={
      <>
        <Box px={1} pb={2} pt={1} sx={{ overflow: 'auto', maxHeight: 300 }}>
          <Typography variant="subtitle1" gutterBottom>
            Source & Response Insights
          </Typography>
          <Divider />
          <Stack
            // mt={2}
            alignItems="flex-start"
            justifyContent="space-between"
            spacing={2}
            divider={<Divider flexItem />}
          >
            {chain.slice(0, -1).map((chainElement, index) => {
              const Icon = integrationsInfo[chainElement.integrationId]?.icon;

              return (
                <>
                  <Stack
                    direction="row"
                    alignItems="flex-start"
                    spacing={2}
                    divider={<Divider orientation="vertical" flexItem />}
                  >
                    <Stack alignItems={'flex-start'} pt={2}>
                      <Avatar sx={{ width: 26, height: 26, color: 'white' }}>{index + 1}</Avatar>

                      <Typography mt={1} variant="overline">
                        {integrationsInfo[chainElement.integrationId].title}
                      </Typography>
                    </Stack>

                    <Box pt={2}>
                      <Typography variant="caption">
                        <pre style={{ whiteSpace: 'pre-wrap' }}> {chainElement.message.trim() || '*No info found'}</pre>
                      </Typography>
                    </Box>
                  </Stack>
                </>
              );
            })}
          </Stack>
        </Box>
      </>
    }
  >
    <IconButton sx={{ backgroundColor: '#393939', color: 'white' }}>
      <ModelBuilder />
    </IconButton>
  </BootstrapTooltip>
);

export default PathInfoBubble;
