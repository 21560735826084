import { Add } from '@carbon/icons-react';
import { Button, ButtonSkeleton } from '@carbon/react';
import { Alert, Box, Snackbar } from '@mui/material';

import { useState } from 'react';
import AddMembersDialog from '../../AddMembersDialog';
import AvatarGroup from '../../AvatarGroup/AvatarGroup';

const StepMembers = ({ users, members, handleToggleMember, setMembers }) => {
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (selected) => {
    setOpen(false);
  };

  const handleSave = (selected) => {
    setMembers(selected);
    handleClose();
  };

  return (
    <Box
      py={2}
      px={4}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}
    >
      <Box>
        <Box mb={2}>
          {!users ? (
            <ButtonSkeleton />
          ) : (
            <Button renderIcon={Add} onClick={handleClickOpen}>
              Add members
            </Button>
          )}
        </Box>
        <Box sx={{ maxHeight: 300 }}>
          <AvatarGroup
            avatars={members.map((memberId) => users.find((user) => user.id === memberId)).filter(Boolean)}
            maxVisibleAvatars={5}
          />
        </Box>
      </Box>

      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
          Error
        </Alert>
      </Snackbar>

      <AddMembersDialog
        initialSelected={members}
        users={users}
        open={open}
        handleClose={handleClose}
        handleSave={handleSave}
      />
    </Box>
  );
};

export default StepMembers;
