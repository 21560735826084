import { Idea } from '@carbon/icons-react';
import { Box, Stack, styled } from '@mui/material';
import { motion } from 'framer-motion';
import ConversationIdea from './ConversationIdea/ConversationIdea';
import './conversation-starter.scss';

// const conversationIdeas = [
//   { text: 'How can I check my balance?', icon: <Account size={24} /> },
//   { text: 'How do I change my password?', icon: <Locked size={24} /> },
//   { text: 'I need to report a lost card', icon: <Report size={24} /> },
//   { text: 'I need help with technical support', icon: <Building size={24} /> },
//   { text: 'Contact customer service', icon: <Phone size={24} /> },
//   { text: 'Request a new credit card', icon: <Purchase size={24} /> },
// ];

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: 20,
  marginBottom: 20,
  paddingBottom: 40,
  // overflowY: 'auto',
  // display: 'flex',
  // height: '100%',
  // flexDirection: 'column',
  // gap: '1rem',
  // paddingBottom: '4rem',
  // justifyContent: 'center',
  // '&::-webkit-scrollbar': {
  //   display: 'none',
  // },
  // '-ms-overflow-style': 'none',
  // scrollbarWidth: 'none',
}));

const ConversationStarter = ({ name, mainColor, onIdeaClick, suggestions }) => (
  <StyledBox>
    <Box
      key={name}
      component={motion.div}
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{
        x: { type: 'spring', stiffness: 100 },
        opacity: { duration: 0.2 },
      }}
    >
      <h1 className="chat-title">{name}</h1>
    </Box>

    {Boolean(suggestions?.length) && (
      <>
        {' '}
        <Stack direction="row" alignItems="center" spacing={1} mt={8} mb={2}>
          <Idea size={14} />

          <h5 className="title-suggestions">Suggestions</h5>
        </Stack>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(210px, 1fr))',
            gap: 2,
            width: '100%',
          }}
        >
          {suggestions?.map((text, index) => (
            <ConversationIdea
              key={index}
              color={mainColor}
              index={index}
              text={text}
              // icon={icon}
              onIdeaClick={onIdeaClick}
            />
          ))}
        </Box>
      </>
    )}
  </StyledBox>
);

export default ConversationStarter;
