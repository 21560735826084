import { Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

const TYPING_SPEED_BASE = 10;
const TYPING_SPEED_VARIABILITY = 5;

const AnimatedListElement = ({ textElement, onAnimationEnd, num }) => {
  const [currentLine, setCurrentLine] = useState('');
  const [charIdx, setCharIdx] = useState(0);

  useEffect(() => {
    let isMounted = true;
    let timeoutId;

    const typeNextChar = () => {
      if (!isMounted) return;

      if (charIdx < textElement.length) {
        setCurrentLine((prevLine) => prevLine + textElement[charIdx]);
        const randomDuration = Math.random() * TYPING_SPEED_VARIABILITY + TYPING_SPEED_BASE;
        timeoutId = setTimeout(() => {
          setCharIdx((prevCharIdx) => prevCharIdx + 1);
        }, randomDuration);
      }
    };

    typeNextChar();

    // Cleanup on unmount or before a new effect runs
    return () => {
      isMounted = false;
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [charIdx, textElement]);

  useEffect(() => {
    if (charIdx === textElement.length && onAnimationEnd) {
      onAnimationEnd();
    }
  }, [charIdx, textElement, onAnimationEnd]);

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Typography variant="body1" color="text.primary" ml={2}>
        {num} - {currentLine}
      </Typography>
    </motion.div>
  );
};

export default AnimatedListElement;
