import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import SecureLayout from './layouts/SecureLayout/SecureLayout';
import ChatPage from './pages/ChatPage';
import ChatPageRedirect from './pages/ChatPageRedirect';

import EvaluationPage from './pages/EvaluationPage/EvaluationPage';
import EvaluationsPage from './pages/EvaluationsPage/EvaluationsPage';
import LoginPage from './pages/LoginPage';
import NewProjectPage from './pages/NewProjectPage/NewProjectPage';
import Page404 from './pages/Page404';
import ProjectPage from './pages/ProjectPage/ProjectPage';
import ProjectsPage from './pages/ProjectsPage/ProjectsPage';
import RedirectPage from './pages/RedirectPage';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/chat" />, index: true },
        // { path: 'landing', element: <LandingPage /> },
        {
          path: 'chat',
          element: (
            <SecureLayout>
              <ChatPageRedirect />
            </SecureLayout>
          ),
        },
        {
          path: 'chat/:id',
          element: (
            <SecureLayout>
              <ChatPage />
            </SecureLayout>
          ),
        },
        {
          path: 'projects',
          element: (
            <SecureLayout>
              <ProjectsPage />
            </SecureLayout>
          ),
        },
        {
          path: 'projects/:id',
          element: (
            <SecureLayout>
              <ProjectPage />
            </SecureLayout>
          ),
        },
        {
          path: 'new-project',
          element: (
            <SecureLayout>
              <NewProjectPage />
            </SecureLayout>
          ),
        },
        {
          path: 'evaluation',
          element: (
            <SecureLayout>
              <EvaluationsPage />
            </SecureLayout>
          ),
        },
        {
          path: 'evaluation/:id',
          element: (
            <SecureLayout>
              <EvaluationPage />
            </SecureLayout>
          ),
        },
      ],
    },
    {
      path: 'redirect',
      element: <RedirectPage />,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/chat" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/" replace />,
    },
  ]);

  return routes;
}
