import { createContext, useEffect, useState } from 'react';
import { useProjectApi } from '../hooks/api/useProjectsApi';
import { useAuth } from '../hooks/useAuth';

export const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  const { isAuthenticated, isCheckingToken, isAxiosReady } = useAuth();

  const { getProjects, removeProject } = useProjectApi();

  const [projects, setProjects] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [error, setError] = useState(null);

  const fetchProjects = async () => {
    try {
      const response = await getProjects();
      const { data } = response;

      if (data) {
        setProjects(data);
        setSelectedProject(data[0] || null); // select first project or null if no projects
      }
    } catch (error) {
      setError('Failed to fetch projects');
      console.error('Failed to fetch projects', error);
    }
  };

  const deleteProject = async (id) => {
    try {
      await removeProject(id);
      setError(null); // clear any existing error

      // remove the project from state
      const newProjects = projects.filter((project) => project.id !== id);
      setProjects(newProjects);

      // check if the deleted project was the selected project
      if (selectedProject?.id === id) {
        // select the first project in the updated list or null if no projects remain
        setSelectedProject(newProjects[0] || null);
      }
    } catch (err) {
      setError('Failed to delete project. Please try again.');
    }
  };

  useEffect(() => {
    if (isAuthenticated) fetchProjects();
  }, [isAuthenticated]);

  return (
    <ProjectContext.Provider value={{ projects, selectedProject, fetchProjects, deleteProject, error }}>
      {children}
    </ProjectContext.Provider>
  );
};
