import { Dropdown, NumberInput, Slider, TextArea, TextInput, Toggle } from '@carbon/react';
import InfoTooltip from '../InfoTooltip';
import OptionsTiles from '../OptionsTiles/OptionsTiles';
import PromptBuilder from '../PromptBuilder/PromptBuilder';
import './form-input.scss';

export default function FormInput({ config, value, onChange, error }) {
  const { id, name, description, type, values, min, max, step, optional } = config;

  const handleChange = (value) => {
    onChange(value, id);
  };

  switch (type) {
    case 'text':
      return (
        <TextInput
          id={id}
          autoComplete="off"
          labelText={description ? <InfoTooltip name={name} description={description} /> : name}
          value={value}
          onChange={(event) => handleChange(event.target.value)}
          invalidText={error}
          invalid={!!error}
          helperText={optional ? 'Optional' : ''}
        />
      );
    case 'textarea':
      return (
        <TextArea
          id={id}
          autoComplete="off"
          labelText={description ? <InfoTooltip name={name} description={description} /> : name}
          value={value}
          onChange={(event) => handleChange(event.target.value)}
          invalidText={error}
          invalid={!!error}
        />
      );
    case 'password':
      return (
        <TextInput.PasswordInput
          type="password"
          autoComplete="off"
          id={id}
          labelText={name}
          value={value}
          onChange={(event) => handleChange(event.target.value)}
          invalidText={error}
          invalid={!!error}
        />
      );
    case 'int':
      return (
        <NumberInput
          allowEmpty
          id={id}
          label={description ? <InfoTooltip name={name} description={description} /> : name}
          defaultValue={value}
          value={value}
          min={min}
          max={max}
          onChange={(event, { value, direction }) => {
            handleChange(Number(value));
          }}
          invalidText={error}
          invalid={!!error}
        />
      );
    case 'select':
      return (
        <Dropdown
          id={id}
          label={description ? <InfoTooltip name={name} description={description} /> : name}
          titleText={name}
          selectedItem={values.find((v) => v.id === value)}
          onChange={(event) => handleChange(event.selectedItem.id)}
          items={values}
          itemToString={(item) => (item ? item.name : '')}
          invalidText={error}
          invalid={!!error}
          style={{ width: '100%' }}
        />
      );

    case 'toggle':
      return (
        <Toggle
          labelText={description ? <InfoTooltip name={name} description={description} /> : name}
          toggled={value}
          id={id}
          onToggle={(value) => {
            handleChange(value);
          }}
        />
      );

    case 'slidebar':
      return (
        <Slider
          className="custom-slider"
          id={id}
          labelText={description ? <InfoTooltip name={name} description={description} /> : name}
          value={value}
          min={min}
          max={max}
          step={step}
          onChange={(event) => handleChange(event.value)}
          invalidText={error}
          invalid={!!error}
        />
      );
    case 'selectable-tiles':
      return (
        <OptionsTiles
          title={name}
          options={values}
          selectedOption={value}
          handleOptionChange={(val) => handleChange(val)}
        />
      );
    case 'prompt-builder':
      return <PromptBuilder value={value} description={description} handleChange={handleChange} />;

    default:
      return null;
  }
}
