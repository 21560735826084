import { motion } from 'framer-motion';

const dotStyle = {
  display: 'inline-block',
  backgroundColor: 'white',
  borderRadius: '50%',
  width: '8px',
  height: '8px',
  marginRight: '4px',
};

const LoadingDots = () => (
  <div>
    <motion.div
      style={dotStyle}
      animate={{ scale: [0.9, 1.3, 0.9] }}
      transition={{
        repeat: Infinity,
        duration: 1.2,
        ease: 'easeInOut',
      }}
    />
    <motion.div
      style={dotStyle}
      animate={{ scale: [1, 1.3, 1] }}
      transition={{
        repeat: Infinity,
        duration: 1.2,
        delay: 0.3,
        ease: 'easeInOut',
      }}
    />
    <motion.div
      style={dotStyle}
      animate={{ scale: [0.9, 1.3, 0.9] }}
      transition={{
        repeat: Infinity,
        duration: 1.2,
        delay: 0.6,
        ease: 'easeInOut',
      }}
    />
  </div>
);

export default LoadingDots;
