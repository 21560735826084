import { ChevronLeft } from '@carbon/icons-react';
import { LoadingButton } from '@mui/lab';
import { AvatarGroup, Box, Button, Card, Stack, Typography, emphasize, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { motion } from 'framer-motion';
import { getInitials } from '../../../utils/names';
import Avatar from '../../Avatar/Avatar';
import TypingEffect from '../../TypingEffect';

const ProjectPreviewCard = ({
  selectedColor,
  projectName,
  members,
  users,
  handleBack,
  loadingCreation,
  handleCreateProject,
}) => {
  const theme = useTheme();

  return (
    <Stack spacing={1}>
      <Card
        variant="outline"
        component={motion.div}
        layoutId="underline"
        sx={{
          width: 350,
          borderRadius: (theme) => theme.spacing(2),
          minHeight: 200,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Box display="flex" flexGrow={1} sx={{ width: '100%' }}>
          <Box
            sx={{
              width: '100%',
              background: 'url(/assets/patterns/pattern.svg)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              flexDirection: 'column',
              p: 3,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography variant="body2" color={grey[400]}>
                Name
              </Typography>
              <Typography variant="overline" sx={{ fontSize: 18, letterSpacing: 2, color: 'white', zIndex: 9999 }}>
                <TypingEffect text={projectName} />
              </Typography>
            </Box>
            <Stack alignItems="flex-start" spacing={1}>
              <Typography variant="body2" color={grey[400]}>
                Members
              </Typography>
              <AvatarGroup
                component={motion.div}
                animate={{ opacity: [0, 1], x: [-5, 0], transition: { delay: 1 } }}
                total={members.length}
                sx={{
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    fontSize: 12,
                    fontWeight: 500,
                    bgcolor: emphasize(selectedColor, 0.4),
                  },
                }}
              >
                {members.map((memberId, index) => {
                  const member = users[memberId];
                  return (
                    <Avatar
                      label={getInitials(member.name)}
                      key={`av-${index}`}
                      alt={member.name}
                      src={member.avatar}
                    />
                  );
                })}
              </AvatarGroup>
            </Stack>
          </Box>
          <Box sx={{ width: 80, backgroundColor: selectedColor, flexGrow: 1 }} />
        </Box>
      </Card>
      <Box
        component={motion.div}
        animate={{ opacity: [0, 1], y: [-20, 0], transition: { delay: 1.5 } }}
        sx={{ width: '100%' }}
      >
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<ChevronLeft />}
            onClick={handleBack}
            sx={{ color: 'white', width: '100%', borderRadius: (theme) => theme.spacing(2, 0, 0, 2) }}
          >
            Back
          </Button>

          <LoadingButton
            loading={loadingCreation}
            variant="contained"
            color="primary"
            size="large"
            onClick={handleCreateProject}
            sx={{ color: 'white', width: '100%', borderRadius: (theme) => theme.spacing(0, 2, 2, 0) }}
          >
            Create project
          </LoadingButton>
        </Stack>
      </Box>
    </Stack>
  );
};

export default ProjectPreviewCard;
