import { Checkmark, Close, Edit } from '@carbon/icons-react';
import { TextInput } from '@carbon/react';
import { IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { useState } from 'react';

const EditableProjectName = ({ initialName, onSave, color }) => {
  const [currentName, setCurrentName] = useState(initialName);
  const [isEditing, setIsEditing] = useState(false);

  const handleNameChange = (event) => {
    setCurrentName(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setIsEditing(false);
      onSave(currentName);
    } else if (event.key === 'Escape') {
      setIsEditing(false);
    }
  };

  return (
    <Stack direction="row" justifyContent="center" alignItems="flex-end" spacing={1} mt={0.5} sx={{ height: 40 }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        {!isEditing ? (
          <Typography
            variant="h3"
            sx={{
              // color: 'white',
              fontWeight: 800,
              maxWidth: 500,
              // textShadow: `0px 0px 10px ${color}`,
              // WebkitTextStroke: '0.6px black',
              // textStroke: '0.6px black',
            }}
            noWrap
          >
            {initialName || <Skeleton width={170} />}
          </Typography>
        ) : (
          <TextInput
            defaultValue={initialName}
            onChange={handleNameChange}
            size="sm"
            onKeyDown={handleKeyDown}
            type="text"
            id="editable-text-input"
          />
        )}
      </Stack>
      {!isEditing ? (
        <IconButton onClick={() => setIsEditing(true)}>
          <Edit />
        </IconButton>
      ) : (
        <Stack direction="row" spacing={0.5}>
          <IconButton onClick={() => setIsEditing(false)}>
            <Close />
          </IconButton>
          <IconButton
            color="success"
            onClick={() => {
              setIsEditing(false);
              onSave(currentName);
            }}
          >
            <Checkmark />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};

export default EditableProjectName;
