import { Add, TrashCan } from '@carbon/icons-react';
import { Button, Stack, TextInput, Tile } from '@carbon/react';
import { camelCase } from 'lodash';
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import './input-list.scss';

const InputList = ({ inputFormList, setInputForms }) => {
  const [name, setName] = useState('');

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(inputFormList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setInputForms(items);
  };

  const isDisabled = () => {
    if (inputFormList.length > 6 || name === '') return true;
    return false;
  };

  const handleAddFormInput = () => {
    if (isDisabled()) return;
    const nItem = {
      title: name,
      id: camelCase(name),
    };
    setInputForms([...inputFormList, nItem]);
    setName('');
  };

  const handleDeleteSuggestion = (index) => {
    const items = Array.from(inputFormList);
    items.splice(index, 1);
    setInputForms(items);
  };

  return (
    <div>
      <div className="input-form-list">
        <TextInput
          id="newInput"
          labelText="New Field"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && name) {
              handleAddFormInput();
            }
          }}
        />
        <Button
          renderIcon={Add}
          hasIconOnly
          kind="primary"
          size="md"
          onClick={handleAddFormInput}
          iconDescription="Add Field"
          tooltipPosition="bottom"
          className="addButton"
          disabled={isDisabled()}
        />
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="inputformlist">
          {(provided) => (
            <Stack {...provided.droppableProps} ref={provided.innerRef} gap={4}>
              {inputFormList?.map((input, index) => (
                <Draggable key={`guided-field-${index}`} draggableId={`item-${index}`} index={index}>
                  {(provided) => (
                    <Tile
                      className="input-form-list-item"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="form-list-header">
                        <div className="number">
                          <div className="number-title">{index + 1}</div>
                        </div>
                        <span>{input.title}</span>
                      </div>
                      <Button
                        kind="danger--ghost"
                        renderIcon={TrashCan}
                        hasIconOnly
                        size="sm"
                        onClick={() => handleDeleteSuggestion(index)}
                        iconDescription="Delete suggestion"
                        tooltipPosition="bottom"
                        className="deleteButton"
                      />
                    </Tile>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default InputList;
