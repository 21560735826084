import { Search } from '@carbon/icons-react';
import { Button, ComposedModal, DataTable, Form, FormGroup, ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import * as React from 'react';

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableSelectRow,
  TableSelectAll,
} = DataTable;

export default function AddMembersDialog({ initialSelected, users, open, handleClose, handleSave }) {
  const [search, setSearch] = React.useState('');
  const [selected, setSelected] = React.useState(initialSelected);

  const handleToggle = (value, isSelected) => {
    const currentIndex = selected.indexOf(value);
    const newselected = [...selected];

    if (currentIndex === -1) {
      newselected.push(value);
    } else if (currentIndex !== -1) {
      newselected.splice(currentIndex, 1);
    }

    setSelected(newselected);
  };

  const filteredUsers = users
    ? users
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((user) => user.name.toLowerCase().includes(search.toLowerCase()))
    : [];

  const headers = [
    {
      key: 'name',
      header: 'Name',
    },
  ];

  const manualRows = filteredUsers?.map((user) => ({
    id: user.id.toString(),
    name: user.name,
  }));

  const handleOnInputChange = (ev) => setSearch(ev.target.value);

  const handleCancel = () => {
    setSelected(initialSelected);
    handleClose();
  };

  return (
    <ComposedModal open={open} onClose={() => handleCancel()}>
      <ModalHeader title="Add members" />
      <ModalBody>
        <Form>
          <FormGroup legendText="Members">
            <DataTable
              rows={manualRows}
              headers={headers}
              isSortable
              useZebraStyles
              render={({ rows, selectedRows, headers, getHeaderProps, getSelectionProps, selectAll }) => (
                <TableContainer>
                  <TableToolbar>
                    <TableToolbarContent>
                      <TableToolbarSearch onChange={handleOnInputChange} />
                      <Button
                        kind="primary"
                        onClick={() => {
                          selectAll();

                          if (selectedRows.length === rows.length) setSelected([]);
                          else setSelected(filteredUsers.map((user) => user.id));
                        }}
                      >
                        {selectedRows.length === rows.length ? 'De-select all' : 'Select all'}
                      </Button>
                    </TableToolbarContent>
                  </TableToolbar>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableSelectAll
                          {...getSelectionProps()}
                          onSelect={() => {
                            selectAll();
                            setSelected(filteredUsers.map((user) => user.id));
                          }}
                        />
                        {headers.map((header) => (
                          <TableHeader key={header.key} {...getHeaderProps({ header })}>
                            {header.header}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, i) => (
                        // const outerRow = manualRows[i];

                        // if (outerRow.selected) row.isSelected = true;
                        // else row.isSelected = false;

                        <TableRow key={row.id}>
                          <TableSelectRow
                            {...getSelectionProps({ row })}
                            checked={selected.includes(row.id)}
                            onSelect={(isSelected) => {
                              // Call your custom function here
                              // manualRows[i].selected = !isSelected;

                              handleToggle(row.id, isSelected);

                              // Now let the original onSelect function do its thing
                              getSelectionProps({ row }).onSelect(isSelected);
                            }}
                          />
                          {row.cells.map((cell) => (
                            <TableCell key={cell.id}>{cell.value}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            />

            {!filteredUsers?.length && (
              <div>
                <Search />
                <p>No search results</p>
              </div>
            )}
          </FormGroup>
        </Form>
      </ModalBody>

      <ModalFooter>
        <Button kind="secondary" onClick={() => handleCancel()}>
          Cancel
        </Button>
        <Button kind="primary" onClick={() => handleSave(selected)}>
          Save Changes
        </Button>
      </ModalFooter>
    </ComposedModal>
  );
}
