import { Add, TrashCan } from '@carbon/icons-react';
import { Stack, TextInput, Tile } from '@carbon/react';
import { Button, IconButton } from '@mui/material';
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import './suggestions-list.scss';

const SuggestionsList = ({ suggestions, setSuggestions }) => {
  const [newSuggestion, setNewSuggestion] = useState('');

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(suggestions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSuggestions(items);
  };

  const handleAddSuggestion = () => {
    setSuggestions([...suggestions, newSuggestion]);
    setNewSuggestion('');
  };

  const handleDeleteSuggestion = (index) => {
    const items = Array.from(suggestions);
    items.splice(index, 1);
    setSuggestions(items);
  };

  return (
    <div className="suggestionsList">
      <div className="suggestionInput">
        <TextInput
          id="newSuggestion"
          labelText="New Suggestion"
          value={newSuggestion}
          onChange={(e) => setNewSuggestion(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && newSuggestion) {
              handleAddSuggestion();
            }
          }}
        />
        <Button
          onClick={handleAddSuggestion}
          disabled={newSuggestion === ''}
          iconDescription="Add suggestion"
          tooltipPosition="bottom"
          className="addButton"
        >
          <Add />
        </Button>
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="suggestions">
          {(provided) => (
            <Stack {...provided.droppableProps} ref={provided.innerRef} gap={4}>
              {suggestions?.map((suggestion, index) => (
                <Draggable key={`intiial-suggestion-${suggestion.id}`} draggableId={`item-${index}`} index={index}>
                  {(provided) => (
                    <Tile
                      className="suggestionItem"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="suggestion-header">
                        <div className="number">
                          <div className="number-title">{index + 1}</div>
                        </div>
                        <span>{suggestion}</span>
                      </div>
                      <IconButton
                        onClick={() => handleDeleteSuggestion(index)}
                        iconDescription="Delete suggestion"
                        className="deleteButton"
                      >
                        <TrashCan />
                      </IconButton>
                    </Tile>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default SuggestionsList;
