import { motion } from 'framer-motion';
import { useContext, useEffect, useRef, useState } from 'react';
import { CodeBlock, atomOneDark, atomOneLight } from 'react-code-blocks';
import { GlobalThemeContext } from '../../../contexts/GlobalThemeContext';

// Constants outside the component
const TYPING_SPEED_BASE = 10;
const TYPING_SPEED_VARIABILITY = 5;

export default function AnimatedCodeBlock({ content, onAnimationEnd }) {
  const { theme } = useContext(GlobalThemeContext);
  const [typedContent, setTypedContent] = useState('');

  const animationEndedRef = useRef(false);
  const currentCharIdxRef = useRef(0);

  useEffect(() => {
    let isMounted = true;

    const typeNextChar = () => {
      if (!isMounted) return;

      if (currentCharIdxRef.current < content.length) {
        currentCharIdxRef.current += 1;
        // Slice content directly for the typing effect
        setTypedContent(content.slice(0, currentCharIdxRef.current));
        const randomDuration = Math.random() * TYPING_SPEED_VARIABILITY + TYPING_SPEED_BASE;
        setTimeout(typeNextChar, randomDuration);
      } else if (!animationEndedRef.current && onAnimationEnd) {
        onAnimationEnd();
        animationEndedRef.current = true;
      }
    };

    typeNextChar();

    return () => {
      isMounted = false;
    };
  }, [content, onAnimationEnd]);

  const codeBlockTheme = theme === 'dark' ? atomOneDark : atomOneLight;

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <CodeBlock text={typedContent} language="javascript" showLineNumbers theme={codeBlockTheme} wrapLines />
    </motion.div>
  );
}
