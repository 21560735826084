import { Box, Stack, styled } from '@mui/material';
import { motion } from 'framer-motion';

const LINE_HEIGHT = '23px';

const Bubble = styled(motion.div)(({ theme }) => ({
  padding: '0.5rem 1.5rem',
  borderRadius: '1.5rem 1.5rem 1.5rem 0',
  background: 'rgba(60, 60, 60, 0.6)',
  color: '#fff',
  textAlign: 'left',
}));

const UserBubble = styled(Bubble)(({ theme, color }) => ({
  borderRadius: '1.5rem 1.5rem 0 1.5rem',
  background: color || theme.palette.primary.main,
  color: theme.palette.getContrastText(color || theme.palette.primary.main),
  width: '100%',
  overflow: 'auto',
}));

const MessageContainer = styled('div')(({ sender }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '1rem',
}));

const UserMessage = ({ content, mainColor }) => (
  <Box display="flex" flexDirection="column">
    <MessageContainer>
      <Stack sx={{ maxWidth: '90%', alignItems: 'flex-end' }}>
        <UserBubble
          className="advanced_glass_chat"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.3 }}
          color={mainColor}
        >
          <pre style={{ whiteSpace: 'pre-wrap', lineHeight: LINE_HEIGHT }}>{content.toString()}</pre>
        </UserBubble>
      </Stack>
    </MessageContainer>
  </Box>
);

export default UserMessage;
