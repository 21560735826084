import { Draggable } from '@carbon/icons-react';
import { Box, Grid, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Reorder, useDragControls } from 'framer-motion';

export const Item = ({ element, children }) => {
  const controls = useDragControls();

  return (
    <Reorder.Item component={Grid} xs drag value={element} id={element} dragListener={false} dragControls={controls}>
      <Stack direction="row">
        <div className="reorder-handle" onPointerDown={(e) => controls.start(e)}>
          <Box
            sx={{
              // border: '1px solid grey',

              cursor: 'grab',
              height: '100%',
              borderRadius: '10px 0 0 0',
              backgroundColor: grey[800],
            }}
            display={'flex'}
            alignItems="center"
          >
            <Draggable size={24} />
          </Box>
        </div>
        {children}
      </Stack>
    </Reorder.Item>
  );
};
