import { Login, Logout, UserAvatar } from '@carbon/icons-react';
import { Box, Divider, IconButton, ListItemIcon, MenuItem, Popover, Typography, alpha } from '@mui/material';
import { useState } from 'react';

import { useAuth } from '../../../hooks/useAuth';
import { getInitials } from '../../../utils/names';

export default function AccountPopover() {
  const [open, setOpen] = useState(null);

  const { user, login, logout } = useAuth();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton onClick={handleOpen} sx={{ color: 'white' }}>
        {!user ? <UserAvatar size={32} /> : getInitials(user?.name)}
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
        transformOrigin={{ vertical: 'center', horizontal: 'left' }}
        PaperProps={{
          sx: {
            zIndex: 99999,
            p: 0,
            background: 'transparent',
            ml: 2,
            width: 180,
            borderRadius: '14px',
            backdropFilter: 'blur(24px)',
            WebkitBackdropFilter: 'blur(8px)', // Safari support
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: '12px',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: alpha('#000', 0.1),
              },
            },
          },
        }}
      >
        {user && (
          <>
            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Typography variant="subtitle2" color="text.primary" noWrap>
                {user?.name}
              </Typography>
              <Typography variant="body2" color="text.primary" noWrap>
                {user?.mail.toLowerCase()}
              </Typography>
            </Box>
            <Divider />
          </>
        )}

        {!user && (
          <MenuItem onClick={handleClose} sx={{ m: 1 }}>
            <ListItemIcon>
              <Login fontSize="small" color="text.primary" />
            </ListItemIcon>
            <Typography variant="body2" color="text.primary">
              Login
            </Typography>
          </MenuItem>
        )}
        {user && (
          <MenuItem
            onClick={() => {
              logout();
              handleClose();
            }}
            sx={{ m: 1, backgroundColor: (theme) => theme.palette.background.paper }}
          >
            <ListItemIcon>
              <Logout fontSize="small" color="white" />
            </ListItemIcon>
            <Typography variant="button">Logout</Typography>
          </MenuItem>
        )}
      </Popover>
    </>
  );
}
