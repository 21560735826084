// component
import { Activity, Tools } from '@carbon/icons-react';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  // {
  //   title: 'landing',
  //   path: '/landing',
  //   icon: icon('ic_home'),
  // },
  // {
  //   title: 'Chat',
  //   path: '/chat',
  //   icon: Chat,
  // },
  {
    title: 'Evaluation',
    path: '/evaluation',
    icon: Activity,
  },
  {
    title: 'Projects',
    path: '/projects',
    icon: Tools,
  },
];

export default navConfig;
