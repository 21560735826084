import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { useContext } from 'react';

import { Theme } from '@carbon/react';

import Router from './routes';
// theme
import ThemeProvider from './theme';
// components

import './App.scss';
import ScrollToTop from './components/scroll-to-top';

import { GlobalThemeContext, GlobalThemeProvider } from './contexts/GlobalThemeContext';
import { AuthProvider } from './hooks/useAuth';
// ----------------------------------------------------------------------

export default function App() {
  return (
    <HelmetProvider>
      <GlobalThemeProvider>
        <AppContent />
      </GlobalThemeProvider>
    </HelmetProvider>
  );
}

function AppContent() {
  const { theme } = useContext(GlobalThemeContext);
  const currTheme = theme === 'light' ? 'g10' : 'g100';

  return (
    <Theme theme={currTheme} style={{ height: '100%' }}>
      <AuthProvider>
        <BrowserRouter>
          <ThemeProvider mode={theme}>
            <ScrollToTop />
            <Router />
          </ThemeProvider>
        </BrowserRouter>
      </AuthProvider>
    </Theme>
  );
}
